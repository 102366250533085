import { useEffect } from 'react';
import './MyGlider.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';
import '@glidejs/glide/dist/css/glide.core.min.css';
import Glide from '@glidejs/glide';

export default function MyGlider(props) {
    useEffect(() => {
        new Glide(`.${props.name}`, {
            perView: 9,
            type: 'slider',
            direction: 'rtl',
            breakpoints: {
                1030: { perView: 8 },
                995: { perView: 7 },
                890: { perView: 6 },
                748: { perView: 5 },
                624: { perView: 4.5 },
                545: { perView: 4 },
                500: { perView: 3.5 },
                400: { perView: 3 },
                330: { perView: 2.5},
                270: { perView: 2 },
                200: { perView: 1 }, 
            },
            bound: true,
        }).mount();
    });

    return (
        <div className={props.name}>
            <div className="glide__track" data-glide-el="track">
                <ul className="glide__slides">
                    {props.children}
                </ul>
            </div>
        </div>
    )
}


function MyLargeGlider(props) {
    useEffect(() => {
        new Glide(`.${props.name}`, {
            perView: 4,
            type: 'slider',
            breakpoints: {
                1046: { perView: 3.5 },
                910: { perView: 3.2 },
                830: { perView: 2.5 },
                646: { perView: 2.1 },
                544: { perView: 1.8 },
                464: { perView: 1.5 },
                386: { perView: 1.2 },
                304: { perView: 1.1 },
            },
            direction: 'rtl',
            bound: true,
        }).mount();
    });

    return (
        <div className={props.name}>
            <div className="glide__track" data-glide-el="track">
                <ul className="glide__slides">
                    {props.children}
                </ul>
            </div>
        </div>
    )
}

export {MyLargeGlider};
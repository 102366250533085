import axios from 'axios';
import './AdminNotifications.css';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function AdminNotifications({ apiEndPoint }) {
    const [isLoading, setIsLoading] = useState(true);
    const [notifications, setNotifications] = useState();
    const history = useHistory();

    const deleteNotification = (id) => {
        axios.get(`${apiEndPoint}/api/deleteNotification/${id}`).then(response => {
            if (response.data) {
                setNotifications(notifications.filter(notification => notification.id !== id));
            }
        });
    }

    useEffect(() => {
        axios.get(`${apiEndPoint}/api/login`).then(response => {
            if (response.data.loggedin === false || response.data.user.isAdmin === 0) {
                history.replace("/");
            }
        });
        axios.get(`${apiEndPoint}/api/getNotifications`).then(result => {
            setNotifications(result.data);
            setIsLoading(false);        
        });
    }, []);

    if (isLoading) {
        return <div></div>
    }

    return (
        <div className="AdminNotifications">
            <h1><i class="fas fa-bell"></i> الإشعارات</h1>
            <div className="AdminNotifications-Container">
                {
                    notifications.map(val => {
                        return (
                            <div className="AdminNotifications-NotificationBox">
                                <div className="AdminNotifications-NotificationInfo">
                                    <table className="detaildInfo">
                                        <tr>
                                            <td>
                                                <b><i class="fas fa-user"></i></b>
                                            </td>
                                            <td>{val.username}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b><i class="fas fa-phone"></i></b>
                                            </td>
                                            <td>
                                                {val.phoneNumber}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b><i class="fas fa-clock"></i></b>
                                            </td>
                                            <td style={{ direction: 'ltr' }}>
                                                {val.time}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b><i class="fas fa-calendar-day"></i></b>
                                            </td>
                                            <td>
                                                {val.submittedWhen === 'RequestingBook' ? 'طلب كتاب' : val.submittedWhen === 'RequestingCard' ? 'طلب بطاقة توب فان' : null}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b><i class="fas fa-envelope-open-text"></i></b>
                                            </td>
                                            <td>
                                                {val.message}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <button onClick={() => deleteNotification(val.id)}><i class="fas fa-times"></i></button>
                            </div>
                        )   
                    })
                }
            </div>
        </div>
    )
}
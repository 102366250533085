import IconedInputField from '../IconedInputField/IconedInputField';
import './Signup.css';
import name from '../../assets/name.svg';
import email from '../../assets/email.svg';
import redPhone from '../../assets/redPhone.svg';
import shownPassword from '../../assets/shownPassword.svg';
import hiddenPassword from '../../assets/hiddenPassword.svg';
import redLocation from '../../assets/redLocation.svg';
import Axios from 'axios';
import { useEffect, useContext, useState } from 'react';
import { ActiveTheme } from '../../App';
import { useHistory, Link } from 'react-router-dom';
import { Ring } from '@uiball/loaders';

export default function Signup(props) {
    const history = useHistory();
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);

    const apiEndPoint = props.apiEndPoint;

    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    const shadowColor = () => {
        if (activeTheme === 'black') {
            return 'rgba(17,17,17, .2) 0 -25px 18px -14px inset,rgba(17,17,17, .15) 0 1px 2px,rgba(17,17,17, .15) 0 2px 4px,rgba(17,17,17, .15) 0 4px 8px,rgba(17,17,17, .15) 0 8px 16px,rgba(17,17,17, .15) 0 16px 32px';
        } else if (activeTheme === 'blue') {
            return 'rgba(151,201,233, .2) 0 -25px 18px -14px inset,rgba(151,201,233, .15) 0 1px 2px,rgba(151,201,233, .15) 0 2px 4px,rgba(151,201,233, .15) 0 4px 8px,rgba(151,201,233, .15) 0 8px 16px,rgba(151,201,233, .15) 0 16px 32px';
        } else if (activeTheme === 'green') {
            return 'rgba(93,187,79, .2) 0 -25px 18px -14px inset,rgba(93,187,79, .15) 0 1px 2px,rgba(93,187,79, .15) 0 2px 4px,rgba(93,187,79, .15) 0 4px 8px,rgba(93,187,79, .15) 0 8px 16px,rgba(93,187,79, .15) 0 16px 32px';
        } else if (activeTheme === 'purple') {
            return 'rgba(130,124,180, .2) 0 -25px 18px -14px inset,rgba(130,124,180, .15) 0 1px 2px,rgba(130,124,180, .15) 0 2px 4px,rgba(130,124,180, .15) 0 4px 8px,rgba(130,124,180, .15) 0 8px 16px,rgba(130,124,180, .15) 0 16px 32px';
        } else if (activeTheme === 'red') {
            return 'rgba(240,91,93, .2) 0 -25px 18px -14px inset,rgba(240,91,93, .15) 0 1px 2px,rgba(240,91,93, .15) 0 2px 4px,rgba(240,91,93, .15) 0 4px 8px,rgba(240,91,93, .15) 0 8px 16px,rgba(240,91,93, .15) 0 16px 32px';
        } else { // yellow
            return 'rgba(253,183,27, .2) 0 -25px 18px -14px inset,rgba(253,183,27, .15) 0 1px 2px,rgba(253,183,27, .15) 0 2px 4px,rgba(253,183,27, .15) 0 4px 8px,rgba(253,183,27, .15) 0 8px 16px,rgba(253,183,27, .15) 0 16px 32px';
        }
    }

    useEffect(() => {
        document.title = 'Paperbook - Signup';
        Axios.get(`${apiEndPoint}/api/login`).then((response) => {
            if (response.data.loggedin === true) {
                history.replace("/");
            }
        });
        window.scrollTo(0, 0);
    }, []);
    
    const checking = async(data) => {
        let result = true;
        // Stupid design...
        await Axios.get(`${apiEndPoint}/api/isPhoneNumberUsed/${data['phoneNumber']}`).then(response => {
            if (response.data) {
                showWarning('رقم الهاتف مستخدم بالفعل');
                result = false;
            }
        });
        await Axios.get(`${apiEndPoint}/api/isNameUsed/${data['name'].trim()}`).then(response => {
            if (response.data) {
                showWarning('هناك مستخدم بهذا الاسم بالفعل');
                result = false;
            }
        });
        if (data['name'].trim().length < 3 || !isNaN(data['name'])) {
            showWarning('الاسم يجب ان لا يقل عن حرفين بدون ارقام');
            result = false;
        }
        if (data['password'].length < 8) {
            showWarning('الرقم السري لا يمكن ان يقل عن 8 خانات و يمكن أن يكون مزيج من اي شيء');
            result = false;
        } 
        if (isNaN(data['phoneNumber']) || data['phoneNumber'].length > 15) {
            showWarning('الرجاء ادخال رقم الهاتف بشكل صحيح (بدون اشارة + )');
            result = false;
        }
        if (data['phoneNumber'] !== data['confirmPhoneNumber']) {
            showWarning('ارقام الهواتف التي قمت بادخالها غير متطابقة');
            result = false;
        }
        if (data['address'].length < 1 || !isNaN(data['address'])) {
            showWarning('الرجاء ادخال العنوان');
            result = false;
        }
        return result;
    }

    const showWarning = (str) => {
        const warning = document.querySelector('#signupWarning');
        const warningDiv = document.createElement('div');
        warningDiv.innerText = str;
        warning.appendChild(warningDiv);
        warning.style.display = 'block';
        warning.style['max-width'] = '415px';
        warning.classList.add('fadein');
        warning.style['background-color'] = mainColor();
        window.scrollTo(0, 0);
        setTimeout(() => {
            warning.classList.remove('fadein');
            warning.classList.add('fadeout');
        }, 10000);
        setTimeout(() => {
            warning.style.display = 'none';
            warningDiv.remove();
        }, 10300);
        warning.classList.remove('fadeout');
    }


    const signup = () => {
        setIsBtnDisabled(true);
        const data = {};
        const formFields = document.querySelectorAll('.formField');
        for (let i=0;i<formFields.length;i++) {
            data[formFields[i].name] = formFields[i].value;
        }
        
        checking(data).then(response => {
            setIsBtnDisabled(false);
            if (response) {
                Axios.post(`${apiEndPoint}/api/register`, {
                    name: data['name'].trim(),
                    cardNumber: 0,
                    password: data['password'],
                    address: data['address'],
                    phoneNumber: data['phoneNumber'],
                }).then(result => {
                    if (result.data) {
                        history.go(0);
                    }
                });
            }
        });
    }

    const passwordVisibility = () => {
        const passIcon = document.getElementById('passwordVisIcon');
        const passwordInput = document.getElementById('passwordInput');
        if (passwordInput.type === 'password') {
            passwordInput.type = 'text';
            passIcon.src = shownPassword;
        } else {
            passwordInput.type = 'password';
            passIcon.src = hiddenPassword;
        }
    }

    return (
        <div className="signup">
            <h2>شن الجو؟</h2>
            <p>انضم لعائلة بايبر واستفد من العروض والامتيازات الخاصة 😎 </p>
            <div className="warning" id="signupWarning"></div>
            <div className="signupForm">
                <div className="question">
                    <label>حط اسمك كامل:</label>
                    <IconedInputField image={name} name="name" type="text"/>
                </div>
                <div className="question">
                    <label>رقم تليفونك:</label>
                    <IconedInputField image={redPhone} name="phoneNumber" type="text"/>
                </div>
                <div className="question">
                    <label>اكد رقم تليفونك:</label>
                    <IconedInputField image={redPhone} name="confirmPhoneNumber" type="text"/>
                </div>
                <div className="question">
                    <label>كلمة المرور:</label>
                    <IconedInputField image={hiddenPassword} imageId="passwordVisIcon" inputId="passwordInput" name="password" type="password" onClickOnIcon={passwordVisibility}/>
                </div>
                <div className="question">
                    <label>العنوان:</label>
                    <IconedInputField image={redLocation} name="address" type="text"/>
                </div>
            </div>
            <div className="actionBtns">
                <p>لديك حساب؟ <Link style={{textDecoration: 'underline'}} to="login">سجل الدخول</Link></p>
                <button className="btn" style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} onClick={signup} disabled={isBtnDisabled}>{isBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color="white" /> : 'تسجيل'}</button>
            </div>
        </div>
    )
}
import IconedInputField from '../IconedInputField/IconedInputField';
import './UserNotificationAdder.css';
import titleIcon from '../../assets/title.svg';
import messageIcon from '../../assets/message.svg';
import { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router';
import { ActiveTheme } from '../../App';
import { Ring } from '@uiball/loaders';

export default function UserNotificationAdder(props) {
    const [notificationTitle, setNotificationTitle] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notifications, setNotifications] = useState([]);
    const [isAddNotificationBtnDisabled, setIsAddNotificationBtnDisabled] = useState(false);
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);
    const history = useHistory();

    const apiEndPoint = props.apiEndPoint;

    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    const shadowColor = () => {
        if (activeTheme === 'black') {
            return 'rgba(17,17,17, .2) 0 -25px 18px -14px inset,rgba(17,17,17, .15) 0 1px 2px,rgba(17,17,17, .15) 0 2px 4px,rgba(17,17,17, .15) 0 4px 8px,rgba(17,17,17, .15) 0 8px 16px,rgba(17,17,17, .15) 0 16px 32px';
        } else if (activeTheme === 'blue') {
            return 'rgba(151,201,233, .2) 0 -25px 18px -14px inset,rgba(151,201,233, .15) 0 1px 2px,rgba(151,201,233, .15) 0 2px 4px,rgba(151,201,233, .15) 0 4px 8px,rgba(151,201,233, .15) 0 8px 16px,rgba(151,201,233, .15) 0 16px 32px';
        } else if (activeTheme === 'green') {
            return 'rgba(93,187,79, .2) 0 -25px 18px -14px inset,rgba(93,187,79, .15) 0 1px 2px,rgba(93,187,79, .15) 0 2px 4px,rgba(93,187,79, .15) 0 4px 8px,rgba(93,187,79, .15) 0 8px 16px,rgba(93,187,79, .15) 0 16px 32px';
        } else if (activeTheme === 'purple') {
            return 'rgba(130,124,180, .2) 0 -25px 18px -14px inset,rgba(130,124,180, .15) 0 1px 2px,rgba(130,124,180, .15) 0 2px 4px,rgba(130,124,180, .15) 0 4px 8px,rgba(130,124,180, .15) 0 8px 16px,rgba(130,124,180, .15) 0 16px 32px';
        } else if (activeTheme === 'red') {
            return 'rgba(240,91,93, .2) 0 -25px 18px -14px inset,rgba(240,91,93, .15) 0 1px 2px,rgba(240,91,93, .15) 0 2px 4px,rgba(240,91,93, .15) 0 4px 8px,rgba(240,91,93, .15) 0 8px 16px,rgba(240,91,93, .15) 0 16px 32px';
        } else { // yellow
            return 'rgba(253,183,27, .2) 0 -25px 18px -14px inset,rgba(253,183,27, .15) 0 1px 2px,rgba(253,183,27, .15) 0 2px 4px,rgba(253,183,27, .15) 0 4px 8px,rgba(253,183,27, .15) 0 8px 16px,rgba(253,183,27, .15) 0 16px 32px';
        }
    }

    useEffect(() => {
        Axios.get(`${apiEndPoint}/api/login`).then(response => {
            if (response.data.loggedin === false || response.data.user.isAdmin === 0) {
                history.replace("/");
            }
            Axios.get(`${apiEndPoint}/api/getUserNotifications`).then(response => {
                setNotifications(response.data);
            });
        });
        document.title = 'Paperbook - Send Notification To User';
    }, []);

    const deleteNotification = (id) => {
        Axios.get(`${apiEndPoint}/api/deleteUserNotification/${id}`).then(response => {
            if (response.data) {
                setNotifications(notifications => notifications.filter(notification => notification.id !== id));
            }
        });
    }

    const showWarning = (str) => {
        const warning = document.querySelector('#userNotificationAdderWarning');
        const warningDiv = document.createElement('div');
        warningDiv.innerText = str;
        warning.appendChild(warningDiv);
        warning.style.display = 'block';
        warning.style['max-width'] = '415px';
        warning.style['background-color'] = mainColor();
        warning.classList.add('fadein');
        window.scrollTo(0, 0);
        setTimeout(() => {
            warning.classList.remove('fadein');
            warning.classList.add('fadeout');
        }, 5000);
        setTimeout(() => {
            warning.style.display = 'none';
            warningDiv.remove();
        }, 5300);
        warning.classList.remove('fadeout');
    }

    const showDoneWarning = (str) => {
        const warning = document.querySelector('#addedUserNotificationWarning');
        const warningDiv = document.createElement('div');
        warningDiv.innerText = str;
        warning.appendChild(warningDiv);
        warning.style.display = 'inline-block';
        warning.classList.add('fadein');
        window.scrollTo(0, 0);
        setTimeout(() => {
            warning.classList.remove('fadein');
            warning.classList.add('fadeout');
        }, 5000);
        setTimeout(() => {
            warning.style.display = 'none';
            warningDiv.remove();
        }, 5300);
        warning.classList.remove('fadeout');
    }

    const sendNotification = () => {
        if (notificationTitle === '' || notificationMessage === '') {
            showWarning('الرجاء تعبئة الخانات الفارغة');
            return;
        }
        setIsAddNotificationBtnDisabled(true);
        Axios.get(`${apiEndPoint}/api/submitUserNotification/${notificationTitle}/${notificationMessage}`).then(response => {
            if (response.data) {
                showDoneWarning('تم ارسال الاشعار لجميع المستخدمين');
                setIsAddNotificationBtnDisabled(false);
                Axios.get(`${apiEndPoint}/api/getUserNotifications`).then(response => {
                    setNotifications(response.data);
                    setNotificationTitle('');
                    setNotificationMessage('');
                });
            };
        });
    }

    const showMore = (id) => {
        const container = document.getElementById(id);
        const  description = container.querySelector('.notificationDescription');
        description.classList.remove('fade');
        description.style.height = 'inherit';
        container.style.cursor = 'unset';
    }

    return (
        <div className="userNotificationAdder">
            <div className="doneWarning" id="addedUserNotificationWarning"></div>
            <div className="warning" id="userNotificationAdderWarning"></div>
            <div className="question">
                <label>عنوان الاشعار:</label>
                <IconedInputField value={notificationTitle} image={titleIcon} placeholder="عنوان الاشعار" onChange={e => setNotificationTitle(e.target.value)}/>
            </div>
            <div className="question">
                <label>نص الاشعار:</label>
                <div className="iconedInputField shadow">
                    <textarea className="contactMessageBox" value={notificationMessage} placeholder="الوصف" name="description" onChange={e => setNotificationMessage(e.target.value)}/><img src={messageIcon} alt=""/>
                </div>
            </div>
            <button disabled={isAddNotificationBtnDisabled} style={{backgroundColor: mainColor()}} className="flatBtn" onClick={sendNotification}>{isAddNotificationBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color="white" /> : 'إرسال'}</button>
            <div className="previousNotifications">
                <h1>:اشعارات سابقة</h1>
                {
                    notifications.length > 0 ? notifications.map(val => {
                        return (
                            <div className="notificationContainer" id={val.id}>
                            <i class="fas fa-times" style={{fontSize: '2em'}} onClick={() => deleteNotification(val.id)}></i>
                                <div className="notificationContent" onClick={() => showMore(val.id)}>
                                    <div className="notificationHeader">
                                        <p className="notificationDate">{val.time}</p><h2>{val.notificationTitle}</h2>
                                    </div>
                                    <p className="notificationDescription fade" id="notificationDescription">{val.notificationMessage}</p>
                                </div>
                            </div>
                        )
                    }) : <p style={{ direction: 'rtl' }}>لا يوجد اشعارات في قاعدة البيانات.</p>
                }
            </div>
        </div>
    )
}
import { useState, useEffect } from 'react';
import './Notification.css';
import Axios from 'axios';
import { useHistory } from 'react-router';

export default function Notification(props) {
    const [newNotifications, setNewNotifications] = useState([]);
    const [seenNotifications, setSeenNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();

    const apiEndPoint = props.apiEndPoint;

    useEffect(() => {
        Axios.get(`${apiEndPoint}/api/login`).then((response) => {
            if (response.data.loggedin) {
                const seenByUser = response.data.user['seenNotifications'];
                Axios.get(`${apiEndPoint}/api/getUserNotifications`).then(result => {
                    const newUserNotificationsIds = [];
                    for (let i in result.data) {
                        newUserNotificationsIds.push(result.data[i].id);
                    }
                    if (seenByUser.seenNotifications) {
                        let newNew = [];
                        let newSeen = [];
                        for (let value of seenByUser.seenNotifications) {
                            for (let i=0;i<result.data.length;i++) {
                                if (result.data[i].id === value) {
                                    newSeen.push(result.data[i]);
                                }
                            }
                            newNew = result.data.filter(val => newSeen.indexOf(val) === -1);
                        }
                        setNewNotifications(newNew);
                        setSeenNotifications(newSeen);
                    } else {
                        setNewNotifications(result.data);
                    }
                    seenByUser['seenNotifications'] = newUserNotificationsIds;
                    Axios.post(`${apiEndPoint}/api/updateUserNotification`, {userId: response.data.user.id, seenNotifications: seenByUser});
                    setIsLoading(false);
                });
                
            } else {
                history.push('/');
            }
        });
    }, []);

    console.log(newNotifications);
    console.log(seenNotifications);

    if (isLoading) {
        return <h1 style={{direction: 'rtl'}}>جاري التحميل ...</h1>
    }

    return (
        <div className="userNotification">
            <h1 style={{padding: '.6em 0'}}>الاشعارات</h1>
            {
                newNotifications.map(val => {
                    return (
                        <div className="notificationContainer" key={val.id}>
                            <i class="fas fa-bell"></i>
                            <div className="notificationContent">
                                <div className="notificationHeader">
                                    <p className="notificationDate">{val.time}</p><h2>{val.notificationTitle}</h2>
                                </div>
                                <p className="notificationDescription">{val.notificationMessage}</p>
                            </div>
                        </div>
                    )
                })
            }
            <div>
                <h4>Seen</h4>
                {
                    (seenNotifications.length > 0) ? (
                        seenNotifications.map(val => {
                            return (
                                <div className="notificationContainer" key={val.id}>
                                    <i class="fas fa-bell"></i>
                                    <div className="notificationContent">
                                        <div className="notificationHeader">
                                            <p className="notificationDate">{val.time}</p><h2>{val.notificationTitle}</h2>
                                        </div>
                                        <p className="notificationDescription">{val.notificationMessage}</p>
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        <p>مفيش حاجة قديمة</p>
                    )
                }
            </div>
        </div>
    )
}
import './AdminDeliveryPricesEditor.css';
import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import IconedInputField from '../IconedInputField/IconedInputField';
import cityIcon from '../../assets/cityIcon.svg';
import priceIcon from '../../assets/price.svg';
import { ActiveTheme } from '../../App';
import { Ring } from '@uiball/loaders';

export default function AdminDeliveryPricesEditor(props) {
    const apiEndPoint = props.apiEndPoint;
    const [deliveryPrices, setDeliveryPrices] = useState({});
    const [cityName, setCityName] = useState('');
    const [price, setPrice] = useState();
    const [isNewEntryBtnDisabled, setIsNewEntryBtnDisabled] = useState(false);
    const [isUpdateEntryBtnDisabled, setIsUpdateEntryBtnDisabled] = useState(false); 
    const [isDeleteEntryBtnDisabled, setIsDeleteEntryBtnDisabled] = useState(false);
    const history = useHistory();
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);

    useEffect(() => {
        axios.get(`${apiEndPoint}/api/login`).then(response => {
            if (response.data.loggedin === false || response.data.user.isAdmin === 0) {
                history.replace("/");
            }
        });
        axios.get(`${apiEndPoint}/api/admin/getDeliveryPrices`).then(response => {
            let obj = {};
            for (let i=0;i<response.data.length;i++) {
                obj[response.data[i].id] = {city: response.data[i].city, price: response.data[i].price};
            }
            setDeliveryPrices(obj);
        });
        document.title = 'Paperbook - Admin Panel';
        window.scrollTo(0, 0);
    }, []);

    const showWarning = (str) => {
        window.scrollTo(0, 0);
        const warning = document.querySelector('#deliveryPricesEditorWarning');
        const warningDiv = document.createElement('div');
        warningDiv.innerText = str;
        warning.appendChild(warningDiv);
        warning.style.display = 'block';
        warning.style['max-width'] = '415px';
        warning.style['background-color'] = mainColor();
        warning.classList.add('fadein');
        window.scrollTo(0, 0);
        setTimeout(() => {
            warning.classList.remove('fadein');
            warning.classList.add('fadeout');
        }, 10000);
        setTimeout(() => {
            warning.style.display = 'none';
            warningDiv.remove();
        }, 10300);
        warning.classList.remove('fadeout');
    }

    const updateCity = (id, newValue) => {
        let newDeliveryPrices = deliveryPrices;
        newDeliveryPrices[id].city = newValue;
        setDeliveryPrices({...newDeliveryPrices});        
    }

    const updatePrice = (id, newValue) => {
        let newDeliveryPrices = deliveryPrices;
        newDeliveryPrices[id].price = newValue;
        setDeliveryPrices({...newDeliveryPrices}); 
    }

    const updateEntry = id => {
        setIsUpdateEntryBtnDisabled(true);
        axios.get(`${apiEndPoint}/api/admin/updateDeliveryPrice/${id}/${deliveryPrices[id].city}/${deliveryPrices[id].price}`).then(result => {
            if (result.data) {
                setIsUpdateEntryBtnDisabled(false);
                showWarning('تم التعديل');
            }
        });
    }

    const deleteEntry = id => {
        setIsDeleteEntryBtnDisabled(true);
        axios.get(`${apiEndPoint}/api/admin/deleteDeliveryPrice/${id}`).then(result => {
            let newDeliveryPrices = deliveryPrices;
            delete newDeliveryPrices[id];
            setDeliveryPrices({...newDeliveryPrices});
            setIsDeleteEntryBtnDisabled(false);
        });
    }

    const insertNewEntry = () => {
        if (cityName.length == 0) {
            showWarning('الرجاء ادخال اسم للمدينة');
        } else if (isNaN(price)) {
            showWarning('الرجاء ادخال قيمة صالحة كسعر');
        } else {
            setIsNewEntryBtnDisabled(true);
            axios.get(`${apiEndPoint}/api/admin/insertDeliveryPrice/${cityName}/${price}`).then(result => {
                if (result.data.done) {
                    setDeliveryPrices({...deliveryPrices, [result.data.id]: {city: cityName, price: price}});
                    setIsNewEntryBtnDisabled(false);
                    showWarning('تم الادخال');
                    setCityName('');
                    setPrice('');
                }
            });
        }
    }

    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    const shadowColor = () => {
        if (activeTheme === 'black') {
            return 'rgba(17,17,17, .2) 0 -25px 18px -14px inset,rgba(17,17,17, .15) 0 1px 2px,rgba(17,17,17, .15) 0 2px 4px,rgba(17,17,17, .15) 0 4px 8px,rgba(17,17,17, .15) 0 8px 16px,rgba(17,17,17, .15) 0 16px 32px';
        } else if (activeTheme === 'blue') {
            return 'rgba(151,201,233, .2) 0 -25px 18px -14px inset,rgba(151,201,233, .15) 0 1px 2px,rgba(151,201,233, .15) 0 2px 4px,rgba(151,201,233, .15) 0 4px 8px,rgba(151,201,233, .15) 0 8px 16px,rgba(151,201,233, .15) 0 16px 32px';
        } else if (activeTheme === 'green') {
            return 'rgba(93,187,79, .2) 0 -25px 18px -14px inset,rgba(93,187,79, .15) 0 1px 2px,rgba(93,187,79, .15) 0 2px 4px,rgba(93,187,79, .15) 0 4px 8px,rgba(93,187,79, .15) 0 8px 16px,rgba(93,187,79, .15) 0 16px 32px';
        } else if (activeTheme === 'purple') {
            return 'rgba(130,124,180, .2) 0 -25px 18px -14px inset,rgba(130,124,180, .15) 0 1px 2px,rgba(130,124,180, .15) 0 2px 4px,rgba(130,124,180, .15) 0 4px 8px,rgba(130,124,180, .15) 0 8px 16px,rgba(130,124,180, .15) 0 16px 32px';
        } else if (activeTheme === 'red') {
            return 'rgba(240,91,93, .2) 0 -25px 18px -14px inset,rgba(240,91,93, .15) 0 1px 2px,rgba(240,91,93, .15) 0 2px 4px,rgba(240,91,93, .15) 0 4px 8px,rgba(240,91,93, .15) 0 8px 16px,rgba(240,91,93, .15) 0 16px 32px';
        } else { // yellow
            return 'rgba(253,183,27, .2) 0 -25px 18px -14px inset,rgba(253,183,27, .15) 0 1px 2px,rgba(253,183,27, .15) 0 2px 4px,rgba(253,183,27, .15) 0 4px 8px,rgba(253,183,27, .15) 0 8px 16px,rgba(253,183,27, .15) 0 16px 32px';
        }
    }

    console.log(deliveryPrices);
    console.log(cityName);

    return (
        <div className="AdminDeliveryPricesEditor">
            <h1>اسعار التوصيل</h1>
            <div className="warning" id="deliveryPricesEditorWarning"></div>
            <div className="deliveryPriceInsertContainer">
                <IconedInputField image={cityIcon} value={cityName} placeholder="اسم المدينة" onChange={(e) => setCityName(e.target.value)} />
                <IconedInputField image={priceIcon} value={price} placeholder="السعر" onChange={(e) => setPrice(e.target.value)} />
                <button style={{backgroundColor: mainColor()}} disabled={isNewEntryBtnDisabled} className="flatBtn" onClick={insertNewEntry}>{isNewEntryBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color="white" /> : 'ادخال'}</button>
            </div>
            <table>
                <tr>
                    <th style={{backgroundColor: mainColor()}}>اجراءات</th>
                    <th style={{backgroundColor: mainColor()}}>السعر</th>
                    <th style={{backgroundColor: mainColor()}}>المدينة</th>
                </tr>
                {
                    (Object.keys(deliveryPrices).length > 0) ? Object.keys(deliveryPrices).map(key => (
                        <tr>
                            <td><button disabled={isUpdateEntryBtnDisabled} style={{backgroundColor: mainColor()}} className="flatBtn" onClick={() => updateEntry(key)}>{isUpdateEntryBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color="white" /> : 'تعديل'}</button><button style={{color: mainColor(), borderColor: mainColor()}} disabled={isDeleteEntryBtnDisabled} className="flatBtn-clicked" onClick={() => deleteEntry(key)}>{isDeleteEntryBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color={mainColor()} /> : 'حذف'}</button></td>
                            <td><input className="deliveryPricesEditorInputField" type="text" value={deliveryPrices[key].price} onChange={(e) => updatePrice(key, e.target.value)} /></td>
                            <td><input className="deliveryPricesEditorInputField" type="text" value={deliveryPrices[key].city} onChange={(e) => updateCity(key, e.target.value)} /></td>
                        </tr>
                    )) : null
                }
            </table>
        </div>
    )
}
import './ErrorReport.css';
import descriptionIcon from '../../assets/message.svg';
import { useState, useContext } from 'react';
import axios from 'axios';
import { ActiveTheme } from '../../App';
import { Ring } from '@uiball/loaders';

export default function ErrorReport(props) {
    const [description, setDescription] = useState('');
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const apiEndPoint = props.apiEndPoint;

    const submit = () => {
        if (description.length == 0) {
            showWarning('الرجاء تعبئة النموذج');
        } else {
            setIsBtnDisabled(true);
            axios.post(`${apiEndPoint}/api/report`, {description: description, type: 'manual'}).then(response => {
                if (response.data) {
                    showWarning('تم ارسال رسالتك, شكراً');
                    setDescription('');
                    setIsBtnDisabled(false);
                }
            });
        }
    }

    const showWarning = (str) => {
        const warning = document.querySelector('#errorReportWarning');
        const warningDiv = document.createElement('div');
        warningDiv.innerText = str;
        warning.appendChild(warningDiv);
        warning.style.display = 'block';
        warning.style['max-width'] = '415px';
        warning.style['background-color'] = mainColor();
        warning.classList.add('fadein');
        window.scrollTo(0, 0);
        setTimeout(() => {
            warning.classList.remove('fadein');
            warning.classList.add('fadeout');
        }, 10000);
        setTimeout(() => {
            warning.style.display = 'none';
            warningDiv.remove();
        }, 10300);
        warning.classList.remove('fadeout');
    }

    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    const shadowColor = () => {
        if (activeTheme === 'black') {
            return 'rgba(17,17,17, .2) 0 -25px 18px -14px inset,rgba(17,17,17, .15) 0 1px 2px,rgba(17,17,17, .15) 0 2px 4px,rgba(17,17,17, .15) 0 4px 8px,rgba(17,17,17, .15) 0 8px 16px,rgba(17,17,17, .15) 0 16px 32px';
        } else if (activeTheme === 'blue') {
            return 'rgba(151,201,233, .2) 0 -25px 18px -14px inset,rgba(151,201,233, .15) 0 1px 2px,rgba(151,201,233, .15) 0 2px 4px,rgba(151,201,233, .15) 0 4px 8px,rgba(151,201,233, .15) 0 8px 16px,rgba(151,201,233, .15) 0 16px 32px';
        } else if (activeTheme === 'green') {
            return 'rgba(93,187,79, .2) 0 -25px 18px -14px inset,rgba(93,187,79, .15) 0 1px 2px,rgba(93,187,79, .15) 0 2px 4px,rgba(93,187,79, .15) 0 4px 8px,rgba(93,187,79, .15) 0 8px 16px,rgba(93,187,79, .15) 0 16px 32px';
        } else if (activeTheme === 'purple') {
            return 'rgba(130,124,180, .2) 0 -25px 18px -14px inset,rgba(130,124,180, .15) 0 1px 2px,rgba(130,124,180, .15) 0 2px 4px,rgba(130,124,180, .15) 0 4px 8px,rgba(130,124,180, .15) 0 8px 16px,rgba(130,124,180, .15) 0 16px 32px';
        } else if (activeTheme === 'red') {
            return 'rgba(240,91,93, .2) 0 -25px 18px -14px inset,rgba(240,91,93, .15) 0 1px 2px,rgba(240,91,93, .15) 0 2px 4px,rgba(240,91,93, .15) 0 4px 8px,rgba(240,91,93, .15) 0 8px 16px,rgba(240,91,93, .15) 0 16px 32px';
        } else { // yellow
            return 'rgba(253,183,27, .2) 0 -25px 18px -14px inset,rgba(253,183,27, .15) 0 1px 2px,rgba(253,183,27, .15) 0 2px 4px,rgba(253,183,27, .15) 0 4px 8px,rgba(253,183,27, .15) 0 8px 16px,rgba(253,183,27, .15) 0 16px 32px';
        }
    }

    return (
        <div className="errorReport">
            <h1>واجهاتك مشكلة؟</h1>
            <p>أشرحلنا شن صارلك وباذن الله نحلوها</p>
            <div className="warning" id="errorReportWarning"></div>
            <div className="iconedInputField shadow">
                <img src={descriptionIcon} alt="" style={{marginRight: '10px'}}/><textarea maxLength={100} className="contactMessageBox" placeholder="اشرحلنا شن صارلك..." value={description} onChange={(e) => setDescription(e.target.value)} name="description"/>
            </div>
            <button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} disabled={isBtnDisabled} className="flatBtn" onClick={submit}>{isBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color="white" /> : 'ارسال'}</button>
        </div>
    )
}
import { useEffect, useState, useContext } from 'react';
import IconedInputField from '../IconedInputField/IconedInputField';
import './CouponGenerator.css';
import icon from '../../assets/profit.svg';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { ActiveTheme } from '../../App';
import { Ring } from '@uiball/loaders';

export default function CouponGenerator(props) {
    const [value, setValue] = useState(0);
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const apiEndPoint = props.apiEndPoint;
    const history = useHistory();

    const generate = (val) => {
        setIsBtnDisabled(true);
        axios.get(`${apiEndPoint}/api/generateCoupon/${val}`).then(response => {
            const couponText = document.getElementById('couponText');
            setIsBtnDisabled(false);
            couponText.innerText = response.data;
        });
    }

    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    const shadowColor = () => {
        if (activeTheme === 'black') {
            return 'rgba(17,17,17, .2) 0 -25px 18px -14px inset,rgba(17,17,17, .15) 0 1px 2px,rgba(17,17,17, .15) 0 2px 4px,rgba(17,17,17, .15) 0 4px 8px,rgba(17,17,17, .15) 0 8px 16px,rgba(17,17,17, .15) 0 16px 32px';
        } else if (activeTheme === 'blue') {
            return 'rgba(151,201,233, .2) 0 -25px 18px -14px inset,rgba(151,201,233, .15) 0 1px 2px,rgba(151,201,233, .15) 0 2px 4px,rgba(151,201,233, .15) 0 4px 8px,rgba(151,201,233, .15) 0 8px 16px,rgba(151,201,233, .15) 0 16px 32px';
        } else if (activeTheme === 'green') {
            return 'rgba(93,187,79, .2) 0 -25px 18px -14px inset,rgba(93,187,79, .15) 0 1px 2px,rgba(93,187,79, .15) 0 2px 4px,rgba(93,187,79, .15) 0 4px 8px,rgba(93,187,79, .15) 0 8px 16px,rgba(93,187,79, .15) 0 16px 32px';
        } else if (activeTheme === 'purple') {
            return 'rgba(130,124,180, .2) 0 -25px 18px -14px inset,rgba(130,124,180, .15) 0 1px 2px,rgba(130,124,180, .15) 0 2px 4px,rgba(130,124,180, .15) 0 4px 8px,rgba(130,124,180, .15) 0 8px 16px,rgba(130,124,180, .15) 0 16px 32px';
        } else if (activeTheme === 'red') {
            return 'rgba(240,91,93, .2) 0 -25px 18px -14px inset,rgba(240,91,93, .15) 0 1px 2px,rgba(240,91,93, .15) 0 2px 4px,rgba(240,91,93, .15) 0 4px 8px,rgba(240,91,93, .15) 0 8px 16px,rgba(240,91,93, .15) 0 16px 32px';
        } else { // yellow
            return 'rgba(253,183,27, .2) 0 -25px 18px -14px inset,rgba(253,183,27, .15) 0 1px 2px,rgba(253,183,27, .15) 0 2px 4px,rgba(253,183,27, .15) 0 4px 8px,rgba(253,183,27, .15) 0 8px 16px,rgba(253,183,27, .15) 0 16px 32px';
        }
    }

    useEffect(() => {
        axios.get(`${apiEndPoint}/api/login`).then(response => {
            if (response.data.loggedin === false || response.data.user.isAdmin === 0) {
                history.replace("/");
            }
        });
    }, []);

    return ( 
        <div className="couponGenerator">
            <h1>اختر قيمة للكوبون</h1>
            <div className='adminActionBtns'>
                <button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} disabled={isBtnDisabled} className="btn" onClick={() => generate(5)}>5 د.ل</button>
                <button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} disabled={isBtnDisabled} className="btn" onClick={() => generate(10)}>10 د.ل</button>
                <button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} disabled={isBtnDisabled} className="btn" onClick={() => generate(20)}>20 د.ل</button>
                <button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} disabled={isBtnDisabled} className="btn" onClick={() => generate(50)}>50 د.ل</button>
            </div>
            <p>او</p>
            <h1 id="couponText"></h1>
            <div className="question">
                <IconedInputField image={icon} placeholder="ادخل قيمة اخرى" onChange={e => setValue(e.target.value)} />
            </div>
            <button disabled={isBtnDisabled} style={{backgroundColor: mainColor()}} className="flatBtn" onClick={() => generate(value)}>{isBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color="white" /> : 'توليد'}</button>
        </div>
    )
}
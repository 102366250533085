import './DeliveriesAdder.css';
import IconedInputField from '../IconedInputField/IconedInputField';
import name from '../../assets/name.svg';
import redPhone from '../../assets/redPhone.svg';
import { useState, useEffect, useContext } from 'react';
import redLocation from '../../assets/redLocation.svg';
import searchIcon from '../../assets/searchIcon.svg';
import axios from 'axios';
import priceIcon from '../../assets/price.svg';
import { useHistory } from 'react-router-dom';
import MyGlider from '../MyGlider/MyGlider';
import { ActiveTheme } from '../../App';

export default function DeliveriesAdder(props) {
    const [username, setUsername] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [books, setBooks] = useState([]);
    const [topFanCode, setTopFanCode] = useState();
    const [deliverOnFriday, setDeliverOnFriday] = useState(false);
    const [cart, setCart] = useState({});
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);
    const apiEndPoint = props.apiEndPoint;
    let time;
    const history = useHistory();


    useEffect(() => {
        axios.get(`${apiEndPoint}/api/login`).then((response) => {
            if (response.data.loggedin === false || response.data.user.isDriver === 0) {
                history.replace("/");
            }
        });
    }, []);


    const showBookSelectionSection = () => {
        const upperSection = document.querySelector('.deliveriesAdderUpperSection');
        const bookSelectionSection = document.querySelector('.deliveriesAdderBookSelectionSection');
        if (username.length == 0) {
            showWarning('ادخل اسم المشتري');
            return;
        }
        if (phoneNumber.length == 0) {
            showWarning('الرجاء ادخال رقم هاتف المشتري');
            return;
        }
        if (address.length == 0) {
            showWarning('الرجاء ادخال عنوان المتستخدم');
            return;
        }
        if (topFanCode !== undefined && topFanCode?.trim().length !== 0) {
            if (isNaN(topFanCode)) {
                showWarning('الرجاء تعبئة خانة كود التوب فان بشكل صحيح');
                return;
            }
        } else {
            setTopFanCode(0);
        }
        upperSection.style.display = 'none';
        bookSelectionSection.style.display = 'block';
    }

    const search = (name) => {
        if (time) {
            clearTimeout(time);
        }
        if (name.length == 0) {
            setBooks([]);
        } else {
            time = setTimeout(() => {
                axios.get(`${apiEndPoint}/api/adminSearch/${name}`).then(response => {
                    setBooks(response.data);
                });
            }, 800);
        }
    }

    const bookPrice = (topFanSale, price, sale) => {
        // {(val.topFanSale === 1 && Number(user.cardNumber) !== 0) ? val.price - 5 : val.price}  
        if (topFanSale === 1 && Number(topFanCode) !== 0) {
            return price - (5 + sale);
        } else {
            return price - sale;
        }
    }

    const addToCart = (id) => {
        let book = books.filter(val => val.id == id)[0];
        console.log(book.title);
        let newCart = cart;
        newCart[book.title] = {
            quantity: 1,
            singleSellProfit: book.singleSellProfit,
            singleSellSale: (book.topFanSale == 1 && Number(topFanCode) !== 0) ? book.sale + 5 : book.sale,
            singleBookPrice: book.price,
        }
        setCart({...newCart});
    }

    const increaseQuantity = (title) => {
        if (cart[title].quantity < 4) {
            let newCart = cart;
            newCart[title].quantity = newCart[title].quantity + 1; 
            setCart({...newCart});
        }
    }

    const decreaseQuantity = (title) => {
        if (cart[title].quantity > 1) {
            let newCart = cart;
            newCart[title].quantity = newCart[title].quantity - 1; 
            setCart({...newCart});
        }
    }

    const removeBook = (title) => {
        let newCart = cart;
        delete newCart[title];
        setCart({...newCart});
    }

    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    const showWarning = (str) => {
        const warning = document.querySelector('#deliveriesAdderWarning');
        const warningDiv = document.createElement('div');
        warningDiv.innerText = str;
        warning.appendChild(warningDiv);
        warning.style.display = 'block';
        warning.style['max-width'] = '415px';
        warning.style['background-color'] = mainColor();
        warning.classList.add('fadein');
        window.scrollTo(0, 0);
        setTimeout(() => {
            warning.classList.remove('fadein');
            warning.classList.add('fadeout');
        }, 10000);
        setTimeout(() => {
            warning.style.display = 'none';
            warningDiv.remove();
        }, 10300);
        warning.classList.remove('fadeout');
    }

    const submitToDriver = () => {
        const deliverySubmitbtn = document.getElementById('deliverySubmitbtn');
        deliverySubmitbtn.disabled = true;
        deliverySubmitbtn.classList.remove('flatBtn');
        deliverySubmitbtn.classList.add('flatBtn-clicked');
        let totalPrice = 0;
        let titles = Object.keys(cart);
        for (let i=0;i<titles.length;i++) {
            totalPrice += cart[titles[i]].quantity * (cart[titles[i]].singleBookPrice - cart[titles[i]].singleSellSale);
        }
        axios.post(`${apiEndPoint}/api/sendToDriver`, { username: username, phoneNumber: phoneNumber, price: totalPrice, address: address,  cartObj: cart, userMessage: '', manual: true, deliverOnFriday: deliverOnFriday }).then(response => {
            if (response.data) {
                history.push('/driver');
            }
        });
    }

    const hideBookSelectionSection = () => {
        const upperSection = document.querySelector('.deliveriesAdderUpperSection');
        const bookSelectionSection = document.querySelector('.deliveriesAdderBookSelectionSection');
        upperSection.style.display = 'block';
        bookSelectionSection.style.display = 'none';
    }

    const shadowColor = () => {
        if (activeTheme === 'black') {
            return 'rgba(17,17,17, .2) 0 -25px 18px -14px inset,rgba(17,17,17, .15) 0 1px 2px,rgba(17,17,17, .15) 0 2px 4px,rgba(17,17,17, .15) 0 4px 8px,rgba(17,17,17, .15) 0 8px 16px,rgba(17,17,17, .15) 0 16px 32px';
        } else if (activeTheme === 'blue') {
            return 'rgba(151,201,233, .2) 0 -25px 18px -14px inset,rgba(151,201,233, .15) 0 1px 2px,rgba(151,201,233, .15) 0 2px 4px,rgba(151,201,233, .15) 0 4px 8px,rgba(151,201,233, .15) 0 8px 16px,rgba(151,201,233, .15) 0 16px 32px';
        } else if (activeTheme === 'green') {
            return 'rgba(93,187,79, .2) 0 -25px 18px -14px inset,rgba(93,187,79, .15) 0 1px 2px,rgba(93,187,79, .15) 0 2px 4px,rgba(93,187,79, .15) 0 4px 8px,rgba(93,187,79, .15) 0 8px 16px,rgba(93,187,79, .15) 0 16px 32px';
        } else if (activeTheme === 'purple') {
            return 'rgba(130,124,180, .2) 0 -25px 18px -14px inset,rgba(130,124,180, .15) 0 1px 2px,rgba(130,124,180, .15) 0 2px 4px,rgba(130,124,180, .15) 0 4px 8px,rgba(130,124,180, .15) 0 8px 16px,rgba(130,124,180, .15) 0 16px 32px';
        } else if (activeTheme === 'red') {
            return 'rgba(240,91,93, .2) 0 -25px 18px -14px inset,rgba(240,91,93, .15) 0 1px 2px,rgba(240,91,93, .15) 0 2px 4px,rgba(240,91,93, .15) 0 4px 8px,rgba(240,91,93, .15) 0 8px 16px,rgba(240,91,93, .15) 0 16px 32px';
        } else { // yellow
            return 'rgba(253,183,27, .2) 0 -25px 18px -14px inset,rgba(253,183,27, .15) 0 1px 2px,rgba(253,183,27, .15) 0 2px 4px,rgba(253,183,27, .15) 0 4px 8px,rgba(253,183,27, .15) 0 8px 16px,rgba(253,183,27, .15) 0 16px 32px';
        }
    }

    console.log(cart);

    return (
        <div className="deliveriesAdder">
            <h1>اضافة طلبية</h1>
            <table className="detailedInfo">
                {
                    Object.keys(cart).map(val => (
                        <tr>
                            <td>
                                <p>{cart[val].quantity}</p>
                            </td>
                            <td>
                                <b>{val}</b>
                            </td>
                        </tr>
                    ))
                }
            </table>
            <div className="warning" id="deliveriesAdderWarning"></div>
            <div className="deliveriesAdderUpperSection">
                <div className="question">
                    <label>:اسم المشتري</label>
                    <IconedInputField image={name} name="name" type="text" onChange={(e) => setUsername(e.target.value)} />
                </div>
                <div className="question">
                    <label>:رقم الهاتف</label>
                    <IconedInputField image={redPhone} name="phoneNumber" type="text" onChange={(e) => setPhoneNumber(e.target.value)} />
                </div>
                <div className="question">
                    <label>:العنوان</label>
                    <div className="iconedInputField shadow">
                        <textarea className="contactMessageBox" onChange={e => setAddress(e.target.value)}/><img src={redLocation} alt=""/>
                    </div>
                </div>
                <div className="question">
                    <label>:كود توب فان</label>
                    <IconedInputField placeholder="كود توب فان" image={priceIcon} name="topFanSale" type="text" onChange={(e) => setTopFanCode(e.target.value)} />
                </div>
                <div className="question">
                    <input type="checkbox" checked={deliverOnFriday} onChange={e => setDeliverOnFriday(e.target.checked)}/>
                    <label>:توصيل يوم الجمعة</label>
                </div>
                <button className='flatBtn' onClick={showBookSelectionSection} style={{ backgroundColor: mainColor() }}>التالي</button>
            </div>
            <div className="deliveriesAdderBookSelectionSection">
                <IconedInputField placeholder="ابحث عن" image={searchIcon} name="bookSearch" type="text" onChange={(e) => search(e.target.value)} />
                {
                    books.map(val => (
                        <div className="cartCard" id={val.id + val.title}>
                            <div className="cartCardRightSide">
                                <h3>{val.title}</h3>
                                <h4>{bookPrice(val.topFanSale, val.price, val.sale)}</h4>
                                {(cart[val.title]) ? (<><div className="counter"><button onClick={() => decreaseQuantity(val.title)}>-</button><span>{cart[val.title].quantity}</span><button onClick={() => increaseQuantity(val.title)}>+</button></div><br /></>) : null}
                                {/* <p className="removeFromCart"><i class="fas fa-cart-arrow-down"></i> حذف</p> */}
                                {(cart[val.title]) ? (<p className="removeFromCart" onClick={() => removeBook(val.title)}><i class="fas fa-cart-arrow-down"></i> ازالة</p>) : (<p className="removeFromCart" style={{color: 'black'}} onClick={() => addToCart(val.id)}><i class="fas fa-check"></i> اضف</p>)}
                            </div>
                            <img src={val.img} alt="" />
                        </div>
                    ))
                }
                <button id="deliverySubmitbtn" className="flatBtn" onClick={submitToDriver} style={{ backgroundColor: mainColor() }}>اضافة للقائمة</button>
                <button className="flatBtn-clicked" onClick={hideBookSelectionSection} style={{ borderColor: mainColor(), color: mainColor() }}>العودة</button>
            </div>
        </div>
    )
}


import './Book.css';
import MyGlider, { ProsGlider } from '../MyGlider/MyGlider';
import Card from '../Card/Card';
import { Link, useLocation } from 'react-router-dom';
import like from '../../assets/like.svg';
import dislike from '../../assets/dislike.svg';
import unpressedLike from '../../assets/unpressedLike.svg';
import unpressedDislike from '../../assets/unpressedDislike.svg';
import { useEffect, useState } from 'react';
import Axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import downloadPdfIcon from '../../assets/downloadPdf.svg';
import axios from 'axios';
import writerLogo from '../../assets/writerLogo.svg';
import pagesLogo from '../../assets/pagesLogo.svg';
import priceLogo from '../../assets/priceLogo.svg';
import religionLogo from '../../assets/religionLogo.svg';
import novelLogo from '../../assets/novelLogo.svg';
import textsLogo from '../../assets/textsLogo.svg';
import selfDevelopmentLogo from '../../assets/selfDevelopment.svg';
import psychologyLogo from '../../assets/psychologyLogo.svg';
import scienceLogo from '../../assets/scienceLogo.svg';
import notesLogo from '../../assets/notesLogo.svg';
import interactiveLogo from '../../assets/interactiveLogo.svg';
import paperbookLogo from '../../assets/paperbookLogo.svg';
import exclusiveLogo from '../../assets/exclusiveLogo.svg';
import preorderLogo from '../../assets/preorderLogo.svg';
import accessoriesLogo from '../../assets/accessoriesLogo.svg';
import ebookLogo from '../../assets/ebooksLogo.svg';
import englishLogo from '../../assets/englishLogo.svg';
import cardsLogo from '../../assets/cardsLogo.svg';
import BooksSlider from '../BooksSlider/BooksSlider';
import { Howl, Howler } from 'howler';
import walletAudio from '../../assets/walletAudio.mp3';
import originalLogo from '../../assets/originalLogo.svg';
import { NumOfCartItems } from '../../App';
import { useContext } from 'react';
import { ActiveTheme } from '../../App';
import { Ring } from '@uiball/loaders';



export default function Book(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [bookData, setBookData] = useState([]);
    const [moreBooksFromAuthor, setMoreBooksFromAuthor] = useState([]);
    const [random, setRandom] = useState([]);
    const [user, setUser] = useState();
    const [isLikePressed, setIsLikePressed] = useState();
    const [isDislikePressed, setIsDislikePressed] = useState();
    const [numOfLikes, setNumOfLikes] = useState(0);
    const [numOfDislikes, setNumOfDislikes] = useState(0);
    const [isBookInCart, setIsBookInCart] = useState(false);
    const [isBookOut, setIsBookOut] = useState(false);
    const { numOfCartItems, setNumOfCartItems } = useContext(NumOfCartItems);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [isBuyBtnDisabled, setIsBuyBtnDisabled] = useState(false);
    const [showReactionBtns, setShowReactionBtns] = useState(true);
    const history = useHistory();
    const location = useLocation();
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);

    const sound = new Howl({
        src: [walletAudio],
    });

    const apiEndPoint = props.apiEndPoint;
    let time;

    let { title } = useParams();

    const bookPrice = () => {
        // (bookData.topFanSale === 1 && Number(user.cardNumber) !== 0) ? (bookData.sale !== 0) ?  : (<><span id="price" style={{opacity: .7, textDecoration: 'line-through'}}>{bookData.price}</span>   <span id="price" style={{fontSize: '1.2em', fontWeight: 'bold'}}>{Number(bookData.price) - 5}</span></>) : (bookData.sale !== 0) ? (bookData.sale !== 0) ? (<><span id="price" style={{opacity: .7, textDecoration: 'line-through'}}>{bookData.price}</span>   <span id="price" style={{fontSize: '1.2em', fontWeight: 'bold'}}>{Number(bookData.price) - bookData.sale}</span></>) : (<span id="price">{bookData.price}</span>)
        if (bookData.topFanSale === 1 && isUserLoggedIn && Number(user.cardNumber) !== 0) {
            return (
                <><span id="price" style={{opacity: .7, textDecoration: 'line-through'}}>{bookData.price}</span>   <span id="price" style={{fontSize: '1.2em', fontWeight: 'bold'}}>{Number(bookData.price) - (5 + bookData.sale)}</span></>
            )
        } else if (bookData.sale !== 0) {
            return (
                <><span id="price" style={{opacity: .7, textDecoration: 'line-through'}}>{bookData.price}</span>   <span id="price" style={{fontSize: '1.2em', fontWeight: 'bold'}}>{Number(bookData.price) - bookData.sale}</span></>
            )
        } else {
            return (
                <span id="price">{bookData.price}</span>
            )
        }
    }

    const gettingData = async() => {
        Axios.get(`${apiEndPoint}/api/login`).then(user => {
            setIsUserLoggedIn(user.data.loggedin);
            Axios.get(`${apiEndPoint}/api/getBook/${title}`).then(book => {
                if (book.data.length === 0) {
                    history.push('/');
                }
                if (book.data[0].quantity === 0) {
                    setIsBookOut(true);
                }
                setBookData(book.data[0]);
                document.title = `Paperbook - ${book.data[0].title}`;
                setNumOfLikes(book.data[0].likes);
                setNumOfDislikes(book.data[0].dislikes);
                Axios.get(`${apiEndPoint}/api/getAuthor/${book.data[0].author}`).then(authorBooks => {
                    setMoreBooksFromAuthor(authorBooks.data.filter(val => val.id !== Number(book.data[0].id)));
                });
                if (user.data.loggedin) {
                    user.data.user.cart = user.data.user.cart;
                    setUser(user.data.user);
                    Axios.get(`${apiEndPoint}/api/isBookInCart/${book.data[0].id}/${user.data.user.id}`).then(response => {
                        if (response.data) {
                            setIsBookInCart(true);
                        }
                    });
                    Axios.get(`${apiEndPoint}/api/reactionCheck/${user.data.user.id}/${book.data[0].id}`).then((response) => {
                        if (response.data.length > 0) {
                            if (response.data[0].liked == 1) {
                                setIsLikePressed(true);
                                setIsDislikePressed(false);
                            } else if (response.data[0].liked == 0) {
                                setIsLikePressed(false);
                                setIsDislikePressed(true);
                            }
                        }
                    });
                }
                Axios.get(`${apiEndPoint}/api/random`).then(result => {
                    setRandom(result.data.filter(val => val.id !== Number(book.id)));
                });
                setIsLoading(false);
            })
        });
    }

    const unDislike = () => {
        Axios.post(`${apiEndPoint}/api/undislike`, {
            userId: user['id'],
            bookId: bookData['id'],
        }).then (response => {
            if (response.data) {
                (numOfDislikes - 1 >= 0) ? setNumOfDislikes(numOfDislikes - 1) : setNumOfDislikes(numOfDislikes);
                setShowReactionBtns(true);
                setIsDislikePressed(false);
            }
        });
    }

    const unLike = () => {
        Axios.post(`${apiEndPoint}/api/unlike`, {
            userId: user['id'],
            bookId: bookData['id'],
        }).then (response => {
            if (response.data) {
                (numOfLikes - 1 >= 0) ? setNumOfLikes(numOfLikes - 1) : setNumOfLikes(numOfLikes);
                setShowReactionBtns(true);
                setIsLikePressed(false);
            }
        });
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        gettingData();
    }, []);


    const addToCart = () => {
        if (isUserLoggedIn) {
            setIsBuyBtnDisabled(true);
            Axios.post(`${apiEndPoint}/api/addToCart`, {
                userId: user['id'],
                bookId: bookData['id'],
                isTopFanSale: (bookData.topFanSale === 1 && Number(user.cardNumber) !== 0),
                sale: bookData.sale,
                singleBookPrice: bookData.price,
                singleSellProfit: bookData.singleSellProfit,
            }).then(response => {
                if (response.data) {
                    setIsBookInCart(true);
                    sound.play();
                    setNumOfCartItems(numOfCartItems + 1);
                }
            });
        } else {
            history.replace('/login');
        }
    }


    const likeBtn = () => {
        setShowReactionBtns(false);
        if (isLikePressed) {
            unLike();
        } else {
            if (isDislikePressed) {
                unDislike();
            }
            Axios.post(`${apiEndPoint}/api/like`, {
                userId: user.id,
                bookId: bookData['id'],
            }).then (response => {
                if (response.data) {
                    setNumOfLikes(numOfLikes + 1);
                    setIsLikePressed(true);
                    setShowReactionBtns(true);
                }
            });
        }
    }

    const dislikeBtn = () => {
        setShowReactionBtns(false);
        if (isDislikePressed) {
            unDislike();
        } else {
            if (isLikePressed) {
                unLike();
            }
            Axios.post(`${apiEndPoint}/api/dislike`, {
                userId: user['id'],
                bookId: bookData['id'],
            }).then(response => {
                if (response.data) {
                    setNumOfDislikes(numOfDislikes + 1);
                    setShowReactionBtns(true);
                }
            });
            setIsDislikePressed(true);
        }
    }

    const downloadBook = () => {
        const pdfDownloadBtn = document.getElementById('pdfDownloadBtn');
        pdfDownloadBtn.disabled = true;
        pdfDownloadBtn.classList.remove('btn');
        pdfDownloadBtn.classList.add('btn-clicked');
        // fetch(bookData.pdfLink, { mode: "no-cors" }).then(res => res.blob()).then(file => {
        //     let tempUrl = URL.createObjectURL(file);
        //     let aTag = document.createElement("a");
        //     aTag.href = tempUrl;
        //     aTag.download = bookData.title;
        //     document.body.appendChild(aTag);
        //     aTag.click();
        //     aTag.remove();
        // });
        window.open(bookData.pdfLink, '_blank');
    }

    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    const shadowColor = () => {
        if (activeTheme === 'black') {
            return 'rgba(17,17,17, .2) 0 -25px 18px -14px inset,rgba(17,17,17, .15) 0 1px 2px,rgba(17,17,17, .15) 0 2px 4px,rgba(17,17,17, .15) 0 4px 8px,rgba(17,17,17, .15) 0 8px 16px,rgba(17,17,17, .15) 0 16px 32px';
        } else if (activeTheme === 'blue') {
            return 'rgba(151,201,233, .2) 0 -25px 18px -14px inset,rgba(151,201,233, .15) 0 1px 2px,rgba(151,201,233, .15) 0 2px 4px,rgba(151,201,233, .15) 0 4px 8px,rgba(151,201,233, .15) 0 8px 16px,rgba(151,201,233, .15) 0 16px 32px';
        } else if (activeTheme === 'green') {
            return 'rgba(93,187,79, .2) 0 -25px 18px -14px inset,rgba(93,187,79, .15) 0 1px 2px,rgba(93,187,79, .15) 0 2px 4px,rgba(93,187,79, .15) 0 4px 8px,rgba(93,187,79, .15) 0 8px 16px,rgba(93,187,79, .15) 0 16px 32px';
        } else if (activeTheme === 'purple') {
            return 'rgba(130,124,180, .2) 0 -25px 18px -14px inset,rgba(130,124,180, .15) 0 1px 2px,rgba(130,124,180, .15) 0 2px 4px,rgba(130,124,180, .15) 0 4px 8px,rgba(130,124,180, .15) 0 8px 16px,rgba(130,124,180, .15) 0 16px 32px';
        } else if (activeTheme === 'red') {
            return 'rgba(240,91,93, .2) 0 -25px 18px -14px inset,rgba(240,91,93, .15) 0 1px 2px,rgba(240,91,93, .15) 0 2px 4px,rgba(240,91,93, .15) 0 4px 8px,rgba(240,91,93, .15) 0 8px 16px,rgba(240,91,93, .15) 0 16px 32px';
        } else { // yellow
            return 'rgba(253,183,27, .2) 0 -25px 18px -14px inset,rgba(253,183,27, .15) 0 1px 2px,rgba(253,183,27, .15) 0 2px 4px,rgba(253,183,27, .15) 0 4px 8px,rgba(253,183,27, .15) 0 8px 16px,rgba(253,183,27, .15) 0 16px 32px';
        }
    }

    const getGenreImg = (genre) => {
        if (genre == 'ديني') {
            return religionLogo;
        } else if (genre == 'رواية') {
            return novelLogo;
        } else if (genre == 'نصوص') {
            return textsLogo;
        } else if (genre == 'تطوير ذات') {
            return selfDevelopmentLogo;
        } else if (genre == 'علوم') {
            return scienceLogo;
        } else if (genre == 'مذكرات') {
            return notesLogo;
        } else if (genre == 'علم النفس') {
            return psychologyLogo;
        } else if (genre == 'مانجا') {
            return novelLogo;
        } else if (genre == 'تفاعلي') {
            return interactiveLogo;
        } else if (genre == 'بيبربوك') {
            return paperbookLogo;
        } else if (genre == 'حصري') {
            return exclusiveLogo;
        } else if (genre == 'كتب اصلية') {
            return originalLogo;
        } else if (genre == 'طلب مسبق') {
            return preorderLogo;
        } else if (genre == 'اكسسوارات') {
            return accessoriesLogo;
        } else if (genre == 'كتب الكترونية') {
            return ebookLogo;
        } else if (genre == 'كتب انجليزية') {
            return englishLogo;
        } else if (genre == 'كروت') {
            return cardsLogo;
        }
    }

    console.log(moreBooksFromAuthor);

    if (isLoading) {
        return (
            <div className="bookLoading">
                <div className="bookTopSection">
                    <img className="skeleton card" />
                    <div className="bookInfo loading">
                        <div className="bookDescription skeleton" ></div>
                        <div className="bookDescription skeleton" ></div>
                        <div className="bookDescription skeleton" ></div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="book">
            <div className="bookTopSection">
                <div className="bookInfo">
                    <div className="titleAndLiks">
                        <h1 style={{direction: 'rtl'}}>{bookData.title}</h1>
                        <div className="liks" style={{opacity: isUserLoggedIn ? null : 0}}>
                            {
                                showReactionBtns ? (
                                    <>
                                        <button className="likingBtn" onClick={likeBtn} id="likeBtn">
                                            <img src={(isLikePressed) ? like : unpressedLike} alt="" id="likeBtnImg"/>
                                        </button>
                                        <span id="likes">{numOfLikes}</span>
                                        <button className="likingBtn" onClick={dislikeBtn} id="dislikeBtn">
                                            <img src={(isDislikePressed) ? dislike : unpressedDislike} alt="" id="dislikeBtnImg"/>
                                        </button>
                                        <span id="likes">{numOfDislikes}</span>
                                    </>
                                ) : (
                                    <Ring size={20} lineWeight={5} speed={2} color={mainColor()} />
                                )
                            }
                        </div>
                    </div>
                        <p className='bookDescription'>
                            {bookData.description}
                        </p>
                        <div className='bookInfoCardsContainer'>
                            <div className="bookInfoCard">
                                <b>السعر</b>
                                {/* <img src={priceLogo} alt="" /> */}
                                <p style={{direction: 'rtl'}}>{bookPrice()} د.ل</p>
                            </div>
                            <div className="bookInfoCard">
                                <b>الكاتب</b>
                                {/* <img src={writerLogo} alt="" /> */}
                                <p>{bookData.author}</p>
                            </div>
                            <div className="bookInfoCard">
                                <b>النوع</b>
                                {/* <img src={getGenreImg(bookData.genre)} alt="" /> */}
                                <p>{bookData.secondGenre ? `${bookData.genre} - ${bookData.secondGenre}` : bookData.genre}</p>
                            </div>
                            <div className="bookInfoCard">
                                <b>عدد الصفحات</b>
                                {/* <img src={pagesLogo} alt="" /> */}
                                <p>{bookData.pages}</p>
                            </div>
                        </div>
                        <div className='bookActionBtns'>
                            {
                                (bookData.pdfLink) ? (
                                    <button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} id="pdfDownloadBtn" className='btn pdfDownloadSection' onClick={downloadBook}>
                                        تحميل PDF مجاني 😎
                                    </button>
                                ) : null
                            }
                            {
                                (bookData.genre !== 'كتب الكترونية') ? (
                                    (isBookOut) ? (
                                        <button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="btn-clicked">
                                            <i class="fas fa-heart-broken"></i> كمل للاسف
                                        </button>
                                    ) : (isUserLoggedIn && isBookInCart) ? (
                                            <button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="btn-clicked">
                                            في العربة <i class="fas fa-check" style={{marginRight: '.4em'}}></i>
                                        </button>
                                    ) : (
                                        (
                                            <button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="btn" onClick={addToCart} disabled={isBuyBtnDisabled}>
                                                {isBuyBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color="white" /> : 'أضف إلي العربة +'}
                                            </button>
                                        )
                                    )
                                ) : null
                            }
                        </div>
                </div>
                <img src={bookData.img} style={(bookData.genre === 'كروت') ? {maxWidth: '300px', height: 'auto'} : null} alt="" className="hardShadow"/>
            </div>
            <div className="moreFrom">
                <BooksSlider listOfBooks={moreBooksFromAuthor} gliderName="booksFromAuthor" title={`المزيد من ${(bookData.author).split(' ')[0]}`} />
            </div>
            <div className="sameGenre">
                <BooksSlider listOfBooks={random} gliderName="randomBook" title="المزيد من الكتب" />
            </div>
        </div>
    )
}
import { useEffect, useState, useContext } from 'react';
import './HomeCoverChanger.css';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { ActiveTheme } from '../../App';
import stickerIcon from '../../assets/stickerIcon.svg';
import smallScreensIcon from '../../assets/smallScreensIcon.svg';
import largeScreensIcon from '../../assets/largeScreensIcon.svg';

export default function HomeCoverChanger(props) {
    const apiEndPoint = props.apiEndPoint;
    const [img, setImg] = useState();
    const [desktopImg, setDesktopImg] = useState();
    const [sticker, setSticker] = useState();
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);
    const [showSmallScreenSection, setShowSmallScreenSection] = useState(false);
    const [showLargeScreenSection, setShowLargeScreenSection] = useState(false);
    const [showStickerSection, setShowStickerSection] = useState(false);
    const themesColors = ['rgb(17,17,17)', 'rgb(151,201,233)', 'rgb(93,187,79)', 'rgb(130,124,180)', 'rgb(240,91,93)', 'rgb(253,183,27)'];
    const themes = ['black', 'blue', 'green', 'purple', 'red', 'yellow'];
    const history = useHistory();

    useEffect(() => {
        axios.get(`${apiEndPoint}/api/login`).then(response => {
            if (response.data.loggedin === false || response.data.user.isAdmin === 0) {
                history.replace("/");
            }
        });
    }, []);

    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    const shadowColor = () => {
        if (activeTheme === 'black') {
            return 'rgba(17,17,17, .2) 0 -25px 18px -14px inset,rgba(17,17,17, .15) 0 1px 2px,rgba(17,17,17, .15) 0 2px 4px,rgba(17,17,17, .15) 0 4px 8px,rgba(17,17,17, .15) 0 8px 16px,rgba(17,17,17, .15) 0 16px 32px';
        } else if (activeTheme === 'blue') {
            return 'rgba(151,201,233, .2) 0 -25px 18px -14px inset,rgba(151,201,233, .15) 0 1px 2px,rgba(151,201,233, .15) 0 2px 4px,rgba(151,201,233, .15) 0 4px 8px,rgba(151,201,233, .15) 0 8px 16px,rgba(151,201,233, .15) 0 16px 32px';
        } else if (activeTheme === 'green') {
            return 'rgba(93,187,79, .2) 0 -25px 18px -14px inset,rgba(93,187,79, .15) 0 1px 2px,rgba(93,187,79, .15) 0 2px 4px,rgba(93,187,79, .15) 0 4px 8px,rgba(93,187,79, .15) 0 8px 16px,rgba(93,187,79, .15) 0 16px 32px';
        } else if (activeTheme === 'purple') {
            return 'rgba(130,124,180, .2) 0 -25px 18px -14px inset,rgba(130,124,180, .15) 0 1px 2px,rgba(130,124,180, .15) 0 2px 4px,rgba(130,124,180, .15) 0 4px 8px,rgba(130,124,180, .15) 0 8px 16px,rgba(130,124,180, .15) 0 16px 32px';
        } else if (activeTheme === 'red') {
            return 'rgba(240,91,93, .2) 0 -25px 18px -14px inset,rgba(240,91,93, .15) 0 1px 2px,rgba(240,91,93, .15) 0 2px 4px,rgba(240,91,93, .15) 0 4px 8px,rgba(240,91,93, .15) 0 8px 16px,rgba(240,91,93, .15) 0 16px 32px';
        } else { // yellow
            return 'rgba(253,183,27, .2) 0 -25px 18px -14px inset,rgba(253,183,27, .15) 0 1px 2px,rgba(253,183,27, .15) 0 2px 4px,rgba(253,183,27, .15) 0 4px 8px,rgba(253,183,27, .15) 0 8px 16px,rgba(253,183,27, .15) 0 16px 32px';
        }
    }

    return (
        <div className="homeCoverChanger">
            <h1>بانر الصفحة الرئيسية</h1>
            <div className="homeCoverChange-imageOptionsContainer" style={{display: showLargeScreenSection || showSmallScreenSection || showStickerSection ? 'none' : 'flex'}}>
                <div className="homeCoverChange-imageOption">
                    <img src={largeScreensIcon} alt="" />
                    <h2>الشاشات الكبرى</h2>
                    <button style={{backgroundColor: mainColor()}} onClick={() => setShowLargeScreenSection(true)} className="flatBtn">تعديل</button>
                </div>
                <div className="homeCoverChange-imageOption">
                    <img src={smallScreensIcon} alt="" />
                    <h2>الشاشات الصغرى</h2>
                    <button style={{backgroundColor: mainColor()}} onClick={() => setShowSmallScreenSection(true)} className="flatBtn">تعديل</button>
                </div>
                <div className="homeCoverChange-imageOption">
                    <img src={stickerIcon} alt="" />
                    <h2>الستكرات</h2>
                    <button style={{backgroundColor: mainColor()}} onClick={() => setShowStickerSection(true)} className="flatBtn">تعديل</button>
                </div>
            </div>
            <div className="homeCoverChange-largeScreenSection" style={{display: showLargeScreenSection ? 'block' : 'none'}}>
                <img style={{ marginBottom: '.5em' }} src={(desktopImg) ? URL.createObjectURL(desktopImg) : `${apiEndPoint}/uploads/desktopBG.webp`} alt="" />
                <form action={`${apiEndPoint}/api/admin/changeDesktopHomeCover`} method="post" encType="multipart/form-data">
                    <label className="flatBtn" style={{ backgroundColor: mainColor(), padding: '.5em 1em' }} htmlFor="desktopBanner">اختيار صورة للشاشات الكبرى</label>
                    <button style={{backgroundColor: mainColor(), display: 'block', margin: '.5em auto'}} className="flatBtn" onClick={(e) => {e.preventDefault(); setShowLargeScreenSection(false)}}>العودة</button>
                    <input type="file" name="desktopBanner" onChange={(e) => setDesktopImg(e.target.files[0])} id="desktopBanner"/>
                    {
                        (desktopImg) ? (
                            <button style={{backgroundColor: mainColor()}} className="flatBtn">تحديث</button>
                        ) : null
                    }
                </form>
            </div>
            <div className="homeCoverChange-smallScreenSection" style={{display: showSmallScreenSection ? 'block' : 'none'}}>
                <img style={{ marginBottom: '.5em' }} src={(img) ? URL.createObjectURL(img) : `${apiEndPoint}/uploads/homeCover.webp`} alt="" />
                <form action={`${apiEndPoint}/api/admin/changeHomeCover`} method="post" encType="multipart/form-data">
                    <label className="flatBtn" style={{ backgroundColor: mainColor(), padding: '.5em 1em' }} htmlFor="banner">اختيار صورة للشاشات الصغرى</label>
                    <button style={{backgroundColor: mainColor(), display: 'block', margin: '.5em auto'}} className="flatBtn" onClick={(e) => {e.preventDefault(); setShowSmallScreenSection(false)}}>العودة</button>
                    <input type="file" name="banner" onChange={(e) => setImg(e.target.files[0])} id="banner"/>
                    {
                        (img) ? (
                            <button style={{backgroundColor: mainColor()}} className="flatBtn">تحديث</button>
                        ) : null
                    }
                </form>
            </div>
            <div className="homeCoverChange-stickerSection" style={{display: showStickerSection ? 'block' : 'none'}}>
                <div>
                    <img src={(sticker) ? URL.createObjectURL(sticker) : `${apiEndPoint}/uploads/${activeTheme}Sticker.png`} alt="" />
                    <div className='homeCoverChange-stickerSectionColorChoices'>
                        {
                            themesColors.map((color, index) => (
                                <div className="homeCoverChange-stickerSectionColorChoice" style={{backgroundColor: color}} onClick={() => setActiveTheme(themes[index])}></div>
                            ))
                        }
                    </div>
                </div> 
                <form action={`${apiEndPoint}/api/admin/changeSticker/${activeTheme}`} method="post" encType="multipart/form-data">
                    <button type="button" style={{backgroundColor: mainColor()}} className='flatBtn'><label htmlFor="sticker" >اختيار ستكر جديد</label></button>
                    <input type="file" name="sticker" onChange={(e) => setSticker(e.target.files[0])} id="sticker"/>
                    {
                        (sticker) ? (
                            <button type="submit" style={{backgroundColor: mainColor()}} className="flatBtn">تحديث</button>
                        ) : null
                    }
                </form>
                <button style={{backgroundColor: mainColor(), display: 'block', margin: '0 auto'}} className='flatBtn' onClick={(e) => {e.preventDefault(); setShowStickerSection(false)}}>العودة</button>
            </div>
            {/* <img src={(img) ? URL.createObjectURL(img) : `${apiEndPoint}/uploads/homeCover.webp`} alt="" />
            <form action={`${apiEndPoint}/api/admin/changeHomeCover`} method="post" encType="multipart/form-data">
                <label style={{backgroundColor: mainColor()}} htmlFor="banner" className='flatBtn'>اختيار صورة للشاشات الصغرى</label>
                <input type="file" name="banner" onChange={(e) => setImg(e.target.files[0])} id="banner"/>
                {
                    (img) ? (
                        <button style={{backgroundColor: mainColor()}} className="flatBtn">تحديث</button>
                    ) : null
                }
            </form>
            <img src={(desktopImg) ? URL.createObjectURL(desktopImg) : `${apiEndPoint}/uploads/desktopBG.webp`} alt="" />
            <form action={`${apiEndPoint}/api/admin/changeDesktopHomeCover`} method="post" encType="multipart/form-data">
                <label htmlFor="desktopBanner" style={{backgroundColor: mainColor()}} className='flatBtn'>اختيار صورة للشاشات الكبرى</label>
                <input type="file" name="desktopBanner" onChange={(e) => setDesktopImg(e.target.files[0])} id="desktopBanner"/>
                {
                    (desktopImg) ? (
                        <button style={{backgroundColor: mainColor()}} className="flatBtn">تحديث</button>
                    ) : null
                }
            </form> */}
        </div>
    )
}
import './Home.css';
import searchIcon from '../../assets/searchIcon.svg';
import IconedInputField from '../IconedInputField/IconedInputField';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
import { ActiveTheme } from '../../App';
import whiteIcon from '../../assets/whiteIcon.svg';
import BooksSlider, { LargeBooksSlider, CardsSlider } from '../BooksSlider/BooksSlider';
import MyGlider from '../MyGlider/MyGlider';


export default function Home(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [religious, setReligious] = useState([]);
    const [poetry, setPoetry] = useState([]);
    const [texts, setTexts] = useState([]);
    const [selfDevelopment, setSelfDevelopment] = useState([]);
    const [notes, setNotes] = useState([]);
    const [science, setScience] = useState([]);
    const [psychology, setPsychology] = useState([]);
    const [paperbookBooks, setPaperbookBooks] = useState([]);
    const [manga, setManga] = useState([]);
    const [interactive, setInteractive] = useState([]);
    const [exclusives, setExclusives] = useState([]);
    const [mostPopular, setMostPopular] = useState([]);
    const [original, setOriginal] = useState([]);
    const [preorder, setPreorder] = useState([]);
    const [accessories, setAccessories] = useState([]);
    const [ebooks, setEbooks] = useState([]);
    const [english, setEnglish] = useState([]);
    const [cards, setCards] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [randomBook, setRandomBook] = useState();
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);
    const apiEndPoint = props.apiEndPoint;
    let time;
    let randomBookPosition = Math.floor(Math.random() * 11);
    
    const handleBooks = books => {
        console.log(books);
        if (books.data.length > 0) {
            console.log('handleBooks Working');
            setMostPopular(books.data.filter(item => item.genre !== 'كروت').slice(0, 10));
            console.log(randomBook);
            setReligious([]);
            setPoetry([]);
            setTexts([]);
            setSelfDevelopment([]);
            setScience([]);
            setNotes([]);
            setPsychology([]);
            setManga([]);
            setInteractive([]);
            setPaperbookBooks([]);
            setExclusives([]);
            setOriginal([]);
            setPreorder([]);
            setAccessories([]);
            setEbooks([]);
            setEnglish([]);
            setCards([]);
            for (let i=0;i<books.data.length;i++) {
                let book = books.data[i];
                if (book.genre === 'ديني' || book.secondGenre === 'ديني') {
                    setReligious(religious => [...religious, book]);
                }
                if (book.genre === 'رواية' || book.secondGenre === 'رواية') {
                    setPoetry(poetry => [...poetry, book]);
                }
                if (book.genre === 'نصوص' || book.secondGenre === 'نصوص') {
                    setTexts(texts => [...texts, book]);
                }
                if (book.genre === 'تطوير ذات' || book.secondGenre === 'تطوير ذات') {
                    setSelfDevelopment(selfDevelopment => [...selfDevelopment, book]);
                }
                if (book.genre === 'علوم' || book.secondGenre === 'علوم') {
                    setScience(science => [...science, book]);
                }
                if (book.genre === 'مذكرات' || book.secondGenre === 'مذكرات') {
                    setNotes(notes => [...notes, book]);
                }
                if (book.genre === 'علم النفس' || book.secondGenre === 'علم النفس') {
                    setPsychology(psychology => [...psychology, book]);
                }
                if (book.genre === 'مانجا' || book.secondGenre === 'مانجا') {
                    setManga(manga => [...manga, book]);
                }
                if (book.genre === 'تفاعلي' || book.secondGenre === 'تفاعلي') {
                    setInteractive(interactive => [...interactive, book]);
                }
                if (book.genre === 'بيبربوك' || book.secondGenre === 'بيبربوك') {
                    setPaperbookBooks(paperbookBooks => [...paperbookBooks, book]);
                }
                if (book.genre === 'حصري' || book.secondGenre === 'حصري') {
                    setExclusives(exclusives => [...exclusives, book]);
                }
                if (book.genre === 'كتب اصلية' || book.secondGenre === 'كتب اصلية') {
                    setOriginal(original => [...original, book]);
                }
                if (book.genre === 'طلب مسبق' || book.secondGenre === 'طلب مسبق') {
                    setPreorder(preorder => [...preorder, book]);
                }
                if (book.genre === 'اكسسوارات' || book.secondGenre === 'اكسسوارات') {
                    setAccessories(accessories => [...accessories, book]);
                }
                if (book.genre === 'كتب الكترونية' || book.secondGenre === 'كتب الكترونية') {
                    setEbooks(ebooks => [...ebooks, book]);
                }
                if (book.genre === 'كتب انجليزية' || book.secondGenre === 'كتب انجليزية') {
                    setEnglish(english => [...english, book]);
                }
                if (book.genre === 'كروت' || book.secondGenre === 'كروت') {
                    setCards(cards => [...cards, book]);
                }
            }
        }
    }

    const gettingData = async() => {
        axios.get(`${apiEndPoint}/api/getAllForShop`).then(books => {
            console.log(books);
            localStorage.setItem('shopBooks', JSON.stringify(books));
            handleBooks(books);
        });
        await axios.get(`${apiEndPoint}/api/random10ForHome`).then(result => {
            console.log(result);
            if (result.data.length > 0) {
                localStorage.setItem('shopBooksRandom10', JSON.stringify(result.data[0]));
                setRandomBook(result.data[0]);
            }
        });
    }

    const search = (e) => {
        document.getElementById('loadingResultContainer').style.display = 'block';
        document.getElementById('notAvailiableText').style.display = 'none';
        if (time) {
            clearTimeout(time);
        }
        if (e.target.value !== '') {
            time = setTimeout(() => {
                axios.get(`${apiEndPoint}/api/search/${e.target.value}`).then(response => {
                    document.getElementById('loadingResultContainer').style.display = 'none';
                    console.log(response.data);
                    if (response.data.length === 0) {
                        document.getElementById('notAvailiableText').style.display = 'block';
                        setSearchResult([]);
                    } else {
                        document.getElementById('notAvailiableText').style.display = 'none';
                        setSearchResult(response.data);
                    }
                });
            }, 1000);
        } else {
            setSearchResult([]);
            document.getElementById('loadingResultContainer').style.display = 'none';
        }
    }

    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    useEffect(() => {
        document.title = 'Paperbook';
        if (localStorage.getItem('shopBooks') !== null) {
            console.log(JSON.parse(localStorage.getItem('shopBooks')));
            handleBooks(JSON.parse(localStorage.getItem('shopBooks')));
        }
        console.log(localStorage.getItem('shopBooksRandom10'));
        if (localStorage.getItem('shopBooksRandom10') !== null && localStorage.getItem('shopBooksRandom10') !== undefined) {
            setRandomBook(JSON.parse(localStorage.getItem('shopBooksRandom10')));
        }
        gettingData();
        setIsLoading(false);
        window.scrollTo(0, 0);
    }, []);

    console.log(original);

    return (
        <div className="home" style={{"&::WebkitScrollbarThumb": {backgroundColor: mainColor()}}}>
            <div className="homeHero">
                <img src={`${apiEndPoint}/uploads/${activeTheme}Sticker.png`} alt="" />
            </div>
            <div id="shopSection" className="shopSection">
                <div className="home-shopHeader">
                    <h1 className='homeTitle' style={{borderBottomColor: mainColor()}}>المتجر</h1>
                    <div className="inputBoxContainer shadow">
                        <div className="iconedInputField">
                            <input type="text" className="formField" placeholder="ابحث عن كتبك المفضلة" id="shopSearchBox" onChange={(e) => search(e)}/><img width={25} src={searchIcon} alt=""/>
                        </div>
                        <div className="results" id="loadingResultContainer" style={{display: 'none'}}>
                            <div className="bookData">
                                <div className="cardContainer">
                                    <img style={{height: '130px', width: '95px'}} className="skeleton" alt="" />
                                </div>
                                <div className="cardInfo">
                                    <h3 style={{textAlign: 'right', color: 'transparent', marginBottom: '.4em'}} className="skeleton"> lskkjsdfsdfsdfflksjdf </h3>
                                    <h3 className="subTitle skeleton" style={{textAlign: 'right', color: 'transparent'}}> sdjflksjflks </h3>
                                </div>
                            </div>
                        </div>
                        <p style={{paddingBottom: '1em', display: 'none'}} id="notAvailiableText">😞 متاسفين, الكتاب الي تبحث عليه مش متوفر</p>
                        <div className="results" style={{display: (searchResult.length > 0) ? 'block' : 'none'}}>
                            {
                                (searchResult.length > 0) ? searchResult.map(val => (
                                    <Link to={`book/${val.title}`}>
                                        <div className="bookData">
                                            <div className="cardContainer">
                                                <img style={(val.genre === 'كروت') ? {width: '100px', height: '65px'} : {height: '119px', width: '85px'}} src={val.img} alt="" />
                                            </div>
                                            <div className="cardInfo">
                                                <h3 style={{textAlign: 'right'}}>{val.title}</h3>
                                                <h3 className="subTitle" style={{textAlign: 'right'}}>{val.author}</h3>
                                            </div>
                                        </div>
                                    </Link>
                                )) : null
                            }
                        </div>
                    </div>
                </div>
                {
                    (isLoading) ? (
                        <>
                            <MyGlider name="homeLoading">
                                <li class="glide__slide">
                                    <div className="card">
                                        <div className="imgContainer skeleton"></div>
                                        <div className="titles">
                                            <div className="title skeleton"></div>
                                            <div className="subTitle skeleton"></div>
                                        </div>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div className="card">
                                        <div className="imgContainer skeleton"></div>
                                        <div className="titles">
                                            <div className="title skeleton"></div>
                                            <div className="subTitle skeleton"></div>
                                        </div>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div className="card">
                                        <div className="imgContainer skeleton"></div>
                                        <div className="titles">
                                            <div className="title skeleton"></div>
                                            <div className="subTitle skeleton"></div>
                                        </div>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div className="card">
                                        <div className="imgContainer skeleton"></div>
                                        <div className="titles">
                                            <div className="title skeleton"></div>
                                            <div className="subTitle skeleton"></div>
                                        </div>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div className="card">
                                        <div className="imgContainer skeleton"></div>
                                        <div className="titles">
                                            <div className="title skeleton"></div>
                                            <div className="subTitle skeleton"></div>
                                        </div>
                                    </div>
                                </li>
                            </MyGlider>
                        </>
                    ) : (
                        <>
                            <LargeBooksSlider listOfBooks={mostPopular} gliderName="mostPopular" title="الأكثر رواجاً" />
                            <CardsSlider listOfBooks={cards} gliderName="cards" title="كروت" />
                            <BooksSlider listOfBooks={original} gliderName="original" title="كتب أصلية" />
                            <BooksSlider randomBook={randomBook} showRandomBook={randomBookPosition == 1} listOfBooks={religious} gliderName="religious" title="ديني" />
                            <BooksSlider randomBook={randomBook} showRandomBook={randomBookPosition == 2} listOfBooks={poetry} gliderName="poetry" title="روايات" />
                            <BooksSlider randomBook={randomBook} showRandomBook={randomBookPosition == 3} listOfBooks={texts} gliderName="texts" title="نصوص" />
                            <BooksSlider randomBook={randomBook} showRandomBook={randomBookPosition == 4} listOfBooks={selfDevelopment} gliderName="selfDevelopment" title="تطوير ذات" />
                            <BooksSlider randomBook={randomBook} showRandomBook={randomBookPosition == 5} listOfBooks={science} gliderName="science" title="علوم" />
                            <BooksSlider randomBook={randomBook} showRandomBook={randomBookPosition == 6} listOfBooks={notes} gliderName="notes" title="مذكرات" />
                            <BooksSlider randomBook={randomBook} showRandomBook={randomBookPosition == 7} listOfBooks={psychology} gliderName="psychology" title="علم النفس" />
                            <BooksSlider randomBook={randomBook} showRandomBook={randomBookPosition == 8} listOfBooks={manga} gliderName="manga" title="مانجا" />
                            <BooksSlider randomBook={randomBook} showRandomBook={randomBookPosition == 9} listOfBooks={interactive} gliderName="interactive" title="تفاعلي" />
                            <BooksSlider randomBook={randomBook} showRandomBook={randomBookPosition == 10} listOfBooks={paperbookBooks} gliderName="paperbookBooks" title="بيبربوك" />
                            <BooksSlider randomBook={randomBook} showRandomBook={randomBookPosition == 11} listOfBooks={exclusives} gliderName="exclusives" title="حصريات" />
                            <BooksSlider listOfBooks={preorder} gliderName="preorder" title="طلب مسبق" />
                            <BooksSlider listOfBooks={accessories} gliderName="accessories" title="اكسسوارات" />
                            <BooksSlider listOfBooks={ebooks} gliderName="ebooks" title="كتب الكترونية" />
                            <BooksSlider listOfBooks={english} gliderName="english" title="كتب انجليزية" />
                        </>
                    )
                }
            </div>
        </div>
    )
    // const [homeBooks, setHomeBooks] = useState([]);
    // const [isLoading, setIsLoading] = useState(true);
    // const [searchResult, setSearchResult] = useState([]);
    // let time;

    // const apiEndPoint = props.apiEndPoint;

    // useEffect(() => {
    //     document.title = 'Paperbook';
    //     Axios.get(`${apiEndPoint}/api/getHomeBooks`).then(response => {
    //         setHomeBooks(response.data);
    //         setIsLoading(false);
    //     });
    // }, []);

    // const search = (e) => {
    //     document.getElementById('loadingResultContainer').style.display = 'block';
    //     if (time) {
    //         clearTimeout(time);
    //     }
    //     if (e.target.value !== '') {
    //         time = setTimeout(() => {
    //             Axios.get(`${apiEndPoint}/api/search/${e.target.value}`).then(response => {
    //                 document.getElementById('loadingResultContainer').style.display = 'none';
    //                 setSearchResult(response.data);
    //             });
    //         }, 1000);
    //     } else {
    //         setSearchResult([]);
    //         document.getElementById('loadingResultContainer').style.display = 'none';
    //     }
    // }

    // return (
    //     <div className="home">
    //         <div className="homeHero">
    //             <div className="upperHomeHeroSection">
    //                 <div>
    //                     <img src={whiteIcon} alt="logo" />
    //                     <h2>Paperbook</h2>
    //                     <p>A Brand You Can <b>Trust.</b></p>
    //                 </div>
    //             </div>
    //             <div className="lowerHomeHeroSection">
    //                 {
    //                     (isLoading) ? (
    //                     <MyGlider name="homeLoading">
    //                     <li className="glide__slide">
    //                         <div className="card">
    //                             <div style={{width: 140, height: 180, borderRadius: '.5em'}} className="skeleton"></div>
    //                             <div className="titles">
    //                                 <div className="title skeleton"></div>
    //                                 <div className="subTitle skeleton"></div>
    //                             </div>
    //                         </div>
    //                     </li>
    //                     <li className="glide__slide">
    //                         <div className="card">
    //                             <div style={{width: 140, height: 180, borderRadius: '.5em'}} className="skeleton"></div>
    //                             <div className="titles">
    //                                 <div className="title skeleton"></div>
    //                                 <div className="subTitle skeleton"></div>
    //                             </div>
    //                         </div> 
    //                     </li>
    //                     <li className="glide__slide">
    //                         <div className="card">
    //                             <div style={{width: 140, height: 180, borderRadius: '.5em'}} className="skeleton"></div>
    //                             <div className="titles">
    //                                 <div className="title skeleton"></div>
    //                                 <div className="subTitle skeleton"></div>
    //                             </div>
    //                         </div>
    //                     </li>
    //                     <li className="glide__slide">
    //                         <div className="card">
    //                             <div style={{width: 140, height: 180, borderRadius: '.5em'}} className="skeleton"></div>
    //                             <div className="titles">
    //                                 <div className="title skeleton"></div>
    //                                 <div className="subTitle skeleton"></div>
    //                             </div>
    //                         </div>
    //                     </li>
    //                     <li className="glide__slide">
    //                         <div className="card">
    //                             <div style={{width: 140, height: 180, borderRadius: '.5em'}} className="skeleton"></div>
    //                             <div className="titles">
    //                                 <div className="title skeleton"></div>
    //                                 <div className="subTitle skeleton"></div>
    //                             </div>
    //                         </div>
    //                     </li>
    //                 </MyGlider>
    //                     ) : (
    //                         <MyGlider name="homeGlider">
    //                             {
    //                                 homeBooks.map(val => (
    //                                     <li class="glide__slide" key={val.id}>
    //                                         <Link to={`book${val.id}`}>
    //                                             <Card image={val.img} title={val.title} author={val.author} />
    //                                         </Link>
    //                                     </li>
    //                                 ))
    //                             }
    //                         </MyGlider>
    //                     )
    //                 }
    //             </div>
    //             {/* <div className="homeSearchBoxContainer">
    //                 <div className="inputBoxContainer shadow">
    //                     <div className="iconedInputField">
    //                         <input type="text" className="formField" placeholder="ابحث عن كتبك المفضلة" id="shopSearchBox" onChange={(e) => search(e)}/><img src={searchIcon} alt=""/>
    //                     </div>
    //                     <div className="results" id="loadingResultContainer" style={{display: 'none'}}>
    //                         <div className="bookData">
    //                             <div className="cardContainer">
    //                                 <img style={{height: '130px', width: '95px'}} className="skeleton" alt="" />
    //                             </div>
    //                             <div className="cardInfo">
    //                                 <h3 style={{textAlign: 'right', color: 'transparent', marginBottom: '.4em'}} className="skeleton"> lskkjsdfsdfsdfflksjdf </h3>
    //                                 <h3 className="subTitle skeleton" style={{textAlign: 'right', color: 'transparent'}}> sdjflksjflks </h3>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div className="results" style={{height: '160px', overflowY: 'scroll', display: (searchResult.length > 0) ? 'block' : 'none'}}>
    //                         {
    //                             (searchResult.length > 0) ? searchResult.map(val => (
    //                                 <Link to={`book${val.id}`}>
    //                                     <div className="bookData">
    //                                         <div className="cardContainer">
    //                                             <img src={val.img} alt="" />
    //                                         </div>
    //                                         <div className="cardInfo">
    //                                             <h3 style={{textAlign: 'right'}}>{val.title}</h3>
    //                                             <h3 className="subTitle" style={{textAlign: 'right'}}>{val.author}</h3>
    //                                         </div>
    //                                     </div>
    //                                 </Link>
    //                             )) : null
    //                         }
    //                     </div>
    //                 </div>
    //             </div> */}
    //         </div>
    //         <div className="prosSection">
    //             <div className="pro">
    //                 <img src={pro1} alt=""/>
    //                 <h2>🚐 توصيل سريع</h2>
    //             </div>
    //             <div className="pro">
    //                 <img src={pro3} alt=""/>
    //                 <h2>⚡ رد سريع</h2>
    //             </div>
    //             <div className="pro">
    //                 <img src={pro2} alt=""/>
    //                 <h2>💳 عروض مميزة <br/>للتوب فانز</h2>
    //             </div>
    //         </div>
    //         <h3 style={{backgroundColor: 'var(--main-color)', color: 'white', margin: '0', padding: '16px 0'}}> علامة تجارية تستحق الثقة<br/>A brand you can trust</h3>
    //         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1380 313" className="homeTop"><path fill="#A71D22" fill-opacity="1" d="M0,160L48,181.3C96,203,192,245,288,256C384,267,480,245,576,213.3C672,181,768,139,864,117.3C960,96,1056,96,1152,133.3C1248,171,1344,245,1392,282.7L1440,320L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
    //         <div className="mightLikeSection">                
    //             <Link to="shop">
    //                 <button className="flatBtn">
    //                     المزيد في المتجر
    //                 </button>
    //             </Link>
    //         </div>
    //         <div className="homeBottom">
    //             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1380 313"><path fill="#A71D22" fill-opacity="1" d="M0,160L48,181.3C96,203,192,245,288,256C384,267,480,245,576,213.3C672,181,768,139,864,117.3C960,96,1056,96,1152,133.3C1248,171,1344,245,1392,282.7L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
    //         </div>
    //     </div>
    // )
    
}
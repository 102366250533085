import './Cart.css';
import { useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import homeAddress from '../../assets/homeAddress.svg';
import anotherAddress from '../../assets/anotherAddress.svg';
import IconedInputField from '../IconedInputField/IconedInputField';
import locationIcon from '../../assets/redLocation.svg';
import messageIcon from '../../assets/message.svg';
import { useHistory } from 'react-router-dom';
import cartDoneContainerImg from '../../assets/cartDoneContainer.JPG';
import PastOrders from '../../Components/PastOrders/PastOrders';
import { NumOfCartItems } from '../../App';
import { useContext } from 'react';
import { ActiveTheme } from '../../App';
import { Ring } from '@uiball/loaders';
import PastOrder from '../PastOrder/PastOrder';


export default function Cart(props) {
    const [user, setUser] = useState({});
    const [booksData, setBooksData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [oneTimeAddress, setOneTimeAddress] = useState('');
    const [userMessage, setUserMessage] = useState('');
    const [cart, setCart] = useState({});
    const [deliverOnFriday, setDeliverOnFriday] = useState(false);
    const [useWallet, setUserWallet] = useState(true);
    const [walletBalance, setWalletBalance] = useState(0);
    const [finishingCardSavedInfoHover, setFinishingCardSavedInfoHover] = useState(false);
    const [finishingCardCustomInfoHover, setFinishingCardCustomInfoHover] = useState(false);
    const [isEmptyCartBtnDisabled, setIsEmptyCartBtnDisabled] = useState(false);
    const [isCheckoutBtnDisabled, setIsCheckoutBtnDisabled] = useState(false);
    const [pastOrderOrder, setPastOrderOrder] = useState({});
    const [pastOrderImgsObject, setPastOrderImgsObject] = useState({});
    const { numOfCartItems, setNumOfCartItems } = useContext(NumOfCartItems);
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);
    const history = useHistory();
    let usedWallet = 0;

    const apiEndPoint = props.apiEndPoint;


     const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    const shadowColor = () => {
        if (activeTheme === 'black') {
            return 'rgba(17,17,17, .2) 0 -25px 18px -14px inset,rgba(17,17,17, .15) 0 1px 2px,rgba(17,17,17, .15) 0 2px 4px,rgba(17,17,17, .15) 0 4px 8px,rgba(17,17,17, .15) 0 8px 16px,rgba(17,17,17, .15) 0 16px 32px';
        } else if (activeTheme === 'blue') {
            return 'rgba(151,201,233, .2) 0 -25px 18px -14px inset,rgba(151,201,233, .15) 0 1px 2px,rgba(151,201,233, .15) 0 2px 4px,rgba(151,201,233, .15) 0 4px 8px,rgba(151,201,233, .15) 0 8px 16px,rgba(151,201,233, .15) 0 16px 32px';
        } else if (activeTheme === 'green') {
            return 'rgba(93,187,79, .2) 0 -25px 18px -14px inset,rgba(93,187,79, .15) 0 1px 2px,rgba(93,187,79, .15) 0 2px 4px,rgba(93,187,79, .15) 0 4px 8px,rgba(93,187,79, .15) 0 8px 16px,rgba(93,187,79, .15) 0 16px 32px';
        } else if (activeTheme === 'purple') {
            return 'rgba(130,124,180, .2) 0 -25px 18px -14px inset,rgba(130,124,180, .15) 0 1px 2px,rgba(130,124,180, .15) 0 2px 4px,rgba(130,124,180, .15) 0 4px 8px,rgba(130,124,180, .15) 0 8px 16px,rgba(130,124,180, .15) 0 16px 32px';
        } else if (activeTheme === 'red') {
            return 'rgba(240,91,93, .2) 0 -25px 18px -14px inset,rgba(240,91,93, .15) 0 1px 2px,rgba(240,91,93, .15) 0 2px 4px,rgba(240,91,93, .15) 0 4px 8px,rgba(240,91,93, .15) 0 8px 16px,rgba(240,91,93, .15) 0 16px 32px';
        } else { // yellow
            return 'rgba(253,183,27, .2) 0 -25px 18px -14px inset,rgba(253,183,27, .15) 0 1px 2px,rgba(253,183,27, .15) 0 2px 4px,rgba(253,183,27, .15) 0 4px 8px,rgba(253,183,27, .15) 0 8px 16px,rgba(253,183,27, .15) 0 16px 32px';
        }
    }

    const finishingCardShadowColor = () => {
        if (activeTheme === 'black') {
            return 'rgba(17,17,17, 0.09) -1px -1px, rgba(17,17,17, 0.4) 5px 5px, rgba(17,17,17, 0.3) 10px 10px, rgba(17,17,17, 0.2) 15px 15px, rgba(17,17,17, 0.1) 20px 20px, rgba(17,17,17, 0.05) 25px 25px';
        } else if (activeTheme === 'blue') {
            return 'rgba(151,201,233, 0.09) -1px -1px, rgba(151,201,233, 0.4) 5px 5px, rgba(151,201,233, 0.3) 10px 10px, rgba(151,201,233, 0.2) 15px 15px, rgba(151,201,233, 0.1) 20px 20px, rgba(151,201,233, 0.05) 25px 25px';
        } else if (activeTheme === 'green') {
            return 'rgba(93,187,79, 0.09) -1px -1px, rgba(93,187,79, 0.4) 5px 5px, rgba(93,187,79, 0.3) 10px 10px, rgba(93,187,79, 0.2) 15px 15px, rgba(93,187,79, 0.1) 20px 20px, rgba(93,187,79, 0.05) 25px 25px';
        } else if (activeTheme === 'purple') {
            return 'rgba(130,124,180, 0.09) -1px -1px, rgba(130,124,180, 0.4) 5px 5px, rgba(130,124,180, 0.3) 10px 10px, rgba(130,124,180, 0.2) 15px 15px, rgba(130,124,180, 0.1) 20px 20px, rgba(130,124,180, 0.05) 25px 25px';
        } else if (activeTheme === 'red') {
            return 'rgba(240,91,93, 0.09) -1px -1px, rgba(240,91,93, 0.4) 5px 5px, rgba(240,91,93, 0.3) 10px 10px, rgba(240,91,93, 0.2) 15px 15px, rgba(240,91,93, 0.1) 20px 20px, rgba(240,91,93, 0.05) 25px 25px';
        } else { // yellow
            return 'rgba(253,183,27, 0.09) -1px -1px, rgba(253,183,27, 0.4) 5px 5px, rgba(253,183,27, 0.3) 10px 10px, rgba(253,183,27, 0.2) 15px 15px, rgba(253,183,27, 0.1) 20px 20px, rgba(253,183,27, 0.05) 25px 25px';
        }
    }
    

    const totalPrice = (books) => {
        user.price = 0;
        if (Number(user.cardNumber) === 0) {
            for (let i=0;i<books.length;i++) {
                user.price += (books[i].price - books[i].sale) * cart[books[i].id];
            }
        } else {
            for (let i=0;i<books.length;i++) {
                if (books[i].topFanSale === 1) {
                    user.price += (books[i].price - (5 + books[i].sale)) * cart[books[i].id];
                } else {
                    user.price += (books[i].price - books[i].sale) * cart[books[i].id];
                }
                
            }
        }
        
        // for (let i=0;i<books.length;i++) {
        //     user.price += books[i].price * cart[books[i].id];
        // }

        if (useWallet) {
            usedWallet = (walletBalance - user.price < 0) ? walletBalance : walletBalance - (walletBalance - user.price);
            user.price = user.price - usedWallet;
        }

        

        return user.price;
    }

    const emptyCart = (fromBtn) => {
        if (fromBtn) {
            setIsEmptyCartBtnDisabled(true);
        }
        Axios.post(`${apiEndPoint}/api/updateUserCart`, { cart: JSON.stringify({}), userId: user.id }).then(response => {
            if (response.data) {
                if (fromBtn) {
                    setCart({});
                    setBooksData([]);
                    setNumOfCartItems(0);
                    setIsEmptyCartBtnDisabled(false);
                }
            } 
        });
    }

    const updateUserWallet = () => {
        Axios.get(`${apiEndPoint}/api/updateUserWallet/${walletBalance - usedWallet}/${user.id}`);
    }

    const showWarning = (str) => {
        const warning = document.querySelector('#cartWarning');
        const warningDiv = document.createElement('div');
        warningDiv.innerHTML = str;
        warning.appendChild(warningDiv);
        warning.style.display = 'block';
        warning.style['max-width'] = '415px';
        warning.style['background-color'] = mainColor();
        warning.classList.add('fadein');
        window.scrollTo(0, 0);
        setTimeout(() => {
            warning.classList.remove('fadein');
            warning.classList.add('fadeout');
        }, 10000);
        setTimeout(() => {
            warning.style.display = 'none';
            warningDiv.remove();
        }, 10300);
        warning.classList.remove('fadeout');
    }

    const checkAvailiablity = async (newCart) => {
        let result = true;
        await Axios.post(`${apiEndPoint}/api/getBooksForCart`, {bookIds: Object.keys(cart)}).then(books => {
            let titles = Object.keys(newCart);
            for (let title of titles) {
                let book = books.data.filter(book => book.title === title)[0];
                if (book.quantity - newCart[title].quantity < 0) {
                    document.getElementById('finishingCartRequestContainer').style.display = 'none';
                    document.body.style.overflowY = 'auto';
                    document.querySelector('nav').style.display = 'block';
                    showWarning(`الكمية المطلوبة من الكتاب ${title} غير متوفرة في المخزن`);
                    showWarning(`الكمية المطلوبة: ${newCart[title].quantity} <br /> الكمية المتوفرة: ${book.quantity}`);
                    
                    
                    result = false;
                } else if (newCart[title].quantity > 4) {
                    document.getElementById('finishingCartRequestContainer').style.display = 'none';
                    document.body.style.overflowY = 'auto';
                    document.querySelector('nav').style.display = 'block';
                    showWarning(`لقد تخطيت الكمية المسموح بها من الكتاب ${title} <br /> الكمية المسموح بها: 4`);
                    
                    result = false;
                }
            }
            
        });
        return result;
    }

    const checkOut = () => {
        setIsCheckoutBtnDisabled(true);
        let newCart = {};
        for (let i=0;i<booksData.length;i++) {
            // cart[booksData[i].title] = cart[booksData[i].id];
            newCart[booksData[i].title] = {
                quantity: cart[booksData[i].id],
                singleSellProfit: booksData[i].singleSellProfit,
                singleSellSale: (booksData[i].topFanSale == 1 && user.cardNumber !== 0) ? booksData[i].sale + 5 : booksData[i].sale,
                singleBookPrice: booksData[i].price,
            }
        }
        checkAvailiablity(newCart)
        .then(result => {
            if (result) {
                Axios.post(`${apiEndPoint}/api/sendToDriver`, { username: user.name, phoneNumber: user.phoneNumber, price: user.price, address: (oneTimeAddress === '') ? user.address : oneTimeAddress,  cartObj: newCart, userMessage: userMessage, manual: false, deliverOnFriday: deliverOnFriday, walletBalanceUsed: (useWallet) ? usedWallet : 0, isTopFan: Number(user.cardNumber) === 0 }).then(response => {
                    if (response.data) {
                        
                        emptyCart(false);
                        setIsCheckoutBtnDisabled(false);
                        setNumOfCartItems(0);
                        setPastOrderOrder({'cart': newCart});
                        generateImgsObjectForPastOrder();
                        const doneContainer = document.getElementById('finishingRequestContainer');
                        const moreInfoDiv = document.getElementById('moreInfoForCartRequest');
                        moreInfoDiv.classList.add('fadeGlassOut');
                        setTimeout(() => {
                            // emptyCart(false);
                            moreInfoDiv.style.display = 'none';
                            moreInfoDiv.classList.remove('fadeGlassOut');
                            doneContainer.classList.add('fadeGlassIn');
                            doneContainer.style.display = 'grid';
                            // setTimeout(() => {
                            doneContainer.classList.remove('fadeGlassIn');
                            scrollUpAfterKeyboard();
                            //     document.body.style.overflowY = 'scroll';
                            //     document.querySelector('nav').style.display = 'block';
                            //     setIsCheckoutBtnDisabled(false);
                            //     setNumOfCartItems(0);
                            //     history.push("/");
                            // }, 10000);
                        }, 400);
                        updateUserWallet();
                    } else {
                        
                    }
                });
            } else {
                setIsCheckoutBtnDisabled(false);
            }
        })
        .catch((error) => {
            setIsCheckoutBtnDisabled(false);
            console.log(error);
            
        });
    }

    

    const askForInfo = () => {
        const requestContainer = document.getElementById('finishingCartRequestContainer');
        const finishingDiv = document.getElementById('finishingDiv');
        document.querySelector('nav').style.display = 'none';
        requestContainer.style.display = 'flex';
        requestContainer.classList.add('fadeGlassIn');
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';
        setTimeout(() => {
            requestContainer.classList.remove('fadeGlassIn');
            finishingDiv.style.display = 'block';
            finishingDiv.classList.add('fadeGlassIn');
        setTimeout(() => {
                finishingDiv.classList.remove('fadeGlassIn');
            }, 400)
        }, 400);
    }


    const editBookQuantity = (change, id) => {
        let newCart = cart;
        let biggestPossible = booksData.filter(book => book.id === id)[0].quantity;
        
        if (change === '-') {
            if (newCart[id] > 1) {
                //Decrease
                newCart[id] = newCart[id] - 1;
                Axios.post(`${apiEndPoint}/api/updateUserCart`, { cart: JSON.stringify(newCart), userId: user.id }).then((response) => {
                    if (response.data) {
                        setCart({...newCart});
                    }
                });
            } else {
                newCart[id] = Math.min(biggestPossible, 4);
                Axios.post(`${apiEndPoint}/api/updateUserCart`, { cart: JSON.stringify(newCart), userId: user.id }).then((response) => {
                    if (response.data) {
                        setCart({...newCart});
                    }
                });
            }
        } else {
            if (newCart[id] < Math.min(biggestPossible, 4)) {
                //Increase
                newCart[id] = newCart[id] + 1;
                Axios.post(`${apiEndPoint}/api/updateUserCart`, { cart: JSON.stringify(newCart), userId: user.id }).then((response) => {
                    if (response.data) {
                        setCart({...newCart});
                    }
                });
            } else {
                newCart[id] = 1;
                Axios.post(`${apiEndPoint}/api/updateUserCart`, { cart: JSON.stringify(newCart), userId: user.id }).then((response) => {
                    if (response.data) {
                        setCart({...newCart});
                    }
                });
            }
        }
    }


    const removeCartItem = (id, elementId) => {
        const btn = document.getElementById(elementId);
        btn.style.color = '#757575';
        let newCart = cart;
        delete newCart[id];
        Axios.post(`${apiEndPoint}/api/updateUserCart`, { cart: JSON.stringify(newCart), userId: user.id }).then((response) => {
            if (response.data) {
                for (let i=0;i<booksData.length;i++) {
                    if (booksData[i].id === id) {
                        let newBooksData = [...booksData];
                        newBooksData.splice(i, 1);
                        setBooksData(newBooksData);
                    }
                }
                setCart(newCart);
                setNumOfCartItems(numOfCartItems => numOfCartItems - 1);
            }
        });
    }

    const showInfoContainer = () => {
        const finishingDiv = document.getElementById('finishingDiv');
        const moreInfoDiv = document.getElementById('moreInfoForCartRequest');
        finishingDiv.classList.add('fadeGlassOut');
        setTimeout(() => {
            finishingDiv.style.display = 'none';
            finishingDiv.classList.remove('fadeGlassOut');
            moreInfoDiv.classList.add('fadeGlassIn');
            moreInfoDiv.style.display = 'flex';
            setTimeout(() => {
                moreInfoDiv.classList.remove('fadeGlassIn');
            }, 400);
        }, 400);
    }


    const bookPrice = (topFanSale, price, sale) => {
        // {(val.topFanSale === 1 && Number(user.cardNumber) !== 0) ? val.price - 5 : val.price}  
        if (topFanSale === 1 && Number(user.cardNumber) !== 0) {
            return price - (5 + sale);
        } else {
            return price - sale;
        }
    }

    useEffect(() => {
        document.title = `Paperbook - Cart`;
        Axios.get(`${apiEndPoint}/api/login`).then(response => {
            if (response.data.loggedin == false) {
                window.location.replace('/login');
            }
            Axios.get(`${apiEndPoint}/api/getWalletBalance/${response.data.user.id}`).then(result => {
                setWalletBalance(result.data[0].wallet);
            });
            Axios.get(`${apiEndPoint}/api/getUserCart/${response.data.user.id}`).then(result => {
                setCart(result.data[0].cart);
                Axios.post(`${apiEndPoint}/api/getBooksForCart`, {bookIds: Object.keys(result.data[0].cart)}).then(books => {
                    setBooksData(books.data);
                });
            });
            setUser(response.data.user);
            setIsLoading(false);
        });
    }, []);

    const generateImgsObjectForPastOrder = () => {
        let imgsObject = {};
        for (let i=0;i<booksData.length;i++) {
            imgsObject[booksData[i].title] = booksData[i].img;
        }
        
        setPastOrderImgsObject({...imgsObject});
    }

    const scrollUpAfterKeyboard = () => {
        window.scrollTo(0, 0);
    }


    


    if (isLoading) {
        return (
            <div className="cardsContainer">
                <div className="cartCard">
                        <div className="cartCardRightSide">
                        <h3 className="skeleton" style={{color: 'transparent', margin: '.4em'}}>slkdfjsdkjfsdj</h3>
                        <h4 className="skeleton" style={{color: 'transparent', margin: '.4em'}}>slkdfjsdkjfsdj</h4>
                        <div className="counter"><button className="skeleton" style={{color: 'transparent', margin: '.4em'}}>ls</button><button className="skeleton" style={{color: 'transparent', margin: '.4em'}}>ls</button></div><br />
                        <p className="removeFromCart skeleton" style={{color: 'transparent', margin: '.8em .4em .4em .4em'}}>lskjflskdjflksd</p>
                    </div>
                    <div className="lgRightSide">
                    <div className="counter"><button className="skeleton" style={{color: 'transparent', margin: '.4em'}}>ls</button><button className="skeleton" style={{color: 'transparent', margin: '.4em'}}>ls</button></div><br />
                        <div>
                            <h3 className="skeleton" style={{color: 'transparent', margin: '.4em'}}>slkdfjsdkjfsdj</h3>
                            <h4 className="skeleton" style={{color: 'transparent', margin: '.4em'}}>slkdfjsdkjfsdj</h4>
                            <p className="removeFromCart skeleton" style={{color: 'transparent', margin: '.4em'}}>lskjflskdjflksd</p>
                        </div>
                    </div>
                    <img className="skeleton" alt="" />
                </div>
            </div>
        );
    }

    if (booksData.length === 0) {
        return (
            <>
                <div className="emptyCart">
                    <i className="fab fa-opencart"></i>
                    <p>العربة فاضية</p>
                </div>
                <PastOrders apiEndPoint={apiEndPoint} username={user.name} />
            </>
        )
    }


    return (
        <div className="cart">
            <div className="warning" id="cartWarning"></div>
            <div className="finishingDivContainer" id="finishingCartRequestContainer">
                <div className="finishingDiv" id="finishingDiv">
                    <h2 style={{textAlign: 'center', margin: 0}}>أكد معلوماتك</h2>
                    <div className="finishingCardsContainer">
                        <div className="finishingCard shadow" onMouseOver={() => setFinishingCardSavedInfoHover(true)} onMouseLeave={() => setFinishingCardSavedInfoHover(false)} style={{boxShadow: finishingCardSavedInfoHover ? finishingCardShadowColor() : null}} onClick={showInfoContainer}>
                            <img src={homeAddress} alt="" />
                            <h4>نفس عنواني للي مسجل عندكم</h4>
                            <p style={{color: '#716C6C'}}>{user.address}</p>
                        </div>
                        <div className="finishingCard shadow" onMouseOver={() => setFinishingCardCustomInfoHover(true)} onMouseLeave={() => setFinishingCardCustomInfoHover(false)} style={{boxShadow: finishingCardCustomInfoHover ? finishingCardShadowColor() : null}}>
                            <img src={anotherAddress} alt="" />
                            <h4>المرة هذه بنغير عنواني</h4>
                            <IconedInputField onBlur={scrollUpAfterKeyboard} placeholder="العنوان" type="text" image={locationIcon} onChange={(e) => setOneTimeAddress(e.target.value)}/>
                            <button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="btn" onClick={showInfoContainer}>تاكيد</button>
                        </div>
                    </div>
                </div>
                <div className="infoContainer" id="moreInfoForCartRequest">
                    <div>
                        <h2 style={{textAlign: 'right', direction: 'rtl'}}>تقدر تخلي ملاحظات زي مثلا: موعد استلامك للكتب, او مثلا لو تبي كل تغليفة باسم بروحه وهكذا...</h2>
                        <div className="iconedInputField shadow">
                            <img src={messageIcon} alt=""/><textarea className="contactMessageBox" placeholder="اي ملاحظة خليها اهني, ولو معندك شي تقدر تخليها فاضية" onChange={(e) => setUserMessage(e.target.value)}/>
                        </div>
                    </div>
                    <button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="flatBtn" onClick={checkOut} disabled={isCheckoutBtnDisabled}>{isCheckoutBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color="white" /> : 'تأكيد'}</button>
                </div>
                <div className="finishingRequestContainer" id="finishingRequestContainer">
                    <PastOrder order={pastOrderOrder} isDone={false} isCart={true} isCanceled={false} imgsObject={pastOrderImgsObject}  />
                </div>
            </div>
            <div className="cardsContainer">
                {
                    booksData.map((val,index) => (
                        <div key={index} className="cartCard" id={val.id + val.title}>
                            <div className="cartCardRightSide">
                                <h3>{val.title}</h3>
                                <h4 style={{direction: 'rtl'}}>{bookPrice(val.topFanSale, val.price, val.sale)} د.ل</h4>
                                <div className="counter"><button onClick={() => editBookQuantity('-', val.id)}>-</button><span>{cart[val.id]}</span><button onClick={() => editBookQuantity('+', val.id)}>+</button></div><br />
                                <p id="smRemoveFromCartBtn" className="removeFromCart" onClick={() => removeCartItem(val.id, "smRemoveFromCartBtn")}><i className="fas fa-cart-arrow-down"></i> حذف</p>
                            </div>
                            <div className="lgRightSide">
                                <div className="counter"><button onClick={() => editBookQuantity('-', val.id)}>-</button><span>{cart[val.id]}</span><button onClick={() => editBookQuantity('+', val.id)}>+</button></div>
                                <div>
                                    <h3>{val.title}</h3>
                                    <h4 style={{direction: 'rtl'}}>{bookPrice(val.topFanSale, val.price, val.sale)} د.ل</h4>
                                    <p id="lgRemoveFromCartBtn" className="removeFromCart" onClick={() => removeCartItem(val.id, "lgRemoveFromCartBtn")}><i className="fas fa-cart-arrow-down"></i> حذف</p>
                                </div>
                            </div>
                            <img style={(val.genre === 'كروت') ? {width: '100px', height: '65px'} : null} src={val.img} alt="" />
                        </div>
                    ))
                }
                <div className="bottomCartSection">
                    <div>
                        <h4 style={{marginBottom: '0', direction: 'rtl'}} className="totalPrice">سعر الكتب: {totalPrice(booksData)} د.ل</h4>
                        {/* <input checked={deliverOnFriday} type="checkbox" style={{marginBottom: '1em'}} onChange={e => setDeliverOnFriday(e.target.checked)}/>
                        <label style={{marginBottom: '1em'}}>توصيل يوم الجمعة (مجاناً داخل طرابلس وضواحيها)</label> */}
                        {
                            (walletBalance > 0) ? (
                                <>
                                    <br />
                                    <input checked={useWallet} type="checkbox" style={{marginBottom: '1em'}} onChange={e => setUserWallet(e.target.checked)}/>
                                    <label style={{marginBottom: '1em'}}>استخدام رصيد المحفظة ({walletBalance} د.ل)</label>
                                </>
                            ) : null
                        }
                    </div>
                    
                    <div className="cartActionButtons">
                        <button className="flatBtn" onClick={askForInfo} style={{ backgroundColor: mainColor() }}>تأكيد</button>
                        <button className="flatBtn-clicked" disabled={isEmptyCartBtnDisabled} onClick={() => emptyCart(true)} style={{ borderColor: mainColor(), color: mainColor() }}>{isEmptyCartBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color={mainColor()} /> : 'إلغاء'}</button>
                    </div>
                </div>
                <p className="bottomText">الاسعار لا تشمل التوصيل لمعرفة سعر التوصيل <Link to="deliveryPrices">اضغط هنا</Link></p>
                <PastOrders apiEndPoint={apiEndPoint} username={user.name} />
            </div>
        </div>
    );
}
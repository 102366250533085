import "./Driver.css";
import { useEffect, useState, useContext } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import DeliveryJob from "../DeliveryJob/DeliveryJob";
import { ActiveTheme } from '../../App';

export default function Driver(props) {
    const [availableDeliveries, setAvailableDeliveries] = useState([]);
    const [driverDeliveries, setDriverDeliveries] = useState([]);
    const [allDeliveries, setAllDeliveries] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isDriver, setIsDriver] = useState(false);
    const [driverName, setDriverName] = useState('');
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);
    const history = useHistory();

      const apiEndPoint = props.apiEndPoint;


  const mainColor = () => {
    if (activeTheme === 'black') {
        return 'rgb(17,17,17)';
    } else if (activeTheme === 'blue') {
        return 'rgb(151,201,233)';
    } else if (activeTheme === 'green') {
        return 'rgb(93,187,79)';
    } else if (activeTheme === 'purple') {
        return 'rgb(130,124,180)';
    } else if (activeTheme === 'red') {
        return 'rgb(240,91,93)';
    } else { // yellow
        return 'rgb(253,183,27)';
    }
  }

  useEffect(() => {
    document.title = "Paperbook - Driver Panel";
    Axios.get(`${apiEndPoint}/api/login`).then((response) => {
      if (
        response.data.loggedin === false ||
        response.data.user.isDriver === 0
      ) {
        history.replace("/");
      }

      if (response.data.user.isAdmin) {
        setIsAdmin(true);
      } else if (response.data.user.isDriver) {
        setIsDriver(true);
        setDriverName(response.data.user.name);
        Axios.get(`${apiEndPoint}/api/driverHasJobs/${response.data.user.name}`).then(result => {
          setDriverDeliveries(result.data);
        });
      }
    });
    Axios.get(`${apiEndPoint}/api/getDeliveries`).then((response) => {
      setAvailableDeliveries(response.data);
    });
    Axios.get(`${apiEndPoint}/api/admin/getAllDeliveries`).then((response) => {
      setAllDeliveries(response.data);
    });
  }, []);

  const moreInfoOnDelivery = (id) => {
    if (isAdmin) {
      history.push(`/driver/delivery`, {id: id, driverName: '', isAdmin: true});
    } else if (isDriver) {
      history.push(`/driver/delivery`, {id: id, driverName: driverName, isAdmin: false});
    }
  }

  const addDelivery = () => {
    history.push('/driver/addDelivery');
  }

  if (isAdmin) {
    return (
      <>
        <h1>الطلبيات</h1>
        <button style={{backgroundColor: mainColor()}} className="flatBtn" onClick={addDelivery}>اضافة طلبية</button>
        <table>
          <tr>
            <th style={{backgroundColor: mainColor()}}>نوع الطلبية</th>
            <th style={{backgroundColor: mainColor()}}>السائق</th>
            <th style={{backgroundColor: mainColor()}}>العنوان</th>
            <th style={{backgroundColor: mainColor()}}>الاسم</th>
            <th style={{backgroundColor: mainColor()}}>#</th>
          </tr>
          {
            allDeliveries.map(val => (
                  <tr onClick={() => moreInfoOnDelivery(val.id)} style={{cursor: 'pointer'}}>
                    <td>{(val.deliverOnFriday === 1) ? 'توصيل مجاني' : 'توصيل مدفوع'}</td>
                    <td>{(val.driverName) ? val.driverName : 'شاغرة'}</td>
                    <td>{val.address}</td>
                    <td>{val.username}</td>
                    <td>{val.id}</td>
                  </tr>
                )
            )
          }
        </table>
      </>
    )
  }

  return (
    <>

      <h1>طلبياتك</h1>
      {
        (driverDeliveries.length > 0) ? (
          <table>
            <tr>
              <th style={{ backgroundColor: mainColor() }}>نوع الطلبية</th>
              <th style={{ backgroundColor: mainColor() }}>العنوان</th>
              <th style={{ backgroundColor: mainColor() }}>الاسم</th>
              <th style={{ backgroundColor: mainColor() }}>#</th>
            </tr>
            {
              driverDeliveries.map(val => (
                    <tr onClick={() => moreInfoOnDelivery(val.id)} style={{cursor: 'pointer'}}>
                      <td>{(val.deliverOnFriday === 1) ? 'توصيل مجاني' : 'توصيل مدفوع'}</td>
                      <td>{val.address}</td>
                      <td>{val.username}</td>
                      <td>{val.id}</td>
                    </tr>
                  )
              )
            }
          </table>
        ) : <p>ليس لديك طلبيات</p>
      }

      <h1>الطلبيات المتاحة</h1>
      {
        (availableDeliveries.length > 0) ? (
          <table>
            <tr>
              <th style={{ backgroundColor: mainColor() }}>نوع الطلبية</th>
              <th style={{ backgroundColor: mainColor() }}>العنوان</th>
              <th style={{ backgroundColor: mainColor() }}>الاسم</th>
              <th style={{ backgroundColor: mainColor() }}>#</th>
            </tr>
            {
              availableDeliveries.map(val => (
                    <tr onClick={() => moreInfoOnDelivery(val.id)} style={{cursor: 'pointer'}}>
                      <td>{(val.deliverOnFriday === 1) ? 'توصيل مجاني' : 'توصيل مدفوع'}</td>
                      <td>{val.address}</td>
                      <td>{val.username}</td>
                      <td>{val.id}</td>
                    </tr>
                  )
              )
            }
          </table>
        ) : <p>لا يوجد طلبيات</p>
      }
    </>
  )
}

import './DeliveryJob.css';
import { useLocation, useHistory } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { ActiveTheme } from '../../App';
import { Ring } from '@uiball/loaders';

export default function DeliveryJob(props) {
    const apiEndPoint = props.apiEndPoint;
    const location = useLocation();
    const history = useHistory();
    const { id, driverName, isAdmin } = location.state;
    const [deliveryInfo, setDeliveryInfo] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isCancelOrderBtnClicked, setIsCancelOrderBtnClicked] = useState(false);
    const [isConfirmOrderBtnClicked, setIsConfirmOrderBtnClicked] = useState(false);
    const [isDropOrderBtnClicked, setIsDropOrderBtnClicked] = useState(false);
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);

    
    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    useEffect(() => {
        axios.get(`${apiEndPoint}/api/login`).then((response) => {
            if (response.data.loggedin === false || response.data.user.isDriver === 0) {
                history.replace("/");
            }
        });
        axios.get(`${apiEndPoint}/api/driver/getDeliveryInfo/${id}`).then(response => {
            response.data[0].cart = response.data[0].cart;
            setDeliveryInfo(response.data[0]);
            setIsLoading(false);
        });
    }, []);

    const showItems = (cartObj) => {
        const arr = Object.keys(cartObj);
        return (
        <td>
            {arr.map((key) => (
            <p>
                <b>{key}: </b> {cartObj[key].quantity}
            </p>
            ))}
        </td>
        );
    };

    const doneBtn = (btnId) => {
        setIsConfirmOrderBtnClicked(true);
        let titles = Object.keys(deliveryInfo.cart);
        console.log(titles);
        let totalProfit = 0;
        for (let i=0;i<titles.length;i++) {
            totalProfit += deliveryInfo.cart[titles[i]].quantity * (deliveryInfo.cart[titles[i]].singleSellProfit - deliveryInfo.cart[titles[i]].singleSellSale);
        }
        axios.get(`${apiEndPoint}/api/driver/done/${id}/${totalProfit}`).then(
            (response) => {
                setIsConfirmOrderBtnClicked(false);
                if (response.data) {
                    history.replace('/driver');
                }
            }
        );
    };

    const showCancelConfirmation = () => {
        const deliveryActionBtns = document.querySelector('.deliveryActionBtns');
        const cancelConfirmBtns = document.querySelector('.cancelConfirmBtns');
        deliveryActionBtns.style.display = 'none';
        cancelConfirmBtns.style.display = 'block';
    }

    const hideCancelConfirmation = () => {
        const deliveryActionBtns = document.querySelector('.deliveryActionBtns');
        const cancelConfirmBtns = document.querySelector('.cancelConfirmBtns');
        cancelConfirmBtns.style.display = 'none';
        deliveryActionBtns.style.display = 'block';
    }

    const showLeaveConfirmation = () => {
        const deliveryActionBtns = document.querySelector('.deliveryActionBtns');
        const leaveConfirmBtns = document.querySelector('.leaveConfirmBtns');
        deliveryActionBtns.style.display = 'none';
        leaveConfirmBtns.style.display = 'block';
    }

    const hideLeaveConfirmation = () => {
        const deliveryActionBtns = document.querySelector('.deliveryActionBtns');
        const leaveConfirmBtns = document.querySelector('.leaveConfirmBtns');
        leaveConfirmBtns.style.display = 'none';
        deliveryActionBtns.style.display = 'block';
    }

    const dropTheJob = () => {
        setIsDropOrderBtnClicked(true);
        axios.get(`${apiEndPoint}/api/driver/makeJobAvailable/${id}`).then(response => {
            setIsDropOrderBtnClicked(false);
            if (response.data) {
                history.replace('/driver');
            }
        });
    }


    const cancelBtn = (btnId) => {
        setIsCancelOrderBtnClicked(true);
        axios.post(`${apiEndPoint}/api/driver/cancel`, {
            id: id,
            cartObj: JSON.stringify(deliveryInfo.cart),
            walletBalanceUsed: deliveryInfo.walletBalanceUsed,
        }).then((response) => {
            setIsCancelOrderBtnClicked(false);
            if (response.data) {
                history.replace('/driver');
            }
        });
    };

    const demandJob = () => {
        const demandBtn = document.getElementById('demandBtn');
        demandBtn.classList.remove('flatBtn');
        demandBtn.classList.add('flatBtn-clicked');
        axios.get(`${apiEndPoint}/api/driver/demandJob/${id}/${driverName}`).then(response => {
        if (response.data) {
            setIsLoading(true);
            let newDeliveryInfo = deliveryInfo;
            newDeliveryInfo.driverName = driverName;
            setDeliveryInfo(newDeliveryInfo);    
            window.scrollTo(0, 0);
            setIsLoading(false);
        } else {
            alert('تم طلب الطلبية بالفعل');
            history.replace('/driver');
        }
        });
    }

    console.log(deliveryInfo.driverName);
    console.log(driverName);

    const actionBtns = () => {
        if (isAdmin) {
            return (
                <>
                    <div className="deliveryActionBtns">
                        <button disabled={isConfirmOrderBtnClicked} style={{backgroundColor: mainColor()}} id="adminConfirmDeliveryBtn" className="flatBtn" onClick={() => doneBtn('adminConfirmDeliveryBtn')}>
                            {
                                isConfirmOrderBtnClicked ? (
                                    <Ring size={20} lineWeight={5} speed={2} color="white" />
                                ) : (
                                    <span>تم التوصيل</span>
                                )
                            }
                        </button>
                        <button disabled={isConfirmOrderBtnClicked} style={{borderColor: mainColor(), color: mainColor()}} className="flatBtn-clicked" onClick={showCancelConfirmation}>الغاء الطلبية</button>
                    </div>
                    <div className="cancelConfirmBtns">
                        <p>الغاء الطلبية؟</p>
                        <button disabled={isCancelOrderBtnClicked} style={{backgroundColor: mainColor()}} id="adminCancelDeliveryBtn" className="flatBtn" onClick={() => cancelBtn('adminCancelDeliveryBtn')}>
                            {
                                isCancelOrderBtnClicked ? (
                                    <Ring size={20} lineWeight={5} speed={2} color="white" />
                                ) : (
                                    <span>تاكيد</span>
                                )
                            }
                        </button>
                        <button disabled={isCancelOrderBtnClicked} style={{borderColor: mainColor(), color: mainColor()}} className="flatBtn-clicked" onClick={hideCancelConfirmation}>تراجع</button>
                    </div>
                </>
            )
        } else if (deliveryInfo.driverName && deliveryInfo.driverName == driverName) {
            return (
                <>
                    <div className="deliveryActionBtns">
                        <button disabled={isConfirmOrderBtnClicked} style={{backgroundColor: mainColor()}} id="driverConfirmDeliveryBtn" className="flatBtn" onClick={() => doneBtn('driverConfirmDeliveryBtn')}>
                            {
                                isConfirmOrderBtnClicked ? (
                                    <Ring size={20} lineWeight={5} speed={2} color="white" />
                                ) : (
                                    <span>تم التوصيل</span>
                                )
                            }
                        </button>
                        <button disabled={isConfirmOrderBtnClicked} style={{borderColor: mainColor(), color: mainColor()}} className="flatBtn-clicked" onClick={showCancelConfirmation}>الغاء الطلبية</button>
                        <button disabled={isConfirmOrderBtnClicked} style={{borderColor: mainColor(), color: mainColor()}} className="flatBtn-clicked" onClick={showLeaveConfirmation}>ترك الطلبية</button>
                    </div>
                    <div className="cancelConfirmBtns">
                        <p>الغاء الطلبية؟</p>
                        <button disabled={isCancelOrderBtnClicked} style={{backgroundColor: mainColor()}} id="driverCancelDeliveryBtn" className="flatBtn" onClick={() => cancelBtn('driverCancelDeliveryBtn')}>
                            {
                                isCancelOrderBtnClicked ? (
                                    <Ring size={20} lineWeight={5} speed={2} color="white" />
                                ) : (
                                    <span>تاكيد</span>
                                )
                            }
                        </button>
                        <button disabled={isCancelOrderBtnClicked} style={{borderColor: mainColor(), color: mainColor()}} className="flatBtn-clicked" onClick={hideCancelConfirmation}>تراجع</button>
                    </div>
                    <div className="leaveConfirmBtns">
                        <p>ترك الطلبية؟</p>
                        <button disabled={isDropOrderBtnClicked} style={{backgroundColor: mainColor()}} id="dropJobBtn" className="flatBtn" onClick={dropTheJob}>
                            {
                                isDropOrderBtnClicked ? (
                                    <Ring size={20} lineWeight={5} speed={2} color="white" />
                                ) : (
                                    <span>تاكيد</span>
                                )
                            }
                        </button>
                        <button disabled={isDropOrderBtnClicked} style={{borderColor: mainColor(), color: mainColor()}} className="flatBtn-clicked" onClick={hideLeaveConfirmation}>تراجع</button>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div className="deliveryActionBtns">
                        <button style={{backgroundColor: mainColor()}} className="flatBtn" id="demandBtn" onClick={demandJob}>طلب</button>
                    </div>
                </>
            )
        }
    }

    console.log(deliveryInfo);

    if (isLoading) {
        return (
            <h1>Loading</h1>
        )
    }

    return (
        <div className='deliveryJob'>
            <h1 style={{direction: 'rtl'}}>طلبية {deliveryInfo.id}#</h1>
            <table className="detailedInfo">
                <tr>
                    <td>
                        <p>{deliveryInfo.username}</p>
                    </td>
                    <td>
                        <b>:الاسم</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>{deliveryInfo.address}</p>
                    </td>
                    <td>
                        <b>:العنوان</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>{deliveryInfo.phoneNumber}</p>
                    </td>
                    <td>
                        <b>:رقم الهاتف</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p style={{direction: 'rtl'}}>{deliveryInfo.price} د.ل</p>
                    </td>
                    <td>
                        <b>:السعر (بدون التوصيل)</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p style={{direction: 'rtl'}}>{deliveryInfo.walletBalanceUsed} د.ل</p>
                    </td>
                    <td>
                        <b>:المدفوع من المحفظة</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>{(deliveryInfo.deliverOnFriday === 1) ? 'توصيل مجاني' : 'توصيل مدفوع'}</p>
                    </td>
                    <td>
                        <b>:نوع الطلبية</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>{showItems(deliveryInfo.cart)}</p>
                    </td>
                    <td>
                        <b>:الطلبيات</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>{(deliveryInfo.userMessage.length > 0) ? deliveryInfo.userMessage : 'لايوجد ملاحظات'}</p>
                    </td>
                    <td>
                        <b>:رسالة المشتري</b>
                    </td>
                </tr>
                {
                    isAdmin ? (
                        <tr>
                            <td>
                                <p>{(deliveryInfo.driverName) ? deliveryInfo.driverName : 'غير محدد'}</p>
                            </td>
                            <td>
                                <b>:السائق</b>
                            </td>
                        </tr>
                    ) : null
                }
            </table>
            {actionBtns()}
        </div>
    )
}
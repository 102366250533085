import { useEffect, useState, useContext } from 'react';
import './UsersMenu.css';
import Axios from 'axios';
import searchIcon from '../../assets/searchIcon.svg';
import { useHistory } from 'react-router';
import { ActiveTheme } from '../../App';
import { Ring } from '@uiball/loaders';

export default function UsersMenu(props) {
    const history = useHistory();
    const [users, setUsers] = useState([]);
    const [totalUsers, setTotalUsers] = useState([]);
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);
    let time;

    const apiEndPoint = props.apiEndPoint;

    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    const shadowColor = () => {
        if (activeTheme === 'black') {
            return 'rgba(17,17,17, .2) 0 -25px 18px -14px inset,rgba(17,17,17, .15) 0 1px 2px,rgba(17,17,17, .15) 0 2px 4px,rgba(17,17,17, .15) 0 4px 8px,rgba(17,17,17, .15) 0 8px 16px,rgba(17,17,17, .15) 0 16px 32px';
        } else if (activeTheme === 'blue') {
            return 'rgba(151,201,233, .2) 0 -25px 18px -14px inset,rgba(151,201,233, .15) 0 1px 2px,rgba(151,201,233, .15) 0 2px 4px,rgba(151,201,233, .15) 0 4px 8px,rgba(151,201,233, .15) 0 8px 16px,rgba(151,201,233, .15) 0 16px 32px';
        } else if (activeTheme === 'green') {
            return 'rgba(93,187,79, .2) 0 -25px 18px -14px inset,rgba(93,187,79, .15) 0 1px 2px,rgba(93,187,79, .15) 0 2px 4px,rgba(93,187,79, .15) 0 4px 8px,rgba(93,187,79, .15) 0 8px 16px,rgba(93,187,79, .15) 0 16px 32px';
        } else if (activeTheme === 'purple') {
            return 'rgba(130,124,180, .2) 0 -25px 18px -14px inset,rgba(130,124,180, .15) 0 1px 2px,rgba(130,124,180, .15) 0 2px 4px,rgba(130,124,180, .15) 0 4px 8px,rgba(130,124,180, .15) 0 8px 16px,rgba(130,124,180, .15) 0 16px 32px';
        } else if (activeTheme === 'red') {
            return 'rgba(240,91,93, .2) 0 -25px 18px -14px inset,rgba(240,91,93, .15) 0 1px 2px,rgba(240,91,93, .15) 0 2px 4px,rgba(240,91,93, .15) 0 4px 8px,rgba(240,91,93, .15) 0 8px 16px,rgba(240,91,93, .15) 0 16px 32px';
        } else { // yellow
            return 'rgba(253,183,27, .2) 0 -25px 18px -14px inset,rgba(253,183,27, .15) 0 1px 2px,rgba(253,183,27, .15) 0 2px 4px,rgba(253,183,27, .15) 0 4px 8px,rgba(253,183,27, .15) 0 8px 16px,rgba(253,183,27, .15) 0 16px 32px';
        }
    }

    useEffect(() => {
        Axios.get(`${apiEndPoint}/api/login`).then(response => {
            if (response.data.loggedin === false || response.data.user.isAdmin === 0) {
                history.replace("/");
            }
        });
        Axios.get(`${apiEndPoint}/api/getUsers`).then(response => {
            setUsers(response.data);
            setTotalUsers(response.data);
        });
        document.title = 'Paperbook - Users Manager';
    }, []);

    const deleteUser = (id) => {
        setIsBtnDisabled(true);
        Axios.get(`${apiEndPoint}/api/deleteUser/${id}`).then(response => {
            if (response.data) {
                setIsBtnDisabled(false);
                setUsers(users => users.filter(user => user.id !== id));
            } else {
                setIsBtnDisabled(false);
            }
        });
    }

    const showConfirmationBtns = (id, big) => {
        if (big) {
            document.getElementById(`bigConfirmationBtns${id}`).style.display = 'block';
            document.getElementById(`bigDeleteBtn${id}`).style.display = 'none';
        } else {
            document.getElementById(`confirmationBtns${id}`).style.display = 'block';
            document.getElementById(`deleteBtn${id}`).style.display = 'none';
        }
    }

    const showAskForConf = (id, big) => {
        if (big) {
            document.getElementById(`bigConfirmationBtns${id}`).style.display = 'none';
            document.getElementById(`bigDeleteBtn${id}`).style.display = 'block';
        } else {
            document.getElementById(`confirmationBtns${id}`).style.display = 'none';
            document.getElementById(`deleteBtn${id}`).style.display = 'block';
        }
    }

    const showFullAddress = (id, address) => {
        document.getElementById(id).innerText = 'العنوان: ' + address;
    }

    const search = (term) => {
        if (time) {
            clearTimeout(time);
        }
        time = setTimeout(() => {
            if (term !== '') {
                Axios.get(`${apiEndPoint}/api/usersSearch/${term}`).then(response => {
                    setUsers(response.data);
                });
            } else {
                setUsers(totalUsers);
            }
        }, 1000);
    }

    return (
        <div className="usersMenu">
            <div className="inputBoxContainer shadow">
                    <div className="iconedInputField">
                        <input type="text" className="formField" placeholder="ابحث باستخدام الاسم, او رقم الهاتف" id="shopSearchBox" onChange={(e) => search(e.target.value)}/><img src={searchIcon} alt=""/>
                    </div>
                </div>
            <table>
                <tr>
                    <th style={{backgroundColor: mainColor()}}>اجراءات</th>
                    <th style={{backgroundColor: mainColor()}}>رصيد المحفظة</th>
                    <th style={{backgroundColor: mainColor()}}>العنوان</th>
                    <th style={{backgroundColor: mainColor()}}>رقم الهاتف</th>
                    <th style={{backgroundColor: mainColor()}}>الاسم</th>
                </tr>
                {
                    users.map(val => {
                        return (
                            <tr>
                                <td>
                                    <button style={{backgroundColor: mainColor()}} className="flatBtn" id={'bigDeleteBtn' + val.id} onClick={() => showConfirmationBtns(val.id, true)}>حذف المستخدم</button>
                                    <div id={'bigConfirmationBtns' + val.id} className="confirmationBtns">
                                        <button style={{backgroundColor: mainColor()}} disabled={isBtnDisabled} className="flatBtn" onClick={() => deleteUser(val.id)}>{isBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color="white" /> : 'تأكيد'}</button>
                                        <button style={{color: mainColor(), borderColor: mainColor()}} className="flatBtn-clicked" onClick={() => showAskForConf(val.id, true)}>الغاء</button>
                                    </div>
                                </td>
                                <td>{val.wallet}</td>
                                <td>{(val.address.length > 20) ? val.address.substring(0, 20) + '...' : val.address}</td>
                                <td>{val.phoneNumber}</td>
                                <td>{val.name}</td>
                            </tr>
                        )
                    })
                }
            </table>
            <div className="smallSize">
                {
                    users.map(val => {
                        return (
                            <div className="mdsm" onClick={() => showFullAddress(val.id, val.address)}>
                                
                                <p>
                                    <button style={{backgroundColor: mainColor()}} className="flatBtn" id={'deleteBtn' + val.id} onClick={() => showConfirmationBtns(val.id, false)}>حذف المستخدم</button>
                                    <div id={'confirmationBtns' + val.id} className="confirmationBtns">
                                        <button style={{backgroundColor: mainColor()}} disabled={isBtnDisabled} className="flatBtn" onClick={() => deleteUser(val.id)}>{isBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color="white" /> : 'تأكيد'}</button>
                                        <button style={{color: mainColor(), borderColor: mainColor()}} className="flatBtn-clicked" onClick={() => showAskForConf(val.id, false)}>الغاء</button>
                                    </div>
                                </p>
                                <div>
                                    <p>الاسم: {val.name}</p>
                                    <p>رقم الهاتف: {val.phoneNumber}</p>
                                    <p id={val.id}>العنوان: {(val.address.length > 20) ? val.address.substring(0, 20) + '...' : val.address}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
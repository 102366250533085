import IconedInputField from '../IconedInputField/IconedInputField';
import './Login.css';
import phone from '../../assets/redPhone.svg';
import shownPassword from '../../assets/shownPassword.svg';
import hiddenPassword from '../../assets/hiddenPassword.svg';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { useEffect, useContext, useState } from 'react';
import { ActiveTheme } from '../../App';
import { useHistory, useLocation } from 'react-router-dom';
import { Ring } from '@uiball/loaders';

export default function Login(props) {
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const history = useHistory();
    const apiEndPoint = props.apiEndPoint;
    const location = useLocation();
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);

    Axios.defaults.withCredentials = true;

    useEffect(() => {
        Axios.get(`${apiEndPoint}/api/login`).then((response) => {
            if (response.data.loggedin === true) {
                history.replace("/");
            }
        });
        document.title = 'Paperbook - Login';
        window.scrollTo(0, 0);
    }, []);

    const showWarning = (str) => {
        const warning = document.querySelector('#loginWarning');
        const warningDiv = document.createElement('div');
        warningDiv.innerText = str;
        warning.appendChild(warningDiv);
        warning.style.display = 'block';
        warning.style['max-width'] = '415px';
        warning.style['background-color'] = mainColor();
        warning.classList.add('fadein');
        window.scrollTo(0, 0);
        setTimeout(() => {
            warning.classList.remove('fadein');
            warning.classList.add('fadeout');
        }, 10000);
        setTimeout(() => {
            warning.style.display = 'none';
            warningDiv.remove();
        }, 10300);
        warning.classList.remove('fadeout');
    }

    const checking = async(data) => {
        if (data['phone'].length > 15 || data['phone'].length < 4) {
            showWarning('الرجاء ادخال رقم هاتفك');
            return false;
        } 
        if (data['password'].length < 8) {
            showWarning('الرقم السري لا يمكن ان يقل عن 8 خانات و يمكن أن يكون مزيج من اي شيء');
            return false;
        }
        return true;
    }

    const login = () => {
        setIsBtnDisabled(true);
        const data = {};
        const formFields = document.querySelectorAll('.formField');
        for (let i=0;i<formFields.length;i++) {
            data[formFields[i].name] = formFields[i].value;
        }
        checking(data).then(response => {
            if (response) {
                Axios.post(`${apiEndPoint}/api/login`, {
                    phone: data['phone'],
                    password: data['password'],
                }).then(result => {
                    if (result.data.message) {
                        showWarning(result.data.message);
                        setIsBtnDisabled(false);
                    } else {
                        // history.goBack();
                        history.go(0);
                    }
                });
            } else {
                setIsBtnDisabled(false);
            }
        });
    }

    const passwordVisibility = () => {
        const passIcon = document.getElementById('passwordVisIcon');
        const passwordInput = document.getElementById('passwordInput');
        if (passwordInput.type === 'password') {
            passwordInput.type = 'text';
            passIcon.src = shownPassword;
        } else {
            passwordInput.type = 'password';
            passIcon.src = hiddenPassword;
        }
    }

    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    const shadowColor = () => {
        if (activeTheme === 'black') {
            return 'rgba(17,17,17, .2) 0 -25px 18px -14px inset,rgba(17,17,17, .15) 0 1px 2px,rgba(17,17,17, .15) 0 2px 4px,rgba(17,17,17, .15) 0 4px 8px,rgba(17,17,17, .15) 0 8px 16px,rgba(17,17,17, .15) 0 16px 32px';
        } else if (activeTheme === 'blue') {
            return 'rgba(151,201,233, .2) 0 -25px 18px -14px inset,rgba(151,201,233, .15) 0 1px 2px,rgba(151,201,233, .15) 0 2px 4px,rgba(151,201,233, .15) 0 4px 8px,rgba(151,201,233, .15) 0 8px 16px,rgba(151,201,233, .15) 0 16px 32px';
        } else if (activeTheme === 'green') {
            return 'rgba(93,187,79, .2) 0 -25px 18px -14px inset,rgba(93,187,79, .15) 0 1px 2px,rgba(93,187,79, .15) 0 2px 4px,rgba(93,187,79, .15) 0 4px 8px,rgba(93,187,79, .15) 0 8px 16px,rgba(93,187,79, .15) 0 16px 32px';
        } else if (activeTheme === 'purple') {
            return 'rgba(130,124,180, .2) 0 -25px 18px -14px inset,rgba(130,124,180, .15) 0 1px 2px,rgba(130,124,180, .15) 0 2px 4px,rgba(130,124,180, .15) 0 4px 8px,rgba(130,124,180, .15) 0 8px 16px,rgba(130,124,180, .15) 0 16px 32px';
        } else if (activeTheme === 'red') {
            return 'rgba(240,91,93, .2) 0 -25px 18px -14px inset,rgba(240,91,93, .15) 0 1px 2px,rgba(240,91,93, .15) 0 2px 4px,rgba(240,91,93, .15) 0 4px 8px,rgba(240,91,93, .15) 0 8px 16px,rgba(240,91,93, .15) 0 16px 32px';
        } else { // yellow
            return 'rgba(253,183,27, .2) 0 -25px 18px -14px inset,rgba(253,183,27, .15) 0 1px 2px,rgba(253,183,27, .15) 0 2px 4px,rgba(253,183,27, .15) 0 4px 8px,rgba(253,183,27, .15) 0 8px 16px,rgba(253,183,27, .15) 0 16px 32px';
        }
    }

    return (
        <div className="login">
            <div className='login-header'>
                <h2>أهلا بعودتك 👋</h2>
                <p>قم بالتسجيل الي حسابك</p>
            </div>
            <div className="warning" id="loginWarning" style={{textAlign: 'center'}}></div>
            <div className="formBox">
                <div className="question">
                    <label>رقم الهاتف:</label>
                    <IconedInputField image={phone} name="phone" type="text"/>
                </div>
                <div className="question">
                    <label>كلمة المرور:</label>
                    <IconedInputField image={hiddenPassword} imageId="passwordVisIcon" inputId="passwordInput" name="password" type="password" onClickOnIcon={passwordVisibility}/>
                </div>
                <div className="login-btn">
                    <p>لست مسجلً؟ <Link to="signup">سجل</Link></p>
                    <button className="btn" onClick={login} style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} disabled={isBtnDisabled} type="submit">{isBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color="white" /> : 'تسجيل الدخول'}</button>
                </div>
            </div>
        </div>
    )
}
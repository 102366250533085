import { useEffect, useState, useContext } from 'react';
import './AdminDriversManager.css';
import axios from 'axios';
import { useHistory } from 'react-router';
import driverIcon from '../../assets/driverIcon.svg';
import IconedInputField from '../IconedInputField/IconedInputField';
import name from '../../assets/name.svg';
import redPhone from '../../assets/redPhone.svg';
import shownPassword from '../../assets/shownPassword.svg';
import hiddenPassword from '../../assets/hiddenPassword.svg';
import Axios from 'axios';
import removeIcon from '../../assets/removeIcon.svg';
import { ActiveTheme } from '../../App';
import { Ring } from '@uiball/loaders';

export default function AdminDriversManager(props) {
    const history = useHistory();
    const [drivers, setDrivers] = useState([]);
    const [isAddDriverBtnDisabled, setIsAddDriverBtnDisabled] = useState(false);
    const [isDeleteDriverBtnDisabled, setIsDeleteDriverBtnDisabled] = useState(false);
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);
    const [isLoading, setIsLoading] = useState(true);

    const apiEndPoint = props.apiEndPoint;

    useEffect(() => {
        axios.get(`${apiEndPoint}/api/login`).then(response => {
            if (response.data.loggedin === false || response.data.user.isAdmin === 0) {
                history.replace("/");
            }
        });
        axios.get(`${apiEndPoint}/api/admin/getListOfDrivers`).then((response) => {
            setDrivers(response.data);
        });
        document.title = 'Paperbook - Admin Panel';        
        setIsLoading(false);
    }, []);

    const showDriversAdder = () => {
        const driversManager = document.getElementById('driversManager');
        const driversAdder = document.getElementById('driversAdder');
        driversManager.style.display = 'none';
        driversAdder.style.display = 'block';
    }

    const passwordVisibility = () => {
        const passIcon = document.getElementById('passwordVisIcon');
        const passwordInput = document.getElementById('passwordInput');
        if (passwordInput.type === 'password') {
            passwordInput.type = 'text';
            passIcon.src = shownPassword;
        } else {
            passwordInput.type = 'password';
            passIcon.src = hiddenPassword;
        }
    }

    const showWarning = (str) => {
        const warning = document.querySelector('#driversManagerWarning');
        const warningDiv = document.createElement('div');
        warningDiv.innerText = str;
        warning.appendChild(warningDiv);
        warning.style.display = 'block';
        warning.style['max-width'] = '415px';
        warning.style['background-color'] = mainColor();
        warning.classList.add('fadein');
        window.scrollTo(0, 0);
        setTimeout(() => {
            warning.classList.remove('fadein');
            warning.classList.add('fadeout');
        }, 10000);
        setTimeout(() => {
            warning.style.display = 'none';
            warningDiv.remove();
        }, 10300);
        warning.classList.remove('fadeout');
    }

    const checking = async (data) => {
        let result = true;
        await Axios.get(`${apiEndPoint}/api/isPhoneNumberUsed/${data['phoneNumber']}`).then(response => {
            if (response.data) {
                showWarning('رقم الهاتف مستخدم بالفعل');
                result = false;
            }
        });
        await Axios.get(`${apiEndPoint}/api/isNameUsed/${data['name'].trim()}`).then(response => {
            if (response.data) {
                showWarning('هناك مستخدم بهذا الاسم بالفعل');
                result = false;
            }
        });
        if (data['name'].trim().length < 3 || !isNaN(data['name'])) {
            showWarning('الاسم يجب ان لا يقل عن حرفين بدون ارقام');
            result = false;
        }
        if (data['password'].length < 8) {
            showWarning('الرقم السري لا يمكن ان يقل عن 8 خانات و يمكن أن يكون مزيج من اي شيء');
            result = false;
        } 
        if (isNaN(data['phoneNumber']) || data['phoneNumber'].length > 15) {
            showWarning('الرجاء ادخال رقم الهاتف بشكل صحيح (بدون اشارة + )');
            result = false;
        }
        return result;
    }

    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    const shadowColor = () => {
        if (activeTheme === 'black') {
            return 'rgba(17,17,17, .2) 0 -25px 18px -14px inset,rgba(17,17,17, .15) 0 1px 2px,rgba(17,17,17, .15) 0 2px 4px,rgba(17,17,17, .15) 0 4px 8px,rgba(17,17,17, .15) 0 8px 16px,rgba(17,17,17, .15) 0 16px 32px';
        } else if (activeTheme === 'blue') {
            return 'rgba(151,201,233, .2) 0 -25px 18px -14px inset,rgba(151,201,233, .15) 0 1px 2px,rgba(151,201,233, .15) 0 2px 4px,rgba(151,201,233, .15) 0 4px 8px,rgba(151,201,233, .15) 0 8px 16px,rgba(151,201,233, .15) 0 16px 32px';
        } else if (activeTheme === 'green') {
            return 'rgba(93,187,79, .2) 0 -25px 18px -14px inset,rgba(93,187,79, .15) 0 1px 2px,rgba(93,187,79, .15) 0 2px 4px,rgba(93,187,79, .15) 0 4px 8px,rgba(93,187,79, .15) 0 8px 16px,rgba(93,187,79, .15) 0 16px 32px';
        } else if (activeTheme === 'purple') {
            return 'rgba(130,124,180, .2) 0 -25px 18px -14px inset,rgba(130,124,180, .15) 0 1px 2px,rgba(130,124,180, .15) 0 2px 4px,rgba(130,124,180, .15) 0 4px 8px,rgba(130,124,180, .15) 0 8px 16px,rgba(130,124,180, .15) 0 16px 32px';
        } else if (activeTheme === 'red') {
            return 'rgba(240,91,93, .2) 0 -25px 18px -14px inset,rgba(240,91,93, .15) 0 1px 2px,rgba(240,91,93, .15) 0 2px 4px,rgba(240,91,93, .15) 0 4px 8px,rgba(240,91,93, .15) 0 8px 16px,rgba(240,91,93, .15) 0 16px 32px';
        } else { // yellow
            return 'rgba(253,183,27, .2) 0 -25px 18px -14px inset,rgba(253,183,27, .15) 0 1px 2px,rgba(253,183,27, .15) 0 2px 4px,rgba(253,183,27, .15) 0 4px 8px,rgba(253,183,27, .15) 0 8px 16px,rgba(253,183,27, .15) 0 16px 32px';
        }
    }

    const signup = () => {
        const data = {};
        const formFields = document.querySelectorAll('.formField');
        for (let i=0;i<formFields.length;i++) {
            data[formFields[i].name] = formFields[i].value;
        }
        checking(data).then(response => {
            if (response) {
                setIsAddDriverBtnDisabled(true);
                Axios.post(`${apiEndPoint}/api/registerDriver`, {
                    name: data['name'].trim(),
                    password: data['password'],
                    phoneNumber: data['phoneNumber'],
                }).then(result => {
                    if (result.data) {
                        axios.get(`${apiEndPoint}/api/admin/getListOfDrivers`).then((response) => {
                            setDrivers(response.data);
                        });
                        setIsAddDriverBtnDisabled(false);
                        const driversManager = document.getElementById('driversManager');
                        const driversAdder = document.getElementById('driversAdder');
                        driversManager.style.display = 'block';
                        driversAdder.style.display = 'none';
                    } else {
                        showWarning('حدثت مشكلة, حاول مجدداً');
                        setIsAddDriverBtnDisabled(false);
                    }
                });
            }
        })
    }

    const showDeleteDialog = (id) => {
        const deleteDialog = document.getElementById(`deleteDialogNum${id}`);
        const deleteBtn = document.getElementById(`deleteIconNum${id}`);
        deleteBtn.style.display = 'none';
        deleteDialog.style.display = 'flex';
    }

    const deleteDriver = (id) => {
        setIsDeleteDriverBtnDisabled(true);
        Axios.get(`${apiEndPoint}/api/deleteUser/${id}`).then(response => {
            if (response.data) {
                setDrivers(drivers => drivers.filter(driver => driver.id !== id));
                setIsDeleteDriverBtnDisabled(false);
            }
        });
    }

    const hideDeleteDialog = (id) => {
        const deleteDialog = document.getElementById(`deleteDialogNum${id}`);
        const deleteBtn = document.getElementById(`deleteIconNum${id}`);
        deleteBtn.style.display = 'block';
        deleteDialog.style.display = 'none';
    }

    if (isLoading) {
        return <h1>Loading</h1>
    }


    return (
        <div className='adminDriversManager'>
            <div className="warning" id="driversManagerWarning"></div>
            <div className="driversManager" id="driversManager">
                <h1>السائقين</h1>
                <button style={{ backgroundColor: mainColor() }} onClick={showDriversAdder} className="flatBtn">اضافة سائق</button>
                {(drivers.length <= 0) ? <p>لا يوجد سائقين مسجلين</p> : drivers.map(val => (
                    <div className="driverCardContainer">
                        <img src={removeIcon} alt="" style={{cursor: 'pointer'}} id={`deleteIconNum${val.id}`} onClick={() => showDeleteDialog(val.id)} />
                        <div className="deleteDialog" id={`deleteDialogNum${val.id}`}>
                            <button style={{backgroundColor: mainColor()}} disabled={isDeleteDriverBtnDisabled} className='flatBtn' onClick={() => deleteDriver(val.id)}>{isDeleteDriverBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color="white" /> : 'تأكيد'}</button>
                            <button style={{borderColor: mainColor(), color: mainColor()}} className='flatBtn-clicked' onClick={() => hideDeleteDialog(val.id)}>الغاء</button>
                        </div>
                        <div className='driverCard'>
                            <div>
                                <b>{val.name}</b>
                                <p>{val.phoneNumber}</p>
                            </div>
                            <img src={driverIcon} alt="" />
                        </div>
                    </div>
                ))}
            </div>
            <div className="driversAdder" id="driversAdder">
                <h1>اضف سائق</h1>
                <div className="warning" id="driversManagerWarning"></div>
                <div className="question">
                    <label>الاسم:</label>
                    <IconedInputField image={name} name="name" type="text" />
                </div>
                <div className="question">
                    <label>رقم الهاتف:</label>
                    <IconedInputField image={redPhone} name="phoneNumber" type="text" />
                </div>
                <div className="question">
                    <label>كلمة السر:</label>
                    <IconedInputField image={hiddenPassword} imageId="passwordVisIcon" inputId="passwordInput" name="password" type="password" onClickOnIcon={passwordVisibility}/>
                </div>
                <button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="btn" onClick={signup} disabled={isAddDriverBtnDisabled}>{isAddDriverBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color="white" /> : 'أضف'}</button>
            </div>
        </div>
    )
}
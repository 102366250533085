import './Navbar.css';
import mainIcon from '../../assets/mainIcon.png';
import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import name from '../../assets/name.svg';
import phone from '../../assets/redPhone.svg';
import instagramIcon from '../../assets/instagram.svg';
import messageIcon from '../../assets/message.svg';
import topFan from '../../assets/topFan.svg';
import { useHistory } from 'react-router-dom';
import IconedInputField from '../IconedInputField/IconedInputField';
import { Howl, Howler } from 'howler';
import walletAudio from '../../assets/walletAudio.mp3';
import storeLogo from '../../assets/store-solid.svg';
import { ActiveTheme, NumOfCartItems } from '../../App';
import cartLogo from '../../assets/cartLogo.svg';
import blueMainIcon from '../../assets/blue.png';
import blackMainIcon from '../../assets/B.png';
import greenMainIcon from '../../assets/G.png';
import purpleMainIcon from '../../assets/P.png';
import redMainIcon from '../../assets/R.png';
import yellowMainIcon from '../../assets/Y.png';
import { Ring } from '@uiball/loaders';

export default function Navbar(props) {
    const [isMenuShown, setIsMenuShown] = useState(false);
    const [userObject, setUserObject] = useState(null);
    const [userMessageForNav, setUserMessageForNav] = useState('');
    const [topFanCode, setTopFanCode] = useState('');
    const [instagramId, setInstagramId] = useState('');
    const [numOfNotifications, setNumOfNotifications] = useState(0);
    const { numOfCartItems, setNumOfCartItems } = useContext(NumOfCartItems);
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [isRequestBookBtnDisabled, setIsRequestBookBtnDisabled] = useState(false);
    const [isAddTopFanCardBtnDisabled, setIsAddTopFanCardBtnDisabled] = useState(false);
    const [isRequestTopFanCardBtnDisabled, setIsRequestTopFanCardBtnDisabled] = useState(false);
    const history = useHistory();
    const apiEndPoint = props.apiEndPoint;

    const sound = new Howl({
        src: [walletAudio],
    });

    const mainIcon = () => {
        if (activeTheme === 'black') {
            return blackMainIcon;
        } else if (activeTheme === 'blue') {
            return blueMainIcon;
        } else if (activeTheme === 'green') {
            return greenMainIcon;
        } else if (activeTheme === 'purple') {
            return purpleMainIcon;
        } else if (activeTheme === 'red') {
            return redMainIcon;
        } else { // yellow
            return yellowMainIcon;
        }
    }

    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    const shadowColor = () => {
        if (activeTheme === 'black') {
            return 'rgba(17,17,17, .2) 0 -25px 18px -14px inset,rgba(17,17,17, .15) 0 1px 2px,rgba(17,17,17, .15) 0 2px 4px,rgba(17,17,17, .15) 0 4px 8px,rgba(17,17,17, .15) 0 8px 16px,rgba(17,17,17, .15) 0 16px 32px';
        } else if (activeTheme === 'blue') {
            return 'rgba(151,201,233, .2) 0 -25px 18px -14px inset,rgba(151,201,233, .15) 0 1px 2px,rgba(151,201,233, .15) 0 2px 4px,rgba(151,201,233, .15) 0 4px 8px,rgba(151,201,233, .15) 0 8px 16px,rgba(151,201,233, .15) 0 16px 32px';
        } else if (activeTheme === 'green') {
            return 'rgba(93,187,79, .2) 0 -25px 18px -14px inset,rgba(93,187,79, .15) 0 1px 2px,rgba(93,187,79, .15) 0 2px 4px,rgba(93,187,79, .15) 0 4px 8px,rgba(93,187,79, .15) 0 8px 16px,rgba(93,187,79, .15) 0 16px 32px';
        } else if (activeTheme === 'purple') {
            return 'rgba(130,124,180, .2) 0 -25px 18px -14px inset,rgba(130,124,180, .15) 0 1px 2px,rgba(130,124,180, .15) 0 2px 4px,rgba(130,124,180, .15) 0 4px 8px,rgba(130,124,180, .15) 0 8px 16px,rgba(130,124,180, .15) 0 16px 32px';
        } else if (activeTheme === 'red') {
            return 'rgba(240,91,93, .2) 0 -25px 18px -14px inset,rgba(240,91,93, .15) 0 1px 2px,rgba(240,91,93, .15) 0 2px 4px,rgba(240,91,93, .15) 0 4px 8px,rgba(240,91,93, .15) 0 8px 16px,rgba(240,91,93, .15) 0 16px 32px';
        } else { // yellow
            return 'rgba(253,183,27, .2) 0 -25px 18px -14px inset,rgba(253,183,27, .15) 0 1px 2px,rgba(253,183,27, .15) 0 2px 4px,rgba(253,183,27, .15) 0 4px 8px,rgba(253,183,27, .15) 0 8px 16px,rgba(253,183,27, .15) 0 16px 32px';
        }
    }

    const showRequestBookPopup = () => {
        const requestBookContainer = document.getElementById('requestBookContainer');
        const infoContainer = document.getElementById('requestBookInfoContainer');
        requestBookContainer.classList.add('fadeGlassIn');
        requestBookContainer.style.display = 'flex';
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';
        setTimeout(() => {
            requestBookContainer.classList.remove('fadeGlassIn');
            infoContainer.classList.add('fadeGlassIn');
            infoContainer.style.display = 'flex';
            setTimeout(() => {
                infoContainer.classList.remove('fadeGlassIn');
            }, 400);
        }, 400);
        document.querySelector('.navContainer').style.display = 'none';
        setIsMenuShown(false);
        document.querySelector('.mobileMenu').style.display = 'none';
        document.querySelector('.hamButton').innerHTML = '&#9776;'
    }

    const closePopup = () => {
        const requestBookContainer = document.getElementById('requestBookContainer');
        const infoContainer = document.getElementById('requestBookInfoContainer');
        infoContainer.classList.add('fadeGlassOut');
        setTimeout(() => {
            infoContainer.style.display = 'none';
            infoContainer.classList.remove('fadeGlassOut');
            requestBookContainer.classList.add('fadeGlassOut');
            setTimeout(() => {
                requestBookContainer.style.display = 'none';
                requestBookContainer.classList.remove('fadeGlassOut');
                document.querySelector('.navContainer').style.display = 'flex';
                document.body.style.overflowY = 'scroll';
            }, 400);
        }, 400);
    }


    const requestBook = () => {
        if (userMessageForNav.trim() !== '') { 
            setIsRequestBookBtnDisabled(true);
            console.log(userObject);
            Axios.get(`${apiEndPoint}/api/addNotification/${userMessageForNav}/${userObject.name}/${userObject.phoneNumber}/RequestingBook`).then(response => {
                if (response.data) {
                    setIsRequestBookBtnDisabled(false);
                    const infoContainer = document.getElementById('requestBookInfoContainer');
                    const doneContainer = document.getElementById('doneContainer');
                    const requestBookContainer = document.getElementById('requestBookContainer');
                    infoContainer.classList.add('fadeGlassOut');
                    setTimeout(() => {
                        infoContainer.style.display = 'none';
                        infoContainer.classList.remove('fadeGlassOut');
                        doneContainer.style.display = 'flex';
                        doneContainer.classList.add('fadeGlassIn');
                        setTimeout(() => {
                            doneContainer.classList.remove('fadeGlassIn');
                            setTimeout(() => {
                                doneContainer.classList.add('fadeGlassOut');
                                setTimeout(() => {
                                    doneContainer.style.display = 'none';
                                    doneContainer.classList.remove('fadeGlassOut');
                                    requestBookContainer.classList.add('fadeGlassOut');
                                    setTimeout(() => {
                                        requestBookContainer.style.display = 'none';
                                        requestBookContainer.classList.remove('fadeGlassOut');
                                        document.querySelector('.navContainer').style.display = 'flex';
                                        document.body.style.overflowY = 'scroll';
                                    }, 400);
                                }, 400);
                            }, 800);
                        }, 400);
                    }, 400);
                }
            });
        }
    }

    Axios.defaults.withCredentials = true;

    useEffect(() => {
        Axios.get(`${apiEndPoint}/api/login`).then((response) => {
            if (response.data.loggedin) {
                setUserObject(response.data.user);
                Axios.get(`${apiEndPoint}/api/getUserCart/${response.data.user.id}`).then(result => {
                    let cart = result.data[0].cart;
                    setNumOfCartItems(Object.keys(cart).length);
                });
                Axios.get(`${apiEndPoint}/api/NumOfNotifications`).then(result => {
                    let totalNumOfNotifications = result.data[0]['COUNT(*)'];
                    let listOfSeenNotifications = response.data.user.seenNotifications;
                    if (listOfSeenNotifications['seenNotifications']) {
                        if (totalNumOfNotifications - listOfSeenNotifications['seenNotifications'].length < 1) {
                            document.querySelector('#notificationBtn__badge').style.display = 'none';
                            document.querySelector('#notificationBtn__badge-mobile').style.display = 'none';
                        } else {
                            setNumOfNotifications(totalNumOfNotifications - listOfSeenNotifications['seenNotifications'].length);
                        }
                    } else {
                        setNumOfNotifications(totalNumOfNotifications);
                    }
                    
                });
            }
        });
    }, []);

    const logout = () => {
        Axios.get(`${apiEndPoint}/api/logout`).then(response => {
            if (response.data) {
                history.push("/login");
                setUserObject(null);
            }
        });
    }

    const changeHamMenuState = () => {
        const hamMenu = document.querySelector('.mobileMenu');
        const hamBtn = document.querySelector('.hamButton');
        if (isMenuShown) {
            setIsMenuShown(false);
            hamMenu.style.display = 'none';
            hamBtn.innerHTML = '&#9776;'
        } else {
            setIsMenuShown(true);
            hamMenu.style.display = 'block';
            hamBtn.innerHTML = '<i className="fas fa-times">';
        }
    }

    const addTopFanCode = () => {
        setIsAddTopFanCardBtnDisabled(true);
        Axios.get(`${apiEndPoint}/api/checkTopFanCode/${topFanCode}`).then(result => {
            if (result.data.length === 0) {
                setIsAddTopFanCardBtnDisabled(false);
                showWarning('رقم التوب فان غير صحيح');
            } else if (result.data[0].userId !== null) {
                setIsAddTopFanCardBtnDisabled(false);
                showWarning('رقم التوب فان مستخدم بالفعل');
            } else {
                Axios.get(`${apiEndPoint}/api/updateTopFanCodeOwner/${topFanCode}/${userObject.id}`).then(response => {
                    if (response.data) {
                        setIsAddTopFanCardBtnDisabled(false);
                        sound.play();
                        const doneContainer = document.getElementById('doneContainer');
                        const glassyContainer = document.getElementById('requestBookContainer');
                        const topfanContainer = document.getElementById('addTopFanCardContainer');
                        topfanContainer.classList.add('fadeGlassOut');
                        setTimeout(() => {
                            topfanContainer.style.display = 'none';
                            topfanContainer.classList.remove('fadeGlassOut');
                            doneContainer.classList.add('fadeGlassIn');
                            doneContainer.style.display = 'flex';
                            setTimeout(() => {
                                doneContainer.classList.remove('fadeGlassIn');
                                doneContainer.classList.add('fadeGlassOut');
                                setTimeout(() => {
                                    doneContainer.style.display = 'none';
                                    doneContainer.classList.remove('fadeGlassOut');
                                    glassyContainer.classList.add('fadeGlassOut');
                                    setTimeout(() => {
                                        glassyContainer.style.display = 'none';
                                        glassyContainer.classList.remove('fadeGlassOut');
                                        document.body.style.overflowY = 'scroll';
                                        document.querySelector('.navContainer').style.display = 'flex';
                                        setUserObject(userObject => userObject['cardNumber'] = topFanCode);
                                    });
                                }, 400);
                            }, 2000);
                        }, 400);
                    }
                });
            }
        });
    }
    
    const showWarning = (str) => {
        const warning = document.querySelector('#topFanWarning');
        const warningDiv = document.createElement('div');
        warningDiv.innerText = str;
        warning.appendChild(warningDiv);
        warning.style.display = 'block';
        warning.style['max-width'] = '415px';
        warning.style['background-color'] = mainColor();
        warning.classList.add('fadein');
        window.scrollTo(0, 0);
        setTimeout(() => {
            warning.classList.remove('fadein');
            warning.classList.add('fadeout');
        }, 5000);
        setTimeout(() => {
            warning.style.display = 'none';
            warningDiv.remove();
        }, 5300);
        warning.classList.remove('fadeout');
    }
    
    const showAddTopFanCardContainer = () => {
        const topfanContainer = document.getElementById('addTopFanCardContainer');
        const glassyContainer = document.getElementById('requestBookContainer');
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';
        glassyContainer.classList.add('fadeGlassIn');
        glassyContainer.style.display = 'flex';
        setTimeout(() => {
            glassyContainer.classList.remove('fadeGlassIn');
            topfanContainer.classList.add('fadeGlassIn');
            topfanContainer.style.display = 'flex';
            setTimeout(() => {
                topfanContainer.classList.remove('fadeGlassIn');
            }, 400);
        }, 400);
        document.querySelector('.navContainer').style.display = 'none';
        setIsMenuShown(false);
        document.querySelector('.mobileMenu').style.display = 'none';
        document.querySelector('.hamButton').innerHTML = '&#9776;'
    }

    const hideAddTopFanCardContainer = () => {
        const topfanContainer = document.getElementById('addTopFanCardContainer');
        const glassyContainer = document.getElementById('requestBookContainer');
        document.body.style.overflowY = 'scroll';
        document.querySelector('.navContainer').style.display = 'flex';
        topfanContainer.classList.add('fadeGlassOut');
        setTimeout(() => {
            topfanContainer.style.display = 'none';
            topfanContainer.classList.remove('fadeGlassOut');
            glassyContainer.classList.add('fadeGlassOut');
            setTimeout(() => {
                glassyContainer.style.display = 'none';
                glassyContainer.classList.remove('fadeGlassOut');
            }, 400);
        }, 400);
    }

    const showRequestTopFanCardContainer = () => {
        const requestTopFanContainer = document.getElementById('requestTopFanCardContaienr');
        const glassyContainer = document.getElementById('requestBookContainer');
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';
        glassyContainer.classList.add('fadeGlassIn');
        glassyContainer.style.display = 'flex';
        setTimeout(() => {
            requestTopFanContainer.classList.remove('fadeGlassIn');
            requestTopFanContainer.classList.add('fadeGlassIn');
            requestTopFanContainer.style.display = 'flex';
            setTimeout(() => {
                requestTopFanContainer.classList.remove('fadeGlassIn');
            }, 400);
        }, 400);
        document.querySelector('.navContainer').style.display = 'none';
        setIsMenuShown(false);
        document.querySelector('.mobileMenu').style.display = 'none';
        document.querySelector('.hamButton').innerHTML = '&#9776;'
    }

    const hideRequestTopFanCardContainer = () => {
        const requestTopFanContaienr = document.getElementById('requestTopFanCardContaienr');
        const glassyContainer = document.getElementById('requestBookContainer');
        document.body.style.overflowY = 'scroll';
        document.querySelector('.navContainer').style.display = 'flex';
        requestTopFanContaienr.classList.add('fadeGlassOut');
        setTimeout(() => {
            requestTopFanContaienr.style.display = 'none';
            requestTopFanContaienr.classList.remove('fadeGlassOut');
            glassyContainer.classList.add('fadeGlassOut');
            setTimeout(() => {
                glassyContainer.style.display = 'none';
                glassyContainer.classList.remove('fadeGlassOut');
            }, 400);
        }, 400);
    }

    const requestTopFanCode = () => {
        if (instagramId.trim() !== '') {
            setIsRequestTopFanCardBtnDisabled(true);
            Axios.get(`${apiEndPoint}/api/addNotification/${instagramId}/${userObject.name}/${userObject.phoneNumber}/RequestingCard`).then(response => {
                if (response.data) {
                    setIsRequestTopFanCardBtnDisabled(false);;
                    const requestTopFanContaienr = document.getElementById('requestTopFanCardContaienr');
                    const doneContainer = document.getElementById('doneContainer');
                    const requestBookContainer = document.getElementById('requestBookContainer');
                    requestTopFanContaienr.classList.add('fadeGlassOut');
                    setTimeout(() => {
                        requestTopFanContaienr.style.display = 'none';
                        requestTopFanContaienr.classList.remove('fadeGlassOut');
                        doneContainer.style.display = 'flex';
                        doneContainer.classList.add('fadeGlassIn');
                        setTimeout(() => {
                            doneContainer.classList.remove('fadeGlassIn');
                            setTimeout(() => {
                                doneContainer.classList.add('fadeGlassOut');
                                setTimeout(() => {
                                    doneContainer.style.display = 'none';
                                    doneContainer.classList.remove('fadeGlassOut');
                                    requestBookContainer.classList.add('fadeGlassOut');
                                    setTimeout(() => {
                                        requestBookContainer.style.display = 'none';
                                        requestBookContainer.classList.remove('fadeGlassOut');
                                        document.querySelector('.navContainer').style.display = 'flex';
                                        document.body.style.overflowY = 'scroll';
                                    }, 400);
                                }, 400);
                            }, 800);
                        }, 400);
                    }, 400);
                }
            });
        }
    }
    
    const scrollUpAfterKeyboard = () => {
        window.scrollTo(0, 0);
    }

    
    return (
        <nav className="shadow">
            <div className="finishingDivContainer" id="requestBookContainer">
                <div className="infoContainer" id="requestBookInfoContainer">
                    <button id="closeBtn" onClick={closePopup}><i className="fas fa-times"></i></button>
                    <div>
                        <h2 style={{textAlign: 'center'}}>اوووكي 🥳<br/>خليلنا اسم الكتاب واسم المؤلف من فضلك وخلي الباقي علينا 😎 </h2>
                        <div className="iconedInputField shadow">
                            <img src={messageIcon} alt=""/><textarea onBlur={scrollUpAfterKeyboard} className="contactMessageBox" placeholder="... نبي كتاب اسمه" onChange={(e) => setUserMessageForNav(e.target.value)}/>
                        </div>
                    </div>
                    <button style={{backgroundColor: mainColor()}}className="flatBtn" onClick={requestBook} disabled={isRequestBookBtnDisabled}>{isRequestBookBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color="white" /> : 'طلب'}</button>
                </div>
                <div className="doneContainer" id="doneContainer">
                    <div>
                        <p style={{color: mainColor()}} className="doneIcon"><i className="fas fa-check-circle"></i></p>
                        <h2 style={{color: mainColor()}} className="doneIcon">Done</h2>
                    </div>
                </div>
                <div className="addTopFanCardContainer" id="addTopFanCardContainer">
                    <button id="closeBtn" onClick={hideAddTopFanCardContainer}><i className="fas fa-times"></i></button>
                    <div>
                        <div className="warning" id="topFanWarning"></div>
                        <h2>قم بإدخال رمز التوب فان خاصتك</h2>
                        <div className="iconedInputField shadow">
                            <img src={topFan} alt=""/><textarea onBlur={scrollUpAfterKeyboard} className="contactMessageBox" placeholder="رمز توب فان" onChange={(e) => setTopFanCode(e.target.value)}/>
                        </div>
                        <button style={{backgroundColor: mainColor()}} className="flatBtn" onClick={addTopFanCode} disabled={isAddTopFanCardBtnDisabled}>{isAddTopFanCardBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color="white" /> : 'ادخال'}</button>
                    </div>
                </div>
                <div className="requestTopFanCardContainer" id="requestTopFanCardContaienr">
                    <button id="closeBtn" onClick={hideRequestTopFanCardContainer}><i className="fas fa-times"></i></button>
                    <div>
                        <div className="warning" id="topFanWarning"></div>
                        <h2>ماعندكش بطاقة ؟ 🤔
اوكي عبي النموذج هذا
و خليلنا حسابك علي الانستقرام ، لو تنطبق عليك شروط التوب فان ح نجهزولك بطاقتك 😎</h2>
                        <IconedInputField onBlur={scrollUpAfterKeyboard} image={name} placeholder="اسمك" />
                        <IconedInputField onBlur={scrollUpAfterKeyboard} image={phone} placeholder="رقم تليفوك" />
                        <IconedInputField onBLur={scrollUpAfterKeyboard} image={instagramIcon} placeholder="اي دي الانستجرام متاعك" onChange={e => setInstagramId(e.target.value)}/>
                        <button style={{backgroundColor: mainColor()}} className="flatBtn" onClick={requestTopFanCode} disabled={isRequestTopFanCardBtnDisabled}>{isRequestTopFanCardBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color="white" /> : 'ادخال'}</button>
                    </div>
                </div>
            </div>
            <div className="navContainer">
                <div className="navLeftSide">
                    {
                        (userObject) ? (
                            <div className="userMenuContainer">
                                <div className="navBtnsStray">
                                    {
                                        (userObject) ? (
                                                <>
                                                    <div className="tabletLinks">
                                                        <Link to="">
                                                            <button onMouseOver={() => setShowUserMenu(true)} onMouseOut={() => setShowUserMenu(false)} className='cartBtn'>
                                                                <div className="navItem">
                                                                    <i className="fas fa-bars"></i>
                                                                    <span>القائمة</span>
                                                                </div>
                                                            </button>
                                                        </Link>
                                                        <Link to="/#shopSection">
                                                            <button className="cartBtn">
                                                                <div className="navItem">
                                                                    <img width="22" src={storeLogo} alt="" />
                                                                    <span>المتجر</span>
                                                                </div>
                                                            </button>
                                                        </Link>
                                                        
                                                    </div>
                                                    <Link to="/cart">
                                                        <button className="cartBtn">
                                                            <div className="navItem">
                                                                <i className="fas fa-shopping-bag"></i>
                                                                <span>العربة</span>
                                                            </div>
                                                            <span className="notificationBtn__badge" style={{fontSize: '.8em', display: (numOfCartItems == 0) ? 'none' : 'block'}}>{numOfCartItems}</span>
                                                        </button>
                                                    </Link>
                                                </>
                                        ) : null
                                    }
                                </div>
                                <div className="userMenu" style={{display: (showUserMenu) ? 'block' : 'none'}} onMouseOver={() => setShowUserMenu(true)} onMouseOut={() => setShowUserMenu(false)}>
                                    <div className="userMenuItem"><Link to="contact" id="Contact" ><i className="fas fa-envelope"></i>تواصل معنا</Link></div>
                                    <div className="userMenuItem" onClick={() => { document.querySelector('#notificationBtn__badge').style.display = 'none'; document.querySelector('#notificationBtn__badge-mobile').style.display = 'none'; }}><Link to="/notifications"><button className="notificationBtn"><i className="fas fa-bell"></i><span className="notificationBtn__badge" id="notificationBtn__badge">{numOfNotifications}</span></button><span>الاشعارات</span></Link></div>
                                    <div className="userMenuItem" onClick={showRequestBookPopup}><i className="fas fa-plus-square"></i><span>عندك كتاب تبي توصي عليه؟</span></div>
                                    {(Number(userObject.cardNumber) === 0) ? (<div className="userMenuItem" onClick={showAddTopFanCardContainer}><i className="fas fa-address-card"></i><span>اضافة رمز توب فان</span></div>) : null}
                                    {(Number(userObject.cardNumber) === 0) ? (<div className="userMenuItem" onClick={showRequestTopFanCardContainer}><i className="far fa-address-card"></i><span>ماعندكش بطاقة توب فان؟</span></div>) : null}
                                    <div className="userMenuItem"><Link to="/wallet"><i className="fas fa-wallet"></i> المحفظة</Link></div>
                                    <div className="userMenuItem"><Link to="/report"><i className="fas fa-exclamation-triangle"></i>ابلاغ عن مشكلة</Link></div>
                                    <div className="userMenuItem logoutLink" onClick={logout}><i className="fas fa-sign-out-alt"></i><span>تسجيل الخروج</span></div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <Link to="signup" className="authBtns"><button className="btn" role="button" style={{backgroundColor: mainColor(), boxShadow: shadowColor()}}>تسجيل</button></Link>
                                <Link to="login" className="authBtns">تسجيل الدخول</Link>
                            </div>
                        )
                    }
                </div>
                <div className="navRightSide">
                    {/* <div className="navLinks">
                        <a href="/#shopSection" id="shop">المتجر</a>
                        <Link to="contact" id="Contact">تواصل معنا</Link>
                    </div> */}
                    <img src={mainIcon()} alt="" width="60"/>
                </div>
                <div className="mobile">
                    {
                        (userObject) ? (
                            <>
                                <Link to="/cart"><button className="cartBtn"><i><img width="18" src={cartLogo} alt="" /></i><span className="notificationBtn__badge" style={{fontSize: '.8em', display: (numOfCartItems == 0) ? 'none' : 'block'}}>{numOfCartItems}</span></button></Link>
                                <a href="/#shopSection" id="shop" className='shopBtn'><button className="cartBtn"><i><img width="20" src={storeLogo} alt="" /></i></button></a>
                            </>
                        ) : null
                    }
                    <button className="hamButton" onClick={changeHamMenuState}>&#9776;</button>
                </div>
            </div>
            <div className="mobileMenu">
                <Link onClick={changeHamMenuState} to="contact" id="Contact" ><i className="fas fa-envelope"></i>تواصل معنا</Link>
                <div className="mobileRightSide">
                    {
                        (userObject) ? (
                            <>
                                <Link style={{opacity: '1'}} onClick={() => { changeHamMenuState(); document.querySelector('#notificationBtn__badge').style.display = 'none'; document.querySelector('#notificationBtn__badge-mobile').style.display = 'none'; }} to="notifications" ><button onClick={changeHamMenuState} className="notificationBtn"><i className="fas fa-bell"></i><span className="notificationBtn__badge" id="notificationBtn__badge-mobile">{numOfNotifications}</span></button><span>الاشعارات</span></Link>
                                <a onClick={showRequestBookPopup}><i className="fas fa-plus-square"></i>عندك كتاب تبي توصي عليه؟</a>
                                {(Number(userObject.cardNumber) === 0) ? (<a onClick={showAddTopFanCardContainer}><i className="fas fa-address-card"></i>اضافة رمز توب فان</a>) : null}
                                {(Number(userObject.cardNumber) === 0) ? (<a onClick={showRequestTopFanCardContainer}><i className="far fa-address-card"></i>ماعندكش بطاقة توب فان؟</a>) : null}
                                <Link onClick={changeHamMenuState} to="/wallet"><i className="fas fa-wallet"></i> المحفظة</Link>
                                <Link to="/report" onClick={changeHamMenuState}><i className="fas fa-exclamation-triangle"></i>ابلاغ عن مشكلة</Link>
                                <a onClick={() => {logout();changeHamMenuState()}} className="logoutLink"><i className="fas fa-sign-out-alt"></i>تسجيل الخروج</a>
                            </>
                        ) : (
                            <div className="buttons">
                                <Link onClick={changeHamMenuState} to="signup" className="authBtns"><button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="btn" role="button">تسجيل</button></Link>
                                <Link onClick={changeHamMenuState} to="login" className="authBtns">تسجيل الدخول</Link>
                            </div>
                        )
                    }
                </div>
            </div>
        </nav>
    )
}
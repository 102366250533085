import React from "react";

class ErrorHandler extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        document.title = 'Error';
        return (
          <div className="emptyCart" style={{width: '100vw', height: '100vh'}}>
            <i class="fas fa-exclamation"></i>
            <p>حدث خطأ</p>
          </div>
        )
      }
  
      return this.props.children; 
    }
};

export default ErrorHandler;
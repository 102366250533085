import './AdminBookEditor.css';
import { useLocation } from 'react-router';
import IconedInputField from '../IconedInputField/IconedInputField';
import titleIcon from '../../assets/title.svg';
import authorIcon from '../../assets/author.svg';
import pagesIcon from '../../assets/pages.svg';
import genreIcon from '../../assets/genre.svg';
import priceIcon from '../../assets/price.svg';
import quantityIcon from '../../assets/quantity.svg';
import descriptionIcon from '../../assets/message.svg';
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import showOnHomeIcon from '../../assets/showOnHome.svg';
import { useHistory } from 'react-router-dom';
import profitIcon from '../../assets/profit.svg';
import pdfIcon from '../../assets/pdfIcon.svg';
import { ActiveTheme } from '../../App';
import { Ring } from '@uiball/loaders';


export default function AdminBookEditor(props) {
    const location = useLocation();
    const { author, description, genre, secondGenre, id, pages, img, price, quantity, title, topFanSale, sale, singleSellProfit, pdfLink } = location.state;
    const [bookTitle, setBookTitle] = useState(title);
    const [bookAuthor, setBookAuthor] = useState(author);
    const [bookPages, setBookPages] = useState(pages);
    const [bookGenre, setBookGenre] = useState(genre);
    const [bookPrice, setBookPrice] = useState(price);
    const [bookQuantity, setBookQuantity] = useState(quantity);
    const [bookDescription, setBookDescription] = useState(description);
    const [bookImg, setBookImg] = useState();
    const [publicSale, setPublicSale] = useState(sale);
    const [bookSingleSellProfit, setBookSingleSellProfit] = useState(singleSellProfit);
    const [bookPdfLink, setBookPdfLink] = useState(pdfLink);
    const [isSale, setIsSale] = useState(topFanSale);
    const [isUpdateBtnDisabled, setIsUpdateBtnDisabled] = useState(false);
    const [isDeleteBtnDisabled, setIsDeleteBtnDisabled] = useState(false);
    const [isDeletePdfBtnDisabled, setIsDeletePdfBtnDisabled] = useState(false);
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);
    const history = useHistory();

    const apiEndPoint = props.apiEndPoint;
    console.log(secondGenre);

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(`${apiEndPoint}/api/login`).then(response => {
            if (response.data.loggedin === false || response.data.user.isAdmin === 0) {
                history.replace("/");
            }
        });
        document.title = 'Paperbook - Admin Panel';
        window.scrollTo(0, 0);
    }, []);


    const deleteBook = () => {
        setIsDeleteBtnDisabled(true);
        axios.get(`${apiEndPoint}/api/admin/deleteBook/${id}`).then(response => {
            if (response.data) {
                setIsDeleteBtnDisabled(false);
                history.replace("/admin");
            }
        });
    }

    const removePdf = () => {
        setIsDeletePdfBtnDisabled(true);
        axios.get(`${apiEndPoint}/api/admin/removePdf/${id}`).then(response => {
            if (response.data) {
                setIsDeletePdfBtnDisabled(false);
                history.replace('/admin');
            }
        });
    }

    const showWarning = (str) => {
        const warning = document.querySelector('#bookEditorWarning');
        const warningDiv = document.createElement('div');
        warningDiv.innerText = str;
        warning.appendChild(warningDiv);
        warning.style.display = 'block';
        warning.style['max-width'] = '415px';
        warning.style['background-color'] = mainColor();
        warning.classList.add('fadein');
        window.scrollTo(0, 0);
        setTimeout(() => {
            warning.classList.remove('fadein');
            warning.classList.add('fadeout');
        }, 10000);
        setTimeout(() => {
            warning.style.display = 'none';
            warningDiv.remove();
        }, 10300);
        warning.classList.remove('fadeout');
    }

    const checking = async(formData) => {
        let result = true;
        if (formData.get('title').length == 0) {
            showWarning('الرجاء ادخال عنوان للكتاب');
            result = false;
        }
        if (formData.get('author').length == 0) {
            showWarning('الرجاء ادخال اسم الكاتب');
            result = false;
        }
        if (isNaN(formData.get('pages')) || formData.get('pages').length == 0) {
            showWarning('الرجاء ادخال قيمة صالحة لعدد الصفحات');
            result = false;
        }
        if (isNaN(formData.get('quantity')) || formData.get('quantity').length == 0) {
            showWarning('الرجاء ادخال قيمة صالحة للكمية');
            result = false;
        }
        if (isNaN(formData.get('price')) || formData.get('price').length == 0) {
            showWarning('الرجاء ادخال قيمة صالحة للسعر');
            result = false;
        }
        if (formData.get('description').length == 0) {
            showWarning('الرجاء ادخال وصف للكتاب');
            result = false;
        }
        if (isNaN(formData.get('singleSellProfit')) || formData.get('singleSellProfit').length == 0) {
            showWarning('الرجاء ادخال قيمة للربح');
            result = false;
        }
        if (isNaN(formData.get('sale'))) {
            showWarning('الرجاء ادخال قيمة صالحة للتخفيض للكل');
            result = false;
        }
        return result;
    }


    const submitBtn = (e) => {
        e.preventDefault();
        const form = document.querySelector("#bookInfoForm");
        const formData = new FormData(form);
        checking(formData).then(result => {
            if (result) {
                setIsUpdateBtnDisabled(true);
                axios.post(`${apiEndPoint}/api/admin/updateBook`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then(response => {
                    if (!response.data) {
                        showWarning('كتاب بهذا العنوان موجود بالفعل');
                        window.scrollTo(0, 0);
                        setIsUpdateBtnDisabled(false);
                    } else if (response.data) {
                        setIsUpdateBtnDisabled(false);
                        history.replace('/admin');
                    }
                });
            }
        });
    }

    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    const shadowColor = () => {
        if (activeTheme === 'black') {
            return 'rgba(17,17,17, .2) 0 -25px 18px -14px inset,rgba(17,17,17, .15) 0 1px 2px,rgba(17,17,17, .15) 0 2px 4px,rgba(17,17,17, .15) 0 4px 8px,rgba(17,17,17, .15) 0 8px 16px,rgba(17,17,17, .15) 0 16px 32px';
        } else if (activeTheme === 'blue') {
            return 'rgba(151,201,233, .2) 0 -25px 18px -14px inset,rgba(151,201,233, .15) 0 1px 2px,rgba(151,201,233, .15) 0 2px 4px,rgba(151,201,233, .15) 0 4px 8px,rgba(151,201,233, .15) 0 8px 16px,rgba(151,201,233, .15) 0 16px 32px';
        } else if (activeTheme === 'green') {
            return 'rgba(93,187,79, .2) 0 -25px 18px -14px inset,rgba(93,187,79, .15) 0 1px 2px,rgba(93,187,79, .15) 0 2px 4px,rgba(93,187,79, .15) 0 4px 8px,rgba(93,187,79, .15) 0 8px 16px,rgba(93,187,79, .15) 0 16px 32px';
        } else if (activeTheme === 'purple') {
            return 'rgba(130,124,180, .2) 0 -25px 18px -14px inset,rgba(130,124,180, .15) 0 1px 2px,rgba(130,124,180, .15) 0 2px 4px,rgba(130,124,180, .15) 0 4px 8px,rgba(130,124,180, .15) 0 8px 16px,rgba(130,124,180, .15) 0 16px 32px';
        } else if (activeTheme === 'red') {
            return 'rgba(240,91,93, .2) 0 -25px 18px -14px inset,rgba(240,91,93, .15) 0 1px 2px,rgba(240,91,93, .15) 0 2px 4px,rgba(240,91,93, .15) 0 4px 8px,rgba(240,91,93, .15) 0 8px 16px,rgba(240,91,93, .15) 0 16px 32px';
        } else { // yellow
            return 'rgba(253,183,27, .2) 0 -25px 18px -14px inset,rgba(253,183,27, .15) 0 1px 2px,rgba(253,183,27, .15) 0 2px 4px,rgba(253,183,27, .15) 0 4px 8px,rgba(253,183,27, .15) 0 8px 16px,rgba(253,183,27, .15) 0 16px 32px';
        }
    }

    return (
        <>
            <div className="warning" id="bookEditorWarning"></div>
            <div className="adminBookEditor">
                <label for="bookCover" className="bookCover bookCoverDiv">
                {
                (bookImg) ? (
                    <img src={URL.createObjectURL(bookImg)} alt="" className="bookCover"/>
                ) : (img) ? (
                    <img src={img} alt="" className="bookCover"/>
                ) : (<i class="fas fa-plus-square"></i>)
                }
                </label>
                <div className="adminEditorForm">
                    <form action={`${apiEndPoint}/api/admin/updateBook`} method="post" encType="multipart/form-data" id="bookInfoForm">
                        <input type="text" name="id" value={id} style={{display: 'none'}}/>
                        <IconedInputField required={true} type="text" name="title" image={titleIcon} placeholder="العنوان" value={bookTitle} onChange={(e) => setBookTitle(e.target.value)}/>
                        <IconedInputField required={true} type="text" name="author" image={authorIcon} placeholder="الكاتب" value={bookAuthor} onChange={(e) => setBookAuthor(e.target.value)}/>
                        <IconedInputField required={true} type="text" name="pages" image={pagesIcon} placeholder="عدد الصفحات" value={bookPages} onChange={(e) => setBookPages(e.target.value)}/>
                        <div className="iconedInputField shadow">
                            <select name="genre" className="selectionBox">
                                <option selected={('ديني' === genre)} value="ديني">ديني</option>
                                <option selected={('رواية' === genre)} value="رواية">رواية</option>
                                <option selected={('نصوص' === genre)} value="نصوص">نصوص</option>
                                <option selected={('تطوير ذات' === genre)} value="تطوير ذات">تطوير ذات</option>
                                <option selected={('علوم' === genre)} value="علوم">علوم</option>
                                <option selected={('مذكرات' === genre)} value="مذكرات">مذكرات</option>
                                <option selected={('علم النفس' === genre)} value="علم النفس">علم النفس</option>
                                <option selected={('مانجا' === genre)} value="مانجا">مانجا</option>
                                <option selected={('تفاعلي' === genre)} value="تفاعلي">تفاعلي</option>
                                <option selected={('ورقة' === genre)} value="ورقة">ورقة</option>
                                <option selected={('حصري' === genre)} value="حصري">حصري</option>
                                <option selected={('كتب اصلية' === genre)} value="كتب اصلية">كتب اصلية</option>
                                <option selected={('كتب انجليزية' === genre)} value="كتب انجليزية">كتب انجليزية</option>
                                <option selected={('كتب الكترونية' === genre)} value="كتب الكترونية">كتب الكترونية</option>
                                <option selected={('اكسسوارات' === genre)} value="اكسسوارات">اكسسوارات</option>
                                <option selected={('طلب مسبق' === genre)} value="طلب مسبق">طلب مسبق</option>
                                <option selected={('كروت' === genre)} value="كروت">كروت</option>
                            </select><img src={genreIcon} alt="" />
                        </div>
                        <div className="iconedInputField shadow">
                            <select name="secondGenre" className="selectionBox">
                                <option selected={(null == secondGenre)} value="">لا يوجد</option>
                                <option selected={('ديني' === secondGenre)} value="ديني">ديني</option>
                                <option selected={('رواية' === secondGenre)} value="رواية">رواية</option>
                                <option selected={('نصوص' === secondGenre)} value="نصوص">نصوص</option>
                                <option selected={('تطوير ذات' === secondGenre)} value="تطوير ذات">تطوير ذات</option>
                                <option selected={('علوم' === secondGenre)} value="علوم">علوم</option>
                                <option selected={('مذكرات' === secondGenre)} value="مذكرات">مذكرات</option>
                                <option selected={('علم النفس' === secondGenre)} value="علم النفس">علم النفس</option>
                                <option selected={('مانجا' === secondGenre)} value="مانجا">مانجا</option>
                                <option selected={('تفاعلي' === secondGenre)} value="تفاعلي">تفاعلي</option>
                                <option selected={('ورقة' === secondGenre)} value="ورقة">ورقة</option>
                                <option selected={('حصري' === secondGenre)} value="حصري">حصري</option>
                                <option selected={('كتب اصلية' === secondGenre)} value="كتب اصلية">كتب اصلية</option>
                                <option selected={('كتب انجليزية' === secondGenre)} value="كتب انجليزية">كتب انجليزية</option>
                                <option selected={('كتب الكترونية' === secondGenre)} value="كتب الكترونية">كتب الكترونية</option>
                                <option selected={('اكسسوارات' === secondGenre)} value="اكسسوارات">اكسسوارات</option>
                                <option selected={('طلب مسبق' === secondGenre)} value="طلب مسبق">طلب مسبق</option>
                                <option selected={('كروت' === secondGenre)} value="كروت">كروت</option>
                            </select><img src={genreIcon} alt="" />
                        </div>
                        <IconedInputField required={true} type="text" name="price" image={priceIcon} placeholder="السعر" value={bookPrice} onChange={(e) => setBookPrice(e.target.value)}/>
                        <IconedInputField required={true} type="text" name="quantity" image={quantityIcon} placeholder="الكمية" value={bookQuantity} onChange={(e) => setBookQuantity(e.target.value)}/>
                        <div className="iconedInputField shadow">
                            <select name="topFanSale" className="selectionBox">
                                <option value="0" selected={(isSale) === 0}>لا تخفيض للكتاب</option>
                                <option value="1" selected={(isSale) === 1}>تخفيض لاصحاب التوب فان</option>
                            </select><img src={showOnHomeIcon} alt="" />
                        </div>
                        <IconedInputField type="text" name="sale" value={publicSale} image={priceIcon} placeholder="تخفيض للجميع" onChange={(e) => setPublicSale(e.target.value)}/>
                        <div className="iconedInputField shadow">
                            <textarea required={true} maxLength={10000} className="contactMessageBox" placeholder="الوصف" value={bookDescription} onChange={(e) => setBookDescription(e.target.value)} name="description"/><img src={descriptionIcon} alt=""/>
                        </div>
                        <input type="file" name="bookCover" id="bookCover" onChange={(e) => setBookImg(e.target.files[0])}/>
                        <IconedInputField required={true} type="text" name="singleSellProfit" value={bookSingleSellProfit} image={profitIcon} placeholder="الربح" onChange={(e) => setBookSingleSellProfit(e.target.value)}/>
                        <IconedInputField type="file" name="pdfFile" image={pdfIcon} placeholder="نسخة الـ PDF" />
                        <button style={{backgroundColor: mainColor()}} disabled={isUpdateBtnDisabled} className="flatBtn" onClick={submitBtn}>{isUpdateBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color="white" /> : 'تحديث'}</button>
                    </form>
                    <button style={{color: mainColor(), borderColor: mainColor()}} disabled={isDeleteBtnDisabled} className="flatBtn-clicked" onClick={deleteBook}>{isDeleteBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color={mainColor()} /> : 'حذف'}</button>
                    <button style={{color: mainColor(), borderColor: mainColor()}} disabled={isDeletePdfBtnDisabled} className="flatBtn-clicked" onClick={removePdf}>{isDeletePdfBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color={mainColor()} /> : 'إزالة ملف الـ PDF (إن وجد)'}</button>
                </div>
            </div>
        </>
    )
}
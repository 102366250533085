import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
import './Wallet.css';
import { useHistory } from 'react-router-dom';
import walletImg from '../../assets/wallet.svg';
import IconedInputField from '../IconedInputField/IconedInputField';
import inputLogo from '../../assets/topFan.svg';
import CountUp from 'react-countup';
import { Howl, Howler } from 'howler';
import walletAudio from '../../assets/walletAudio.mp3';
import { ActiveTheme } from '../../App';
import { Ring } from '@uiball/loaders';

export default function Wallet(props) {
    const [walletBalance, setWalletBalance] = useState(0);
    const [walletDOM, setWalletDOM] = useState(walletBalance);
    const [userId, setUserId] = useState();
    const [coupon, setCoupon] = useState('');
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);
    const apiEndPoint = props.apiEndPoint;
    const history = useHistory();

    const sound = new Howl({
        src: [walletAudio],
    });

    useEffect(() => {
        axios.get(`${apiEndPoint}/api/login`).then(response => {
            if (response.data.loggedin) {
                axios.get(`${apiEndPoint}/api/getWalletBalance/${response.data.user.id}`).then(result => {
                    setWalletBalance(result.data[0].wallet);
                    setWalletDOM(result.data[0].wallet);
                    setUserId(response.data.user.id);
                });
            } else {
                history.push('/login');
            }
        });
    }, []);

    const showWarning = (str) => {
        const warning = document.querySelector('#walletWarning');
        const warningDiv = document.createElement('div');
        warningDiv.innerText = str;
        warning.appendChild(warningDiv);
        warning.style.display = 'block';
        warning.style['max-width'] = '415px';
        warning.style['background-color'] = mainColor();
        warning.classList.add('fadein');
        window.scrollTo(0, 0);
        setTimeout(() => {
            warning.classList.remove('fadein');
            warning.classList.add('fadeout');
        }, 10000);
        setTimeout(() => {
            warning.style.display = 'none';
            warningDiv.remove();
        }, 10300);
        warning.classList.remove('fadeout');
    }

    const showInput = () => {
        const flatBtn = document.getElementById('walletFlatBtn');
        const couponInput = document.getElementById('couponInput');
        flatBtn.classList.add('fadeGlassOut');
        setTimeout(() => {
            flatBtn.style.display = 'none';
            flatBtn.classList.remove('fadeGlassOut');
            couponInput.style.display = 'block';
            couponInput.classList.add('fadeGlassIn');
            setTimeout(() => {
                couponInput.classList.remove('fadeGlassIn');
            }, 400);
        }, 400);
    }

    const submitInput = () => {
        if (coupon.length > 0) {
            setIsBtnDisabled(true);
            axios.get(`${apiEndPoint}/api/useCoupon/${coupon}/${userId}`).then(resopnse => {
                if (resopnse.data == false) {
                    showWarning('الرجاء ادخال كوبون صالح');
                    setCoupon("");
                    setIsBtnDisabled(false);
                } else {
                    setCoupon("");
                    setIsBtnDisabled(false);
                    setWalletDOM(<><CountUp start={walletBalance} end={walletBalance + resopnse.data[0].value}/></>)
                    setWalletBalance(walletBalance => walletBalance + resopnse.data[0].value);
                    sound.play();
                }
            });
        }
    }

    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    const shadowColor = () => {
        if (activeTheme === 'black') {
            return 'rgba(17,17,17, .2) 0 -25px 18px -14px inset,rgba(17,17,17, .15) 0 1px 2px,rgba(17,17,17, .15) 0 2px 4px,rgba(17,17,17, .15) 0 4px 8px,rgba(17,17,17, .15) 0 8px 16px,rgba(17,17,17, .15) 0 16px 32px';
        } else if (activeTheme === 'blue') {
            return 'rgba(151,201,233, .2) 0 -25px 18px -14px inset,rgba(151,201,233, .15) 0 1px 2px,rgba(151,201,233, .15) 0 2px 4px,rgba(151,201,233, .15) 0 4px 8px,rgba(151,201,233, .15) 0 8px 16px,rgba(151,201,233, .15) 0 16px 32px';
        } else if (activeTheme === 'green') {
            return 'rgba(93,187,79, .2) 0 -25px 18px -14px inset,rgba(93,187,79, .15) 0 1px 2px,rgba(93,187,79, .15) 0 2px 4px,rgba(93,187,79, .15) 0 4px 8px,rgba(93,187,79, .15) 0 8px 16px,rgba(93,187,79, .15) 0 16px 32px';
        } else if (activeTheme === 'purple') {
            return 'rgba(130,124,180, .2) 0 -25px 18px -14px inset,rgba(130,124,180, .15) 0 1px 2px,rgba(130,124,180, .15) 0 2px 4px,rgba(130,124,180, .15) 0 4px 8px,rgba(130,124,180, .15) 0 8px 16px,rgba(130,124,180, .15) 0 16px 32px';
        } else if (activeTheme === 'red') {
            return 'rgba(240,91,93, .2) 0 -25px 18px -14px inset,rgba(240,91,93, .15) 0 1px 2px,rgba(240,91,93, .15) 0 2px 4px,rgba(240,91,93, .15) 0 4px 8px,rgba(240,91,93, .15) 0 8px 16px,rgba(240,91,93, .15) 0 16px 32px';
        } else { // yellow
            return 'rgba(253,183,27, .2) 0 -25px 18px -14px inset,rgba(253,183,27, .15) 0 1px 2px,rgba(253,183,27, .15) 0 2px 4px,rgba(253,183,27, .15) 0 4px 8px,rgba(253,183,27, .15) 0 8px 16px,rgba(253,183,27, .15) 0 16px 32px';
        }
    }



    return (
        <div className="wallet">
            <div>
                <img className='walletImg' src={walletImg} alt="" />
                <p>رصيد المحفظة:</p>
                <h1>{walletDOM} د.ل</h1>
                <button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} id="walletFlatBtn" className="flatBtn" onClick={showInput}>اضافة رصيد</button>
                <div id="couponInput">
                    <div className="warning" id="walletWarning"></div>
                    <IconedInputField inputId="couponCodeInput" image={inputLogo} type="text" placeholder="ادخل كوبونك" onChange={e => setCoupon(e.target.value)} value={coupon} />
                    <button style={{backgroundColor: mainColor()}} className="flatBtn" onClick={submitInput} disabled={isBtnDisabled}>{isBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color="white" /> : 'أضف'}</button>
                </div>
            </div>
        </div>
    )
}
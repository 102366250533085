import "./BooksSlider.css";
import MyGlider, { MyLargeGlider } from '../MyGlider/MyGlider';
import Card from '../Card/Card';
import { Link, useHistory } from "react-router-dom";
import priceLogo from '../../assets/priceLogo.svg';



export default function BooksSlider(props) {
  const listOfBooks = props.listOfBooks;
  if (listOfBooks.length > 0) {
    return (
      <div className="Slider">
        <h1 style={{direction: 'rtl', fontSize: '1.5em', marginTop: '.5em', marginBottom: '.5em'}}>{props.title}:</h1>
        <MyGlider name={props.gliderName}>
          {listOfBooks.map((val) => (
            <li class="glide__slide" key={val.id}>
              <Link to={`/book/${val.title}`}>
                <Card image={val.img} title={val.title} author={val.author} genre={val.genre} />
              </Link>
            </li>
          ))}
        </MyGlider>
        {(props.showRandomBook && props.randomBook.quantity > 0) ? (
          <div className="bookOverviewContainer">
            <i class="fas fa-times bookOverviewCloseBtn" onClick={() => {document.querySelector('.bookOverviewContainer').style.display = 'none'}}></i>
            <div className="bookOverviewTopSection">
              {/* <i class="fas fa-medal"></i> */}
              <i class="fas fa-meteor"></i>
              <span style={{opacity: '.6'}}>ينصح به</span>
            </div>
            <div className="bookOverview">
              <img src={props.randomBook.img} alt="" style={(props.randomBook.genre === 'كروت') ? {width: '100px', height: '65px', marginRight: '-.6em'} : {height: '119px', width: '85px'}}/>
              <div>
                <h2>{props.randomBook.title}</h2>
                <div className="bookOverviewDescriptionContainer">
                  <p className="subTitle">{props.randomBook.description}</p>
                </div>
                <div className="bookOverviewBottomSection">
                  <Link to={`/book/${props.randomBook.title}`}>قراءة المزيد <i class="fas fa-share"></i></Link>
                  <div className="bookInfoCard">
                      <span>{props.randomBook.price} د.ل</span>
                      <img src={priceLogo} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  } else {
    return null;
  }
}

export function LargeBooksSlider(props) {
  let listOfBooks = props.listOfBooks;
  const history = useHistory();
  if (listOfBooks.length > 0) {
    let parsedListOfBooks = [];
    let currentBooksGroup = [];
    for (let i=0;i<listOfBooks.length;i++) {
      if (String(i)[0] == '0' || String(i)[0] == '5') {
        parsedListOfBooks.push([listOfBooks[i]]);
      } else {
        currentBooksGroup.push(listOfBooks[i]);
        if (currentBooksGroup.length == 4 || i == listOfBooks.length-1) {
          parsedListOfBooks.push(currentBooksGroup);
          currentBooksGroup = [];
        }
      }
    }
    return (
      <div className="Slider">
        <h1 style={{direction: 'rtl', fontSize: '1.5em', marginTop: '.5em', marginBottom: '.5em'}}>{props.title}:</h1>
        <MyLargeGlider name={props.gliderName}>
          {parsedListOfBooks.map((val) => (
            <li class="glide__slide" key={val.id}>
              <div className="largeCardContainer">
                {
                  (val.length > 1) ? (
                    val.map(book => (
                      <Link to={`/book/${book.title}`}>
                        <Card image={book.img} title={book.title} author={book.author} genre={book.genre}/>
                      </Link>
                    ))
                  ) : (
                    val.map(book => (
                      <div className="largeCard" onClick={() => history.push(`/book/${book.title}`)}>
                        <div>
                          <img src={book.img} alt="" />
                          <h2 className="title">{book.title}</h2>
                          <h2 className="subTitle">{book.author}</h2>
                        </div>
                      </div>
                    ))
                  )
                }
              </div>
            </li>
          ))}
        </MyLargeGlider>
      </div>
    );
  } else {
    return null;
  }
}

export function CardsSlider(props) {
  let listOfBooks = props.listOfBooks;
  console.log(listOfBooks);
  if (listOfBooks.length > 0) {
    return (
      <div className="Slider">
        <h1 style={{direction: 'rtl', fontSize: '1.5em', marginTop: '.5em', marginBottom: '.5em'}}>{props.title}:</h1>
        <MyLargeGlider name={props.gliderName}>
          {listOfBooks.map((val) => (
            <li class="glide__slide" key={val.id}>
              <Link to={`/book/${val.title}`}>
                {/* <Card image={val.img} title={val.title} author={val.author} genre={val.genre} /> */}
                <div className="card">
                  <div className="imgContainer">
                      <img src={val.img} alt="" style={{width: '250px', height: '165px'}}/>
                  </div>
                  <div className="titles">
                      <h2 className="title">{val.title}</h2>
                      <h2 className="subTitle">{val.author}</h2>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </MyLargeGlider>
      </div>
    )
  } else {
    return null;
  }
}
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Book from "./Components/Book/Book";
import Contact from "./Components/Contact/Contact";
import Footer from "./Components/Footer/Footer";
import { createContext, useEffect, useMemo, useState } from "react";
import Home from './Components/Home/Home';
import Login from "./Components/Login/Login";
import Navbar from "./Components/Navbar/Navbar";
import Shop from "./Components/Shop/Shop";
import Signup from "./Components/Signup/Signup";
import Cart from './Components/Cart/Cart';
import { uuid } from 'uuidv4';
import Driver from "./Components/Driver/Driver";
import Admin from "./Components/Admin/Admin";
import AdminBookEditor from "./Components/AdminBookEditor/AdminBookEditor";
import AdminBookAdder from "./Components/AdminBookAdder/AdminBookAdder";
import ErrorHandler from './Components/ErrorHandler/ErrorHandler';
import SellsRecords from "./Components/SellsRecords/SellsRecords";
import DeliveryPrices from './Components/DeliveryPrices/DeliveryPrices';
import UserNotificationAdder from "./Components/UserNotificationAdder/UserNotificationAdder";
import Notification from "./Components/Notification/Notification";
import UsersMenu from "./Components/UsersMenu/UsersMenu";
import AdminDriversManager from "./Components/AdminDriversManager/AdminDriversManager";
import AdminDeliveryPricesEditor from './Components/AdminDeliveryPricesEditor/AdminDeliveryPricesEditor';
import mainIcon from "../src/assets/mainIcon.png";
import DeliveryJob from "./Components/DeliveryJob/DeliveryJob";
import DeliveriesAdder from "./Components/DeliveriesAdder/DeliveriesAdder";
import Record from "./Components/Record/Record";
import HomeCoverChanger from "./Components/HomeCoverChanger/HomeCoverChanger";
import Wallet from "./Components/Wallet/Wallet";
import CouponGenerator from "./Components/CouponGenerator/CouponGenerator";
import ErrorReport from "./Components/ErrorReport/ErrorReport";
import PastOrder from "./Components/PastOrder/PastOrder";
import AdminNotifications from "./Components/AdminNotifications/AdminNotifications";

export const NumOfCartItems = createContext();
export const ActiveTheme = createContext();

function App() {

  const APIENDPOINT = 'https://api.waraqa.ly';
  // const APIENDPOINT = 'http://localhost:3001';

  const [numOfCartItems, setNumOfCartItems] = useState(0);
  const themes = ['black', 'blue', 'green', 'purple', 'red', 'yellow'];
  const [activeTheme, setActiveTheme] = useState(themes[Math.floor(Math.random() * themes.length)]);

  const numOfCartItemsValue = useMemo(
    () => ({numOfCartItems, setNumOfCartItems}),
    [numOfCartItems]
  );

  const activeThemeValue = useMemo(
    () => ({ activeTheme, setActiveTheme }),
    [activeTheme]
  );
  
  return (
      <ErrorHandler>
        <ActiveTheme.Provider value={activeThemeValue}>
          <NumOfCartItems.Provider value={numOfCartItemsValue}>
            <Router>
                <Navbar apiEndPoint={APIENDPOINT} />
                <Switch>
                    <Route exact path="/">
                      <Home apiEndPoint={APIENDPOINT} />
                      {/* <div style={{width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                        <img src={mainIcon} alt="" />
                        <h1>تحت التطوير 🛠︎</h1>
                      </div> */}
                    </Route>
                    {/* <Route exact path="/shop">
                      <Shop apiEndPoint={APIENDPOINT}/>
                    </Route> */}
                    <Route exact path="/contact">
                      <Contact apiEndPoint={APIENDPOINT}/>
                    </Route>
                    <Route exact path="/signup">
                      <Signup apiEndPoint={APIENDPOINT}/>
                    </Route>
                    <Route exact path="/login">
                      <Login apiEndPoint={APIENDPOINT} />
                    </Route>
                    <Route exact path="/book/:title" render={(props) => <Book key={props.location.key} apiEndPoint={APIENDPOINT} />} />
                    <Route exact path="/cart" render={(props) => <Cart key={props.location.key} apiEndPoint={APIENDPOINT} />} />
                    <Route exact path="/driver" render={(props) => <Driver apiEndPoint={APIENDPOINT}/>} />
                    <Route exact path="/admin" render={(props) => <Admin apiEndPoint={APIENDPOINT}/>} />
                    <Route exact path="/admin/bookEditor" render={(props) => <AdminBookEditor apiEndPoint={APIENDPOINT}/>} />
                    <Route exact path="/admin/bookAdder" render={(props) => <AdminBookAdder apiEndPoint={APIENDPOINT}/>} />
                    <Route exact path="/admin/sellsRecords" render={(props) => <SellsRecords apiEndPoint={APIENDPOINT}/>} />
                    <Route exact path="/deliveryPrices" render={(props) => <DeliveryPrices apiEndPoint={APIENDPOINT}/>} />
                    <Route exact path="/admin/UserNotificationAdder" render={(props) => <UserNotificationAdder apiEndPoint={APIENDPOINT}/>} />
                    <Route exact path="/notifications" render={(props) => <Notification apiEndPoint={APIENDPOINT}/>} />
                    <Route exact path="/admin/usersmenu" render={(props) => <UsersMenu apiEndPoint={APIENDPOINT} />} />
                    <Route exact path="/admin/drivers" render={(props) => <AdminDriversManager apiEndPoint={APIENDPOINT}/>} />
                    <Route exact path="/driver/delivery" render={(props) => <DeliveryJob apiEndPoint={APIENDPOINT}/>} />
                    <Route exact path="/driver/addDelivery" render={(props) => <DeliveriesAdder apiEndPoint={APIENDPOINT}/>} />
                    <Route exact path="/admin/sellsRecords/Record" render={(props) => <Record apiEndPoint={APIENDPOINT}/>} />
                    <Route exact path="/admin/changeHomeCover" render={(props) => <HomeCoverChanger apiEndPoint={APIENDPOINT} />} />
                    <Route exact path="/wallet" render={(props) => <Wallet apiEndPoint={APIENDPOINT}/>} />
                    <Route exact path="/admin/couponGenerator" render={(props) => <CouponGenerator apiEndPoint={APIENDPOINT} /> } />
                    <Route exact path="/report" render={(props) => <ErrorReport apiEndPoint={APIENDPOINT} />} />
                    <Route exact path="/cart/pastOrder" render={(props) => <PastOrder apiEndPoint={APIENDPOINT} />} />
                    <Route exact path="/admin/deliveryPrices" render={(props) => <AdminDeliveryPricesEditor apiEndPoint={APIENDPOINT} /> } />
                    <Route exact path="/admin/notifications" render={(props) => <AdminNotifications apiEndPoint={APIENDPOINT} /> } />
                    <Redirect to='/' />
                </Switch>
                <Footer />
            </Router>
          </NumOfCartItems.Provider>
        </ActiveTheme.Provider>
      </ErrorHandler>
  );
}

export default App;

import './Card.css';

export default function Card(props) {
    return (
        <div className="card" key={props.identifier}>
            <div className="imgContainer">
                <img src={props.image} alt="" style={(props.genre === 'كروت') ? {width: '100px', height: '65px'} : null} />
            </div>
            <div className="titles">
                <h2 className="title">{props.title}</h2>
                <h2 className="subTitle">{props.author}</h2>
            </div>
        </div>
    )
}
import { useEffect, useState, useContext } from 'react';
import './Admin.css';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import searchIcon from '../../assets/searchIcon.svg';
import { useHistory } from 'react-router-dom';
import { ActiveTheme } from '../../App';


export default function Admin(props) {
    const [allBooks, setAllBooks] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchResult, setSearchResult] = useState([]);
    const [totalNumOfUsers, setTotalNumOfUsers] = useState();
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);
    const history = useHistory();
    let time;

    const apiEndPoint = props.apiEndPoint;

    const closePopup = () => {
        document.querySelector('.adminActionBtns').style.display = 'block';
        const topFanContainer = document.getElementById('topFanContainer');
        const codePlaceholderContainer = document.getElementById('codePlaceholderContainer');
        document.querySelector('nav').style.display = 'block';
        document.body.style.overflowY = 'scroll';
        codePlaceholderContainer.classList.add('fadeGlassOut');
        setTimeout(() => {
            codePlaceholderContainer.style.display = 'none';
            codePlaceholderContainer.classList.remove('fadeGlassOut');
            topFanContainer.classList.add('fadeGlassOut');
            setTimeout(() => {
                topFanContainer.style.display = 'none';
                topFanContainer.classList.remove('fadeGlassOut');
            }, 400);
        }, 400);
    }

    const showTopFanCode = () => {
        document.querySelector('.adminActionBtns').style.display = 'none';
        Axios.get(`${apiEndPoint}/api/generateTopFanCode`).then(response => {
            const topFanContainer = document.getElementById('topFanContainer');
            const codePlaceholderContainer = document.getElementById('codePlaceholderContainer');
            document.querySelector('nav').style.display = 'none';
            window.scrollTo(0, 0);
            document.body.style.overflow = 'hidden';
            topFanContainer.classList.add('fadeGlassIn');
            topFanContainer.style.display = 'flex';
            setTimeout(() => {
                topFanContainer.classList.remove('fadeGlassIn');
                document.getElementById('codePlaceholder').innerText = response.data;
                codePlaceholderContainer.classList.add('fadeGlassIn');
                codePlaceholderContainer.style.display = 'flex';
                setTimeout(() => {
                    codePlaceholderContainer.classList.remove('fadeGlassIn');
                }, 400);
            }, 400);
        });
    }

    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    const shadowColor = () => {
        if (activeTheme === 'black') {
            return 'rgba(17,17,17, .2) 0 -25px 18px -14px inset,rgba(17,17,17, .15) 0 1px 2px,rgba(17,17,17, .15) 0 2px 4px,rgba(17,17,17, .15) 0 4px 8px,rgba(17,17,17, .15) 0 8px 16px,rgba(17,17,17, .15) 0 16px 32px';
        } else if (activeTheme === 'blue') {
            return 'rgba(151,201,233, .2) 0 -25px 18px -14px inset,rgba(151,201,233, .15) 0 1px 2px,rgba(151,201,233, .15) 0 2px 4px,rgba(151,201,233, .15) 0 4px 8px,rgba(151,201,233, .15) 0 8px 16px,rgba(151,201,233, .15) 0 16px 32px';
        } else if (activeTheme === 'green') {
            return 'rgba(93,187,79, .2) 0 -25px 18px -14px inset,rgba(93,187,79, .15) 0 1px 2px,rgba(93,187,79, .15) 0 2px 4px,rgba(93,187,79, .15) 0 4px 8px,rgba(93,187,79, .15) 0 8px 16px,rgba(93,187,79, .15) 0 16px 32px';
        } else if (activeTheme === 'purple') {
            return 'rgba(130,124,180, .2) 0 -25px 18px -14px inset,rgba(130,124,180, .15) 0 1px 2px,rgba(130,124,180, .15) 0 2px 4px,rgba(130,124,180, .15) 0 4px 8px,rgba(130,124,180, .15) 0 8px 16px,rgba(130,124,180, .15) 0 16px 32px';
        } else if (activeTheme === 'red') {
            return 'rgba(240,91,93, .2) 0 -25px 18px -14px inset,rgba(240,91,93, .15) 0 1px 2px,rgba(240,91,93, .15) 0 2px 4px,rgba(240,91,93, .15) 0 4px 8px,rgba(240,91,93, .15) 0 8px 16px,rgba(240,91,93, .15) 0 16px 32px';
        } else { // yellow
            return 'rgba(253,183,27, .2) 0 -25px 18px -14px inset,rgba(253,183,27, .15) 0 1px 2px,rgba(253,183,27, .15) 0 2px 4px,rgba(253,183,27, .15) 0 4px 8px,rgba(253,183,27, .15) 0 8px 16px,rgba(253,183,27, .15) 0 16px 32px';
        }
    }

    useEffect(() => {
        Axios.get(`${apiEndPoint}/api/login`).then(response => {
            if (response.data.loggedin === false || response.data.user.isAdmin === 0) {
                history.replace("/");
            }
        });
        document.title = 'Paperbook - Admin Panel';
        Axios.get(`${apiEndPoint}/api/getTotalNumOfUsers`).then(r => {
            setTotalNumOfUsers(r.data[0]['COUNT(*)']);
        });
        setIsLoading(false); 
        // Axios.get(`${apiEndPoint}/api/getAll`).then(response => {
        //     setAllBooks(response.data);
        //     Axios.get(`${apiEndPoint}/api/getNotifications`).then(result => {
        //         setNotifications(result.data);
        //         Axios.get(`${apiEndPoint}/api/getTotalNumOfUsers`).then(r => {
        //             setTotalNumOfUsers(r.data[0]['COUNT(*)']);
        //         });
        //     });
        // });
    }, []);

    const search = (e) => {
        if (time) {
            clearTimeout(time);
        }
        time = setTimeout(() => {
            if (e.target.value !== '') {
                Axios.get(`${apiEndPoint}/api/adminSearch/${e.target.value}`).then(response => {
                    setSearchResult(response.data);
                });
            } else {
                setSearchResult([]);
            }
        }, 1000);
    }

    if (isLoading) {
        return (
            <div className="admin">
                <div className="adminLeftSide skeleton" style={{width: '100%'}}></div>
                <div className="adminRightSide skeleton" style={{width: '100%', height: '60vh'}}></div>
            </div>
        )
    }

    return (
        <div className="admin">
            <div className="finishingDivContainer" id="topFanContainer">
                <div className="doneContainer" id="codePlaceholderContainer">
                    <button id="closeBtn" onClick={closePopup}><i class="fas fa-times"></i></button>
                    <div>
                        <p className="doneIcon"><i style={{fontWeight: 'bold', color: mainColor()}}  id="codePlaceholder"></i></p>
                        <p className="doneIcon" style={{color: 'grey'}}>يرجى نسخ الكود, لن تتمكن من رؤيته بعد الأن</p>
                     </div>
                </div>
            </div>
            <h1><i class="fas fa-users-cog"></i> إدارة المستخدمين</h1>
            <div className="usersCount">
                    <div>
                    <i class="fas fa-users"></i>
                    <span>الاجمالي<br/>{totalNumOfUsers}</span>
                    </div>
                    <Link to="admin/usersmenu">
                    <div>
                        <i class="fas fa-cogs" style={{marginBottom: '.2em'}}></i>
                        <span>اعدادات المستخدمين</span>
                    </div>
                </Link>
            </div>
            <div className="adminActionBtns">
                <Link to="admin/bookAdder"><button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="flatBtn">اضف كتاب</button></Link>
                <button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="flatBtn" onClick={showTopFanCode}>توليد رمز توب فان</button>
                <Link to="admin/couponGenerator"><button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="flatBtn">توليد كوبون</button></Link>
                <Link to="admin/sellsRecords"><button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="flatBtn">سجل المبيعات</button></Link>
                <Link to="admin/UserNotificationAdder"><button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="flatBtn">ارسال اشعار للمستخدمين</button></Link>
                <Link to="admin/drivers"><button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="flatBtn">ادارة السائقين</button></Link>
                <Link to="/admin/changeHomeCover"><button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="flatBtn">بانر الصفحة الرئيسية</button></Link>
                <Link to="/admin/deliveryPrices"><button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="flatBtn">اسعار التوصيل</button></Link>
                <Link to="/admin/notifications"><button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="flatBtn">الإشعارات</button></Link>
            </div>
            <h1><i class="fas fa-book"></i> الكتب</h1>
            <div className="inputBoxContainer shadow">
                <div className="iconedInputField">
                    <input type="text" className="formField" placeholder="إبحث عن الكتب" id="shopSearchBox" onChange={(e) => search(e)}/><img src={searchIcon} alt=""/>
                </div>
            </div>
            {
                (searchResult.length > 0) ? searchResult.map(val => (
                    <Link to={{
                        pathname: 'admin/bookEditor',
                        state: {
                            author: val.author,
                            description: val.description,
                            dislikes: val.dislikes,
                            genre: val.genre,
                            id: val.id,
                            img: val.img,
                            likes: val.likes,
                            pages: val.pages,
                            price: val.price,
                            quantity: val.quantity,
                            title: val.title,
                            topFanSale: val.topFanSale,
                            sale: val.sale,
                            singleSellProfit: val.singleSellProfit,
                            pdfLink: val.pdfLInk,
                            secondGenre: val.secondGenre,
                        }
                    }}>
                        <div className="adminBookItem">
                            <img  style={(props.genre === 'كروت') ? {width: '100px', height: '65px'} : {height: '119px', width: '85px'}} src={val.img} alt="" />
                            <div className="adminBookItemInfo">
                                <h3 style={{direction: 'rtl'}}>{val.title}</h3>
                                <p>تبقى {val.quantity} {val.quantity > 10 ? <>نسخة</> : <>نسخ</>}</p>
                            </div>
                        </div>
                    </Link>
                )) : (<p>لا يوجد كتاب يوافق النص الموجود في صندوق البحث</p>)
            }
        </div>
        // <div className="admin">
        //     <div className="finishingDivContainer" id="topFanContainer">
        //         <div className="doneContainer" id="codePlaceholderContainer">
        //             <button id="closeBtn" onClick={closePopup}><i class="fas fa-times"></i></button>
        //             <div>
        //                 <p className="doneIcon"><i style={{fontWeight: 'bold', color: mainColor()}}  id="codePlaceholder"></i></p>
        //                 <p className="doneIcon" style={{color: 'grey'}}>يرجى نسخ الكود, لن تتمكن من رؤيته بعد الأن</p>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="adminLeftSide">
        //         <h1><i class="fas fa-users-cog"></i> إدارة المستخدمين</h1>
        //         <div className="usersCount">
        //             <div>
        //                 <i class="fas fa-users"></i>
        //                 <span>الاجمالي<br/>{totalNumOfUsers}</span>
        //             </div>
        //             <Link to="admin/usersmenu">
        //                 <div>
        //                     <i class="fas fa-cogs" style={{marginBottom: '.2em'}}></i>
        //                     <span>اعدادات المستخدمين</span>
        //                 </div>
        //             </Link>
        //         </div>
        //         <h1><i class="fas fa-book"></i> الكتب</h1>
        //         <div className="inputBoxContainer shadow">
        //             <div className="iconedInputField">
        //                 <input type="text" className="formField" placeholder="إبحث عن الكتب" id="shopSearchBox" onChange={(e) => search(e)}/><img src={searchIcon} alt=""/>
        //             </div>
        //             <div className="results" style={{display: (searchResult.length > 0) ? 'block' : 'none'}}>
        //                 {
        //                     (searchResult.length > 0) ? searchResult.map(val => (
        //                         <Link to={{
        //                             pathname: 'admin/bookEditor',
        //                             state: {
        //                                 author: val.author,
        //                                 description: val.description,
        //                                 dislikes: val.dislikes,
        //                                 genre: val.genre,
        //                                 id: val.id,
        //                                 img: val.img,
        //                                 likes: val.likes,
        //                                 pages: val.pages,
        //                                 price: val.price,
        //                                 quantity: val.quantity,
        //                                 title: val.title,
        //                                 topFanSale: val.topFanSale,
        //                                 sale: val.sale,
        //                                 singleSellProfit: val.singleSellProfit,
        //                                 pdfLink: val.pdfLInk,
        //                                 secondGenre: val.secondGenre,
        //                             }
        //                         }}>
        //                             <div className="bookData">
        //                                 <div className="cardContainer">
        //                                     <img  style={(val.genre === 'كروت') ? {width: '100px', height: '65px'} : {height: '119px', width: '85px'}} src={val.img} alt="" />
        //                                 </div>
        //                                 <div className="cardInfo">
        //                                     <h3 style={{direction: 'rtl'}}>{val.title}</h3>
        //                                     <h3 style={{direction: 'rtl', textAlign: 'right'}} className="subTitle">{val.author}</h3>
        //                                 </div>
        //                             </div>
        //                         </Link>
        //                     )) : null
        //                 }
        //             </div>
        //         </div>
        //         <div className="adminActionBtns">
        //             <Link to="admin/bookAdder"><button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="btn">اضف كتاب</button></Link>
        //             <button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="btn" onClick={showTopFanCode}>توليد رمز توب فان</button>
        //             <Link to="admin/couponGenerator"><button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="btn">توليد كوبون</button></Link>
        //             <Link to="admin/sellsRecords"><button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="btn">سجل المبيعات</button></Link>
        //             <Link to="admin/UserNotificationAdder"><button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="btn">ارسال اشعار للمستخدمين</button></Link>
        //             <Link to="admin/drivers"><button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="btn">ادارة السائقين</button></Link>
        //             <Link to="/admin/changeHomeCover"><button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="btn">بانر الصفحة الرئيسية</button></Link>
        //             <Link to="/admin/deliveryPrices"><button style={{backgroundColor: mainColor(), boxShadow: shadowColor()}} className="btn">اسعار التوصيل</button></Link>
        //         </div>
        //         {
        //             (allBooks.length > 0) ? allBooks.map(val => (
        //                 <Link to={{
        //                     pathname: 'admin/bookEditor',
        //                     state: {
        //                         author: val.author,
        //                         description: val.description,
        //                         dislikes: val.dislikes,
        //                         genre: val.genre,
        //                         id: val.id,
        //                         img: val.img,
        //                         likes: val.likes,
        //                         pages: val.pages,
        //                         price: val.price,
        //                         quantity: val.quantity,
        //                         title: val.title,
        //                         topFanSale: val.topFanSale,
        //                         sale: val.sale,
        //                         singleSellProfit: val.singleSellProfit,
        //                         pdfLink: val.pdfLInk,
        //                         secondGenre: val.secondGenre,
        //                     }
        //                 }}>
        //                     <div className="adminBookItem">
        //                         <img  style={(props.genre === 'كروت') ? {width: '100px', height: '65px'} : {height: '119px', width: '85px'}} src={val.img} alt="" />
        //                         <div className="adminBookItemInfo">
        //                             <h3 style={{direction: 'rtl'}}>{val.title}</h3>
        //                             <p>تبقى {val.quantity} {val.quantity > 10 ? <>نسخة</> : <>نسخ</>}</p>
        //                         </div>
        //                     </div>
        //                 </Link>
        //             )) : (<h1>لم تقم بإضافة أي كتاب بعد</h1>)
        //         }
        //     </div>
        //     <div className="adminRightSide">
                
        //         <h1><i class="fas fa-bell"></i> الإشعارات</h1>
        //         {
        //                 notifications.map(val => {
        //                     let date = val.time.split('T');
        //                     date[1] = date[1].slice(0, 5);
        //                     return (
        //                         <div className="notificationBox">
        //                             <div className="notificationInfo">
        //                                 <p>{val.username} <b> :<i class="fas fa-user"></i></b></p>
        //                                 <p>{val.phoneNumber} <b> :<i class="fas fa-phone"></i> </b></p>
        //                                 <p>{date[0]}  {date[1]}<b> :<i class="fas fa-clock"></i></b></p>
        //                                 <p>{val.submittedWhen}<b> :<i class="fas fa-calendar-day"></i></b></p>
        //                                 <p>{val.message}<b> :<i class="fas fa-envelope-open-text"></i></b></p>
        //                             </div>
        //                             <button onClick={() => deleteNotification(val.id)}><i class="fas fa-times"></i></button>
        //                         </div>
        //                     )   
        //                 })
        //         }
        //     </div>
        // </div>
    )
}